import { css, Global } from '@emotion/core';

import React from 'react';

/**
 * A global to reset box-sizing.
 *
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
const BoxSizingReset = () => {
  return (
    <Global
      styles={css`
        html {
          box-sizing: border-box;
        }
        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }
      `}
    />
  );
};

export default BoxSizingReset;
