import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { CalendarThemeBox, CalendarThemeIcon } from '../base';

const NavigatorButtonStyled = styled(CalendarThemeBox)`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const NavigatorButton = React.forwardRef(
  ({ icon, isDisabled, variant, onFocus, onClick, onKeyDown }, ref) => (
    <NavigatorButtonStyled
      isDisabled={isDisabled}
      onClick={!isDisabled ? onClick : undefined}
      onFocus={!isDisabled ? onFocus : undefined}
      onKeyDown={!isDisabled ? onKeyDown : undefined}
      ref={ref}
      tabIndex={!isDisabled ? 0 : undefined}
      variant={variant}
      variantComponent="navigatorButton"
    >
      <CalendarThemeIcon name={icon} variant={variant} variantComponent="navigatorButtonIcon" />
    </NavigatorButtonStyled>
  )
);

NavigatorButton.displayName = 'NavigatorButton';

NavigatorButton.propTypes = {
  /**
   * Disables button.
   */
  isDisabled: PropTypes.bool,
  /**
   * Icon name.
   */
  icon: PropTypes.string.isRequired,
  /**
   * Theme variant.
   */
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Handle onFocus event.
   */
  onFocus: PropTypes.func.isRequired,
  /**
   * Handle onClick event.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Handle onKeyDown event.
   */
  onKeyDown: PropTypes.func.isRequired,
};

NavigatorButton.defaultProps = {
  isDisabled: false,
};

export default NavigatorButton;
