import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../_internal/theme/stylesFor';

const Root = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  border-color: transparent;

  ${stylesForDefaultState('switch')}

  &:hover {
    ${stylesForHoverState('switch')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('switch')}
  }
`;

Root.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Root;
