import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import calcMenuHeight from '../functions/calcMenuHeight';
import WindowedOptionsContainer from './internal/WindowedOptionsContainer';
import OptionContainer from '../option/OptionContainer';

const WindowedOptions = ({
  options,
  variant,
  optionHeight,
  selectedIndex,
  menuHeight,
  getItemProps,
  highlightedIndex,
  optionId,
  children,
  isSelected,
  listRef,
}) => {
  useLayoutEffect(() => {
    if (listRef.current && selectedIndex) {
      listRef.current.scrollToItem(selectedIndex, 'center');
    }
  }, [listRef, selectedIndex]);

  return (
    <WindowedOptionsContainer
      variant={variant}
      variantComponent="options"
      height={calcMenuHeight(options, optionHeight, menuHeight)}
      width="100%"
    >
      <AutoSizer>
        {({ height, width }) => {
          return (
            <FixedSizeList
              ref={listRef}
              width={width}
              height={height}
              itemCount={options.length}
              itemSize={optionHeight}
              itemData={{
                items: options,
                getItemProps,
                highlightedIndex,
                variant,
                optionId,
              }}
            >
              {({ index, data, style }) => {
                const {
                  items,
                  renderOption,
                  getItemProps,
                  highlightedIndex,
                  variant,
                  optionId,
                } = data;

                return (
                  <OptionContainer
                    getItemProps={getItemProps}
                    highlightedIndex={highlightedIndex}
                    index={index}
                    key={index}
                    option={items[index]}
                    optionId={optionId}
                    renderOption={renderOption}
                    variant={variant}
                    isSelected={isSelected(items[index])}
                    style={style}
                  >
                    {children({ option: items[index], index })}
                  </OptionContainer>
                );
              }}
            </FixedSizeList>
          );
        }}
      </AutoSizer>
    </WindowedOptionsContainer>
  );
};

WindowedOptions.propTypes = {
  children: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  isSelected: PropTypes.func.isRequired,
  listRef: PropTypes.object,
  menuHeight: PropTypes.number.isRequired,
  optionHeight: PropTypes.number.isRequired,
  optionId: PropTypes.func,
  options: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

WindowedOptions.defaultProps = {
  options: [],
  selectedItem: undefined,
  highlightedIndex: undefined,
  optionId: undefined,
  optionHeight: undefined,
  menuHeight: undefined,
};

export default WindowedOptions;
