import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import optionLabel from '../functions/optionLabel';

const OptionPlainText = ({ renderOption, option, index }) => {
  return <Fragment>{optionLabel(renderOption, option, index)}</Fragment>;
};

OptionPlainText.displayName = 'OptionPlainText';

OptionPlainText.propTypes = {
  renderOption: PropTypes.func,
  option: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
};

OptionPlainText.defaultProps = {
  renderOption: undefined,
};

export default OptionPlainText;
