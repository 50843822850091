import PropTypes from 'prop-types';
import React, { useState } from 'react';
import BaseInput from '../_internal/base/BaseInput';
import useDateMask, { Patterns } from '../_internal/hooks/useDateMask';
import DatePickerAddon from './_internal/DatePickerAddon';

const DateInput = ({
  id,
  isDisabled,
  isReadOnly,
  maxDate,
  minDate,
  name,
  onBlur,
  onChange,
  onFocus,
  pattern,
  placeholder,
  value,
  variant,
}) => {
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(false);

  const handleOnComplete = d => {
    if (d instanceof Date) {
      onChange && onChange(d);
    }
  };

  const { ref: inputRef, setRef } = useDateMask({
    isPlaceholderVisible: isPlaceholderVisible,
    onComplete: handleOnComplete,
    date: value,
    pattern: pattern,
    min: minDate,
    max: maxDate,
  });

  const handleOnFocus = e => {
    setIsPlaceholderVisible(true);
    onFocus && onFocus(e);
  };

  const handleOnBlur = e => {
    setIsPlaceholderVisible(false);
    onBlur && onBlur(e);
  };

  const handleOnRequestClear = () => {
    onChange && onChange(null);
    inputRef.current.focus();
  };

  const handleOnPickerClose = () => {
    if (value instanceof Date) {
      inputRef.current.focus();
    }
  };

  const isInputDisabled = isDisabled || isReadOnly;
  const isInputEnabled = !isInputDisabled;
  const canUseDatePicker = !!(isInputEnabled && onChange);

  return (
    <BaseInput
      addonStart={
        <DatePickerAddon
          isVisible={canUseDatePicker}
          onChange={handleOnComplete}
          onClose={handleOnPickerClose}
          variant={variant}
          value={value}
        />
      }
      defaultValue=""
      id={id}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      isShowClear={value instanceof Date}
      name={name}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onRequestClear={handleOnRequestClear}
      placeholder={placeholder}
      ref={setRef}
      type="text"
      variant={variant}
    />
  );
};

DateInput.Patterns = {
  date: Patterns.date,
  dateTime: Patterns.dateTime,
  dateTime24hour: Patterns.dateTime24hour,
};

DateInput.propTypes = {
  /**
   * Sets the input id.
   */
  id: PropTypes.string,
  /**
   * Disables the input.
   */
  isDisabled: PropTypes.bool,
  /**
   * Sets the input as readonly.
   */
  isReadOnly: PropTypes.bool,
  /**
   * Sets the max date in range that can be entered.
   */
  maxDate: PropTypes.instanceOf(Date),
  /**
   * Sets the min date in range that can be entered.
   */
  minDate: PropTypes.instanceOf(Date),
  /**
   * Sets the input name.
   */
  name: PropTypes.string,
  /**
   * Called when input is blurred.
   */
  onBlur: PropTypes.func,
  /**
   * Called when the input value changes.
   */
  onChange: PropTypes.func,
  /**
   * Called when input is focused.
   */
  onFocus: PropTypes.func,
  /**
   * The date pattern to use. Use one of the props on `DateInput.Patterns`
   */
  pattern: PropTypes.oneOf(Object.values(Patterns)),
  /**
   * The input placeholder.
   */
  placeholder: PropTypes.string,
  /**
   * The input value - a javascript Date object.
   */
  value: PropTypes.instanceOf(Date),
  /**
   * The input theme variant.
   */
  variant: PropTypes.string,
};

DateInput.defaultProps = {
  id: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  maxDate: undefined,
  minDate: undefined,
  name: undefined,
  onChange: undefined,
  pattern: DateInput.Patterns.date,
  placeholder: undefined,
  value: undefined,
  variant: 'default',
};

export default DateInput;
