import styled from '@emotion/styled';
import { Icon } from '../../../../index';
import { stylesForDefaultState, stylesForHoverState } from '../../../../_internal/theme/stylesFor';

const ToastsThemeIcon = styled(Icon)`
  ${stylesForDefaultState('toasts')}

  &:hover {
    ${stylesForHoverState('toasts')}
  }
`;

export default ToastsThemeIcon;
