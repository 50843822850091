import { Global } from "@emotion/core";
import styled from "@emotion/styled";
import { getThemeColor } from "@modernary/ui-kit-styled-system";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Manager, Popper, Reference } from "react-popper";
import { animated, interpolate } from "react-spring/renderprops.cjs";
import { Z_INDEX_TOOLTIP } from "../../_internal/constants";
import ScaleTransition from "../../_internal/transitions/ScaleTransition";
import randomId from "../../_internal/utils/randomId";
import { Absolute, Hover, Text } from "../../index";
import Backdrop from "../../Util/Backdrop";
import Arrow from "../../Util/Popper/Arrow";
import Portal from "../../Util/Portal";
import { DEFAULT_BG_COLOR, DEFAULT_COLOR } from "./_internal/constants";

const StyledTextContainer = styled.span`
  &:before,
  &:after {
    content: "\\00a0";
  }
`;

const StyledText = styled.span`
  display: inline-block;
  border-bottom-color: ${getThemeColor("primary")};
  border-bottom-width: 2px;
  border-bottom-style: dotted;
`;

const DecoratedText = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledTextContainer {...rest} ref={ref}>
    <StyledText>{children}</StyledText>
  </StyledTextContainer>
));

DecoratedText.displayName = "DecoratedText";

const modifiers = {
  offset: {
    enabled: true,
    offset: "0,12"
  }
};

const canShowPopper = ({ isOpen, isHovering }) => {
  if (isOpen !== undefined) {
    return isOpen;
  }
  return isHovering;
};

const Tooltip = ({
  backdropOpacity,
  bg,
  children,
  color,
  cursor,
  enterDelay,
  isOpen,
  leaveDelay,
  onRequestClose,
  placement,
  radius,
  shadow,
  title,
  TitleComponent,
  zIndex
}) => {
  const portalId = useRef(randomId());
  return (
    <Hover disable={isOpen !== undefined} enterDelay={enterDelay} leaveDelay={leaveDelay}>
      {({ getHoverProps, isHovering }) => {
        const isShowPopper = canShowPopper({ isOpen, isHovering });
        return (
          <Manager>
            {cursor && (
              <Global
                styles={{
                  body: {
                    cursor: isShowPopper ? cursor : undefined
                  }
                }}
              />
            )}
            <Reference>
              {({ ref }) => {
                const childArray = React.Children.toArray(children);
                if (childArray.length > 1) {
                  console.error("<Tooltip> must contain only one child.");
                  return null;
                }

                if (childArray.length === 0) {
                  console.error("<Tooltip> must contain a child.");
                  return null;
                }

                const commonProps = { ref: ref, tabIndex: 0, ...getHoverProps() };
                if (typeof childArray[0] === "string") {
                  return React.createElement(DecoratedText, commonProps, children);
                } else {
                  return React.cloneElement(children, commonProps);
                }
              }}
            </Reference>
            <ScaleTransition isActive={isShowPopper}>
              {({ scale, opacity }) => (
                <Popper placement={placement} modifiers={modifiers}>
                  {({ ref, style: { top, left, position, transform }, placement, arrowProps }) => {
                    return (
                      <Absolute
                        as={animated.div}
                        p="xsmall"
                        px="small"
                        ref={ref}
                        style={{
                          zIndex: zIndex,
                          top: top,
                          left: left,
                          position: position,
                          opacity: opacity,
                          transform: interpolate([scale], s => `${transform} scale(${s})`)
                        }}
                        data-placement={placement}
                        bg={bg || DEFAULT_BG_COLOR}
                        radius={radius}
                        shadow={shadow}
                      >
                        {TitleComponent !== undefined ? (
                          TitleComponent
                        ) : (
                          <Text fontSize={12} color={color || DEFAULT_COLOR}>
                            {title}
                          </Text>
                        )}
                        <Arrow
                          ref={arrowProps.ref}
                          style={arrowProps.style}
                          data-placement={placement}
                          color={bg}
                        />
                      </Absolute>
                    );
                  }}
                </Popper>
              )}
            </ScaleTransition>
            {onRequestClose && isOpen && (
              <Portal id={portalId.current}>
                <Backdrop onClick={onRequestClose} opacity={backdropOpacity} zIndex={zIndex - 1} />
              </Portal>
            )}
          </Manager>
        );
      }}
    </Hover>
  );
};

Tooltip.displayName = "Tooltip";

Tooltip.propTypes = {
  /**
   * The css cursor to show when the tooltip is open.
   *
   * See https://developer.mozilla.org/en-US/docs/Web/CSS/cursor
   */
  cursor: PropTypes.string,
  /**
   * Setting this will turn the tooltip into a controlled component disabling
   * the hover events.
   */
  isOpen: PropTypes.bool,
  /**
   * Where to place the tooltip.
   */
  placement: PropTypes.oneOf([
    "auto",
    "top",
    "top-start",
    "top-end",
    "right",
    "right-start",
    "right-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end"
  ]),

  /**
   * Tooltip title component - overrides the text title.
   */
  TitleComponent: PropTypes.node,

  /**
   * Tooltip text.
   */
  title: PropTypes.string,

  /**
   * Children node
   */
  children: PropTypes.node,

  /**
   * Sets the opacity of the backdrop. Should be a number 0 to 1.
   */
  backdropOpacity: PropTypes.number,

  /**
   * Background color of the tooltip, accepts theme colors (ie. green.400).
   */
  bg: PropTypes.string,

  /**
   * Text color.
   */
  color: PropTypes.string,

  /**
   * Time is ms before revealing the tooltip.
   */
  enterDelay: PropTypes.number,

  /**
   * Time is ms before closing the tooltip.
   */
  leaveDelay: PropTypes.number,

  /**
   * Callback to request that the tooltip should close.
   */
  onRequestClose: PropTypes.func,

  /**
   * Sets the theme shadow value.
   */
  shadow: PropTypes.string,

  /**
   * Sets the theme radii value.
   */
  radius: PropTypes.string,

  /**
   * Z-index of the tooltip.
   */
  zIndex: PropTypes.number
};

Tooltip.defaultProps = {
  backdropOpacity: 0,
  cursor: undefined,
  enterDelay: 50,
  isOpen: undefined,
  leaveDelay: 50,
  placement: "auto",
  radius: undefined,
  shadow: undefined,
  zIndex: Z_INDEX_TOOLTIP
};

export default Tooltip;
