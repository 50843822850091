import addDays from 'date-fns/addDays';
import getDay from 'date-fns/getDay';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import getMonth from 'date-fns/getMonth';
import getWeeksInMonth from 'date-fns/getWeeksInMonth';
import getYear from 'date-fns/getYear';
import isSameMonth from 'date-fns/isSameMonth';
import startOfMonth from 'date-fns/startOfMonth';
import subDays from 'date-fns/subDays';

/**
 * Returns an array of dates that occur in the monthly calendar view for the given
 * date. The monthly view begins on Sunday.
 * @param date
 */
const getMonthlyCalendar = ({ date = new Date() }) => {
  const weeksInMonth = getWeeksInMonth(date);
  const daysInMonth = getDaysInMonth(date);
  const monthStartDate = startOfMonth(date);
  const startDayOfWeek = getDay(monthStartDate);
  const year = getYear(date);
  const month = getMonth(date);
  const totalCells = weeksInMonth * 7;

  // Array to hold all of the dates you would see on a typical calendar.
  const calendar = [];

  // Fill in the calendar for all of the dates in this month.
  let daysToAdd = 0;
  for (let i = startDayOfWeek; i < daysInMonth + startDayOfWeek; i += 1) {
    const d = addDays(monthStartDate, daysToAdd++);
    calendar[i] = {
      date: d,
      isOtherMonth: !isSameMonth(date, d),
    };
  }

  // Fill in the dates of the previous month.
  for (let i = startDayOfWeek; i >= 0; i -= 1) {
    const d = subDays(calendar[startDayOfWeek].date, startDayOfWeek - i);
    calendar[i] = {
      date: d,
      isOtherMonth: !isSameMonth(date, d),
    };
  }

  // Fill in the remaining dates of the next month.
  const fillCount = totalCells - 1 - daysInMonth;
  const lastDayOfMonth = calendar[daysInMonth];

  if (lastDayOfMonth) {
    for (let i = fillCount, j = 1; i > 0; i -= 1, j += 1) {
      const d = addDays(lastDayOfMonth.date, j);
      calendar[totalCells - i] = {
        date: d,
        isOtherMonth: !isSameMonth(date, d),
      };
    }
  }

  // Week numbers start 0
  const getDaysByWeekNumber = (weekNumber = 0) => {
    const start = weekNumber * 7;
    return calendar.slice(start, start + 7);
  };

  //console.log('calendar', calendar);

  return {
    weeksInMonth,
    daysInMonth,
    monthStartDate,
    startDayOfWeek,
    year,
    month,
    days: calendar,
    getDaysByWeekNumber,
  };
};

export default getMonthlyCalendar;
