import styled from "@emotion/styled";
import { bottom, left, right, top, zIndex } from "@modernary/ui-kit-styled-system";
import Box from "../Box/Box";

const Relative = styled(Box)`
  position: relative;
  ${top} 
  ${bottom} 
  ${left} 
  ${right}
  ${zIndex}
`;

Relative.propTypes = {
  ...top.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
  ...left.propTypes,
  ...zIndex.propTypes
};

Relative.displayName = "Relative";

export default Relative;
