import PropTypes from 'prop-types';
import React from 'react';
import BaseThemeIcon from '../../base/BaseThemeIcon';

const RemoveIcon = ({ variant }) => (
  <BaseThemeIcon name="X" variant={variant} variantComponent="removeIcon" />
);

RemoveIcon.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default RemoveIcon;
