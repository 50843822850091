import styled from '@emotion/styled';

const TableCell = styled('div')`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  width: ${props => props.width || '100%'};
  transition: border-color 100ms linear, background-color 100ms linear, transform 100ms ease;
`;

export default TableCell;
