import styled from '@emotion/styled';
import { Box } from '../../../../Box';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../_internal/theme/stylesFor';

const CalendarThemeBox = styled(Box)`
  transition: border-color 200ms linear, background-color 200ms linear, transform 200ms ease;
  user-select: none;

  ${stylesForDefaultState('calendar')};

  &:hover {
    ${stylesForHoverState('calendar')};
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('calendar')};
  }
`;

export default CalendarThemeBox;
