import styled from '@emotion/styled';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../../_internal/theme/stylesFor';

const StyledButton = styled('button')`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  ${stylesForDefaultState('tree')}

  &:hover {
    ${stylesForHoverState('tree')}
  }

  &:focus {
    ${stylesForFocusState('tree')}
  }
`;

export default StyledButton;
