import PropTypes from 'prop-types';
import React from 'react';
import { Icons } from '../../../../../index';
import StyledIcon from '../styled/StyledIcon';

const CollapseIcon = ({ expanded, variant }) => {
  return expanded ? (
    <StyledIcon
      color="primary"
      isExpanded={expanded}
      name={Icons.ChevronDown}
      size={14}
      variant={variant}
      variantComponent="collapseIcon"
    />
  ) : (
    <StyledIcon
      color="primary"
      isExpanded={expanded}
      name={Icons.ChevronRight}
      size={14}
      variant={variant}
      variantComponent="collapseIcon"
    />
  );
};

CollapseIcon.propTypes = {
  expanded: PropTypes.bool.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default CollapseIcon;
