import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import isPropValid from '@emotion/is-prop-valid';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../_internal/theme/stylesFor';

const StyledHandle = styled('span', { shouldForwardProp: isPropValid })`
  display: inline-block;
  position: absolute;
  z-index: 1;
  left: 1px;

  ${stylesForDefaultState('switch')}

  &:hover {
    ${stylesForHoverState('switch')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('switch')}
  }
`;

const springConfig = { mass: 1, tension: 570, friction: 26, clamp: true, velocity: 1 };

const Handle = ({ variant, isChecked, isDisabled }) => {
  const [{ x }, set] = useSpring(() => ({
    x: [isChecked ? 100 : 0],
    config: springConfig,
  }));

  useEffect(() => {
    set({ x: isChecked ? 100 : 0 });
  }, [isChecked, set]);

  return (
    <StyledHandle
      as={animated.div}
      isChecked={isChecked}
      isDisabled={isDisabled}
      variant={variant}
      variantComponent="handle"
      style={{
        transform: x.interpolate(x => `translateX(${x}%)`),
      }}
    />
  );
};

Handle.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

Handle.defaultProps = {
  isChecked: undefined,
  isDisabled: undefined,
};

export default Handle;
