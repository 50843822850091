export const Z_INDEX_POPPER = 1070;
export const Z_INDEX_TOOLTIP = 1050;
export const Z_INDEX_POPOVER = 1030;
export const Z_INDEX_MODAL = 1010;

/**
 * These patterns need to work with date-fns/format|parse.
 */
export const DEFAULT_DATE_PATTERN = 'MM/dd/yyyy';
export const DEFAULT_DATE_TIME_PATTERN = 'MM/dd/yyyy hh:mm aa';
export const DEFAULT_DATE_TIME_24_HR_PATTERN = 'MM/dd/yyyy HH:mm';
