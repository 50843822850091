import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Box } from '../../../../Box';
import { CheckboxTree } from '../../../index';
import OptionsContainer from '../../_internal/options/internal/OptionsContainer';

const CheckboxTreeOptions = ({
  expanded,
  id,
  menuHeight,
  menuWidth,
  nodeChildren,
  nodeId,
  nodeLabel,
  nodeValue,
  nodes,
  onChange,
  onModelChange,
  onExpand,
  renderNode,
  value,
  variant,
  variantCheckboxTree,
}) => {
  const ref = useRef(null);

  return (
    <OptionsContainer height={menuHeight} ref={ref} variant={variant} variantComponent="options">
      <Box p="small" minWidth={menuWidth}>
        <CheckboxTree
          expanded={expanded}
          id={id}
          nodeChildren={nodeChildren}
          nodeId={nodeId}
          nodeLabel={nodeLabel}
          nodeValue={nodeValue}
          nodes={nodes}
          onChange={onChange}
          onExpand={onExpand}
          onModelChange={onModelChange}
          renderNode={renderNode}
          value={value}
          variant={variantCheckboxTree}
        />
      </Box>
    </OptionsContainer>
  );
};

CheckboxTreeOptions.propTypes = {
  expanded: PropTypes.array,
  id: PropTypes.string,
  menuHeight: PropTypes.number,
  menuWidth: PropTypes.number,
  nodeChildren: PropTypes.func.isRequired,
  nodeId: PropTypes.func.isRequired,
  nodeLabel: PropTypes.func.isRequired,
  nodeValue: PropTypes.func,
  nodes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onModelChange: PropTypes.func.isRequired,
  renderNode: PropTypes.func.isRequired,
  value: PropTypes.array,
  variant: PropTypes.string,
  variantCheckboxTree: PropTypes.string,
};

CheckboxTreeOptions.defaultProps = {
  id: undefined,
  value: undefined,
  variant: 'default',
  nodeValue: undefined,
};

export default CheckboxTreeOptions;
