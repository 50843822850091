import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import {
  isArrowLeftKey,
  isArrowRightKey,
  isEnterKey,
  isEscapeKey,
  isSpaceKey,
} from '../../../../../_internal/isKey';
import StyledButton from '../styled/StyledButton';
import CollapseIcon from './CollapseIcon';

const LeafSpacer = styled('span')`
  display: block;
  width: 28px;
`;

const CollapseButton = ({
  expanded,
  leaf,
  onExpandClose,
  onExpandOpen,
  onExpandToggle,
  variant,
}) => {
  const buttonRef = useRef(null);

  if (leaf) {
    return <LeafSpacer />;
  }

  const handleOnClick = e => {
    e.stopPropagation();
    onExpandToggle();
  };

  const handleKeyDown = e => {
    if (isEnterKey(e) || isSpaceKey(e)) {
      e.preventDefault();
      onExpandToggle();
    } else if (isArrowRightKey(e)) {
      onExpandOpen();
    } else if (isArrowLeftKey(e)) {
      onExpandClose();
    } else if (isEscapeKey(e)) {
      buttonRef.current.blur();
    }
  };

  return (
    <StyledButton
      aria-label="Toggle tree node"
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
      ref={buttonRef}
      tabIndex={0}
      type="button"
      variant={variant}
      variantComponent="collapseButton"
    >
      <CollapseIcon expanded={expanded} variant={variant} />
    </StyledButton>
  );
};

CollapseButton.propTypes = {
  onExpandClose: PropTypes.func.isRequired,
  onExpandOpen: PropTypes.func.isRequired,
  onExpandToggle: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  leaf: PropTypes.bool.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default CollapseButton;
