import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Table = styled('div')`
  display: table;
  width: 100%;
  border-collapse: ${props => (props.isCollapsed ? 'collapse' : 'separate')};
`;

Table.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
};

Table.defaultProps = {
  isCollapsed: false,
};

export default Table;
