/**
 * Scrolls the option menu to the first item having the "data-selected=true"
 * attribute.
 */
const scrollToSelected = ref => {
  if (ref && ref.current) {
    const item = ref.current.querySelector(`[data-selected=true]`);
    if (item) {
      item.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }
};

export default scrollToSelected;
