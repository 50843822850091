import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Icons } from '../../index';
import BaseInput from '../_internal/base/BaseInput';
import InputThemeAddon from '../_internal/base/InputThemeAddon';
import InputThemeIcon from '../_internal/base/InputThemeIcon';
import useCurrencyMask from '../_internal/hooks/useCurrencyMask';

const parseNumber = value => {
  const example = Intl.NumberFormat('en-us').format('1.1');
  const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g');
  const cleaned = value.replace(cleanPattern, '');
  const normalized = cleaned.replace(example.charAt(1), '.');
  return parseFloat(normalized);
};

const CurrencyInput = ({
  allowNegative,
  id,
  isDisabled,
  isReadOnly,
  name,
  onBlur,
  min,
  max,
  onChange,
  onFocus,
  placeholder,
  value,
  variant,
}) => {
  const [internalValue, setInternalValue] = useState(String(value));

  useEffect(() => {
    setInternalValue(String(value));
  }, [value]);

  const handleOnComplete = num => {
    setInternalValue(num);
    const parsed = parseNumber(num);

    if (isNaN(parsed)) {
      onChange(null);
      return;
    }

    if (!min && !max) {
      onChange && onChange(parsed);
    } else if (min && max && parsed >= min && parsed <= max) {
      onChange && onChange(parsed);
    }
  };

  const { ref: inputRef, setRef } = useCurrencyMask({
    allowNegative: allowNegative,
    max: max,
    min: min,
    onComplete: handleOnComplete,
    value: internalValue,
  });

  const handleOnFocus = e => {
    // select text when focused.
    if (inputRef.current && inputRef.current.select) {
      inputRef.current.select();
    }
    onFocus && onFocus(e);
  };

  const handleOnBlur = e => {
    onBlur && onBlur(e);
  };

  const handleOnRequestClear = () => {
    onChange && onChange(null);
    inputRef.current.focus();
  };

  return (
    <BaseInput
      addonStart={
        <InputThemeAddon variant={variant} variantComponent="addonStart">
          <InputThemeIcon
            name={Icons.Dollar}
            variant={variant}
            variantComponent="iconCurrency"
            isDisabled={isDisabled}
          />
        </InputThemeAddon>
      }
      defaultValue=""
      id={id}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      isShowClear={value !== null && value !== undefined}
      name={name}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onRequestClear={handleOnRequestClear}
      placeholder={placeholder}
      ref={setRef}
      type="text"
      variant={variant}
    />
  );
};

CurrencyInput.propTypes = {
  /**
   * Allow negative currency values.
   */
  allowNegative: PropTypes.bool,
  /**
   * Sets the input id.
   */
  id: PropTypes.string,
  /**
   * Disables the input.
   */
  isDisabled: PropTypes.bool,
  /**
   * Sets the input as readonly.
   */
  isReadOnly: PropTypes.bool,
  /**
   * Sets the max in range that can be entered.
   */
  max: PropTypes.number,
  /**
   * Sets the min in range that can be entered.
   */
  min: PropTypes.number,
  /**
   * Sets the input name.
   */
  name: PropTypes.string,
  /**
   * Called when input is blurred.
   */
  onBlur: PropTypes.func,
  /**
   * Called when the input value changes.
   */
  onChange: PropTypes.func,
  /**
   * Called when input is focused.
   */
  onFocus: PropTypes.func,
  /**
   * The input placeholder.
   */
  placeholder: PropTypes.string,
  /**
   * The input value - a javascript Number.
   */
  value: PropTypes.number,
  /**
   * The input theme variant.
   */
  variant: PropTypes.string,
};

CurrencyInput.defaultProps = {
  allowNegative: true,
  id: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  max: undefined,
  min: undefined,
  name: undefined,
  onChange: undefined,
  placeholder: undefined,
  value: undefined,
  variant: 'default',
};

export default CurrencyInput;
