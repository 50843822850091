import PropTypes from 'prop-types';
import React, { Fragment, useImperativeHandle, useRef } from 'react';
import { Box } from '../../../../Box';
import DateRange from '../../../_internal/model/DateRange';
import { Table } from '../base';
import DaysOfWeek from './DaysOfWeek';
import Week from './Week';

const makeSelector = date => `div[data-date="${date}"]`;

const PickDate = React.forwardRef(
  ({ calendar, selected, variant, onPickDate, onFocusDate, isDisabled }, ref) => {
    const days = [];
    const tableRef = useRef(null);

    const getActiveDateInt = () => {
      return Number.parseInt(document.activeElement.getAttribute('data-date'));
    };

    const getDateElement = date => {
      return tableRef.current.querySelector(makeSelector(date));
    };

    // call to set focus on next date.
    useImperativeHandle(ref, () => ({
      focusInitial: () => {
        const dateToFocus = getDateElement(1);
        if (dateToFocus) {
          dateToFocus.focus();
          return true;
        }
        return false;
      },
      focusUp: () => {
        const dateToFocus = getDateElement(getActiveDateInt() - 7);
        if (dateToFocus) {
          dateToFocus.focus();
          return true;
        }
        return false;
      },
      focusDown: () => {
        const dateToFocus = getDateElement(getActiveDateInt() + 7);
        if (dateToFocus) {
          dateToFocus.focus();
          return true;
        }
        return false;
      },
      focusLeft: () => {
        const dateToFocus = getDateElement(getActiveDateInt() - 1);
        if (dateToFocus) {
          dateToFocus.focus();
          return true;
        }
        return false;
      },
      focusRight: () => {
        const dateToFocus = getDateElement(getActiveDateInt() + 1);
        if (dateToFocus) {
          dateToFocus.focus();
          return true;
        }
        return false;
      },
    }));

    for (let i = 0; i <= calendar.weeksInMonth; i++) {
      days.push(
        <Week
          days={calendar.getDaysByWeekNumber(i)}
          isDisabled={isDisabled}
          key={i}
          onClickDate={onPickDate}
          onFocusDate={onFocusDate}
          selected={selected}
          variant={variant}
        />
      );
    }

    return (
      <Fragment>
        <Box mb="xsmall">
          <DaysOfWeek variant={variant} />
        </Box>
        <Table ref={tableRef}>{days}</Table>
      </Fragment>
    );
  }
);

PickDate.propTypes = {
  /**
   * Calendar data.
   */
  calendar: PropTypes.object.isRequired,
  /**
   * Disables interaction.
   */
  isDisabled: PropTypes.bool,
  /**
   * Calendar theme variant.
   */
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * The date to show in the calendar.
   */
  date: PropTypes.instanceOf(Date),
  /**
   * Selected date.
   */
  selected: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(DateRange)]),
  /**
   * Called when month is picked.
   */
  onPickDate: PropTypes.func.isRequired,
  /**
   * Called when a date dom element is focused.
   */
  onFocusDate: PropTypes.func.isRequired,
};

PickDate.defaultProps = {
  date: undefined,
  isDisabled: false,
  selected: undefined,
};

PickDate.displayName = 'PickDate';

export default PickDate;
