import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { getThemeColor, margin, shadow } from "@modernary/ui-kit-styled-system";
import PropTypes from "prop-types";
import React from "react";

const width = props => (props.width ? { width: props.width } : { width: "100%" });

const height = props => (props.height ? { height: props.height } : { height: "1px" });

const vertical = props =>
  props.vertical
    ? {
        height: props.height || "100%",
        width: props.width || "1px"
      }
    : null;

const color = props => ({ backgroundColor: getThemeColor(props.color)(props) });

const shouldForwardProp = prop => isPropValid(prop) && !["color", "width", "height"].includes(prop);

const Hr = styled("hr", { shouldForwardProp })`
  display: block;
  margin: 0;
  border: none;
  ${color}
  ${shadow}
  ${margin}
  ${width}
  ${height}
  ${vertical}
`;

const Divider = ({ vertical, ...rest }) => {
  return <Hr role="separator" vertical={vertical} {...rest} />;
};

Divider.propTypes = {
  /**
   *  Color theme key.
   */
  color: PropTypes.string,
  /**
   * Height, accepts a number or string.
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Sets the theme shadow value.
   */
  shadow: PropTypes.string,
  /**
   * Width, accepts a number or string.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Vertical orientation.
   */
  vertical: PropTypes.bool
};

Divider.defaultProps = {
  vertical: undefined,
  color: "grey.100",
  width: undefined,
  height: undefined
};

export default Divider;
