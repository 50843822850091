import React from 'react'
import Svg from './Svg'
export const SvgPlus = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M0 9h24v6H0z" />
    <path d="M9 0h6v24H9z" />
  </Svg>
))
SvgPlus.isIcon = true
export default SvgPlus
