import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { animated, interpolate } from 'react-spring/renderprops.cjs';
import { Z_INDEX_POPOVER } from '../../_internal/constants';
import ScaleTransition from '../../_internal/transitions/ScaleTransition';
import randomId from '../../_internal/utils/randomId';
import { Absolute } from '../../index';
import Portal from '../../Util/Portal';
import Backdrop from '../Backdrop';
import Arrow from '../Popper/Arrow';
import { DEFAULT_BG_COLOR } from './_internal/constants';

const modifiers = {
  offset: {
    enabled: true,
    offset: '0,12',
  },
};

const Popover = ({
  backdropOpacity,
  bg,
  children,
  isOpen,
  onRequestClose,
  placement,
  PopoverComponent,
  radius,
  shadow,
  zIndex,
}) => {
  const portalContentId = useRef(randomId());
  const portalId = useRef(randomId());

  if (!PopoverComponent) {
    return children;
  }

  return (
    <Manager>
      <Reference>
        {({ ref }) => {
          const childArray = React.Children.toArray(children);
          if (childArray.length > 1) {
            console.error('<Popover> must contain only one child.');
            return null;
          }

          if (childArray.length === 0) {
            console.error('<Popover> must contain a child.');
            return null;
          }

          const commonProps = { ref: ref, tabIndex: 0 };

          return React.cloneElement(children, commonProps);
        }}
      </Reference>
      <ScaleTransition isActive={isOpen}>
        {({ scale, opacity }) => (
          <Popper placement={placement} modifiers={modifiers}>
            {({ ref, style: { top, left, position, transform }, placement, arrowProps }) => {
              return (
                <Portal id={portalContentId.current}>
                  <Absolute
                    as={animated.div}
                    ref={ref}
                    style={{
                      zIndex: zIndex,
                      top: !isNaN(top) ? top : undefined,
                      left: !isNaN(left) ? left : undefined,
                      position: position,
                      opacity: opacity,
                      transform: interpolate([scale], s => `${transform} scale(${s})`),
                    }}
                    data-placement={placement}
                    bg={bg || DEFAULT_BG_COLOR}
                    radius={radius}
                    shadow={shadow}
                  >
                    {PopoverComponent}
                    <Arrow
                      ref={arrowProps.ref}
                      style={{
                        top: !isNaN(arrowProps.style.top) ? arrowProps.style.top : undefined,
                        left: !isNaN(arrowProps.style.left) ? arrowProps.style.left : undefined,
                      }}
                      data-placement={placement}
                      color={bg}
                    />
                  </Absolute>
                </Portal>
              );
            }}
          </Popper>
        )}
      </ScaleTransition>
      {onRequestClose && isOpen && (
        <Portal id={portalId.current}>
          <Backdrop onClick={onRequestClose} opacity={backdropOpacity} zIndex={zIndex - 1} />
        </Portal>
      )}
    </Manager>
  );
};

Popover.displayName = 'Popover';

Popover.propTypes = {
  /**
   * Sets the opacity of the backdrop. Should be a number 0 to 1.
   */
  backdropOpacity: PropTypes.number,
  /**
   * Background color of the tooltip, accepts theme colors (ie. green.400).
   */
  bg: PropTypes.string,
  /**
   * Children node
   */
  children: PropTypes.node,
  /**
   * Opens the popover.
   */
  isOpen: PropTypes.bool,
  /**
   * Callback to request that the tooltip should close.
   */
  onRequestClose: PropTypes.func,
  /**
   * Popover content component.
   */
  PopoverComponent: PropTypes.node,
  /**
   * Where to place the popover.
   */
  placement: PropTypes.oneOf([
    'auto',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  /**
   * Sets the theme radii value.
   */
  radius: PropTypes.string,
  /**
   * Sets the theme shadow value.
   */
  shadow: PropTypes.string,
  /**
   * Z-index
   */
  zIndex: PropTypes.number,
};

Popover.defaultProps = {
  backdropOpacity: 0.1,
  bg: 'white',
  isOpen: false,
  onRequestClose: () => {},
  PopoverComponent: null,
  placement: 'auto',
  radius: undefined,
  shadow: undefined,
  zIndex: Z_INDEX_POPOVER,
};

export default Popover;
