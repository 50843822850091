import { css, Global } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import React from 'react';

/**
 * Global css rule to set the font-family on the <body>.
 */
const BaseFont = ({ theme }) => {
  return (
    <Global
      styles={css`
        body {
          font-family: ${theme.fonts.sans};
        }
      `}
    />
  );
};

export default withTheme(BaseFont);
