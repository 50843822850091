import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { themeDarkShadeInBaseColor } from '../../_internal/theme/color';
import { stylesForDefaultState } from '../../_internal/theme/stylesFor';
import { Box, Icon } from '../../index';

const Root = styled(Box)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  ${stylesForDefaultState('chip')}
  ${themeDarkShadeInBaseColor({
    cssProperty: 'backgroundColor',
    propsName: 'baseColor',
    useMiddleDark: true,
    moveBy: -2,
  })}
`;

const ChipIcon = styled(Icon)`
  ${stylesForDefaultState('chip')}
`;

const Chip = React.forwardRef(
  ({ children, baseColor, variant, iconStart, iconEnd, ...rest }, ref) => (
    <Root
      ref={ref}
      as="span"
      baseColor={baseColor}
      variant={variant}
      variantComponent="root"
      {...rest}
    >
      {iconStart && <ChipIcon name={iconStart} variant={variant} variantComponent="iconStart" />}
      {children}
      {iconEnd && <ChipIcon name={iconEnd} variant={variant} variantComponent="iconEnd" />}
    </Root>
  )
);

Chip.displayName = 'Chip';

Chip.propTypes = {
  /**
   * The base theme color. The styling logic will find the first "isDark" color for the base color.
   */
  baseColor: PropTypes.string,
  /**
   * Children, usually just a string.
   */
  children: PropTypes.node.isRequired,
  /**
   * Icon to place at the start inside the chip.
   */
  iconStart: PropTypes.string,
  /**
   * Icon to place at the end inside the chip.
   */
  iconEnd: PropTypes.string,
  /**
   * The chip theme variant.
   */
  variant: PropTypes.string,
};

Chip.defaultProps = {
  variant: 'default',
  baseColor: 'primary',
};

export default Chip;
