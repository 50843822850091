import React from 'react'
import Svg from './Svg'
export const SvgHistory = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M23.843 11.93c-.112 5.978-4.962 10.715-10.827 10.602-2.368 0-4.511-.79-6.429-2.256-.676-.564-.902-1.579-.338-2.255.564-.677 1.579-.903 2.256-.339 1.24 1.015 2.819 1.466 4.398 1.579 4.061 0 7.444-3.271 7.557-7.331 0-2.481-1.128-4.737-3.045-6.09 0 0-.113 0-.113-.113a7.09 7.09 0 00-4.173-1.353H12.227c-.113 0-.339 0-.451.113-.113 0-.226 0-.226.112-.113 0-.338.113-.451.113h-.339c-.112.113-.338.113-.451.226h-.112c-.226.112-.339.225-.564.338-.677.338-1.241.677-1.805 1.241-.113.112-.225.338-.451.451v.113a7.507 7.507 0 00-1.692 4.173h2.369l-4.061 6.09-3.947-6.203h2.481c0-.677.113-1.466.339-2.143 0-.113 0-.113.112-.226-.112-.112-.112-.225 0-.451 0-.113 0-.113.113-.225.113-.339.226-.677.451-1.015 0-.113.113-.226.113-.339.113-.113.113-.338.226-.451 0 0 .112-.113.112-.226.113-.112.226-.338.339-.451v-.112l.338-.339.113-.113.113-.112c.225-.339.451-.564.676-.79l.677-.677.113-.112c.225-.113.451-.339.677-.451.112 0 .112-.113.225-.113.226-.113.451-.339.79-.451.225-.113.564-.226.789-.339h.113c.338-.225.564-.338.789-.451.113 0 .339-.113.452-.113.112 0 .338-.112.451-.112h.112c.113 0 .226 0 .339-.113h.225c.113 0 .339 0 .452-.113h1.353c.338 0 .677 0 1.015.113.338 0 .677.113 1.015.113h.113c.677.112 1.24.338 1.917.563.113 0 .226.113.339.113.112.113.338.113.451.226.112.113.338.113.451.225.225.113.338.226.451.339.113.113.338.225.451.338 2.707 1.917 4.511 5.188 4.511 8.797z" />
    <path d="M15 12.8h-2.2V9.6c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v4.7H15c.4 0 .7-.3.7-.7 0-.4-.3-.8-.7-.8z" />
  </Svg>
))
SvgHistory.isIcon = true
export default SvgHistory
