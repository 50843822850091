import React from 'react'
import Svg from './Svg'
export const SvgSettings = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M21.955 10.945h-2.097C19.417 9.4 18.01 8.27 16.354 8.27s-3.062 1.159-3.531 2.676H2.588c-.552 0-1.021.442-1.021 1.021 0 .552.441 1.02 1.02 1.02h10.236c.441 1.546 1.848 2.677 3.504 2.677s3.062-1.131 3.503-2.676h2.097c.552 0 1.02-.442 1.02-1.021a.978.978 0 00-.992-1.02zm-5.6 2.676a1.66 1.66 0 01-1.656-1.655c0-.938.717-1.655 1.655-1.655.938 0 1.656.717 1.656 1.655a1.66 1.66 0 01-1.656 1.655zM2.588 4.903h2.179C5.208 6.448 6.615 7.58 8.271 7.58s3.062-1.13 3.504-2.676h10.18c.551 0 1.02-.441 1.02-1.02s-.44-1.021-1.02-1.021h-10.18C11.333 1.317 9.926.186 8.27.186s-3.063 1.13-3.504 2.676h-2.18c-.551 0-1.02.441-1.02 1.02s.441 1.021 1.02 1.021zM8.27 2.227c.883 0 1.655.717 1.655 1.655S9.21 5.538 8.271 5.538s-1.656-.773-1.656-1.656.773-1.655 1.656-1.655zM21.955 19.029h-10.18c-.442-1.545-1.849-2.676-3.504-2.676s-3.063 1.13-3.504 2.676h-2.18c-.551 0-1.02.441-1.02 1.02s.441 1.021 1.02 1.021h2.18c.441 1.545 1.848 2.676 3.504 2.676s3.062-1.13 3.504-2.676h10.18c.551 0 1.02-.441 1.02-1.02s-.44-1.021-1.02-1.021zM8.27 21.705c-.883 0-1.656-.718-1.656-1.656s.718-1.655 1.656-1.655 1.655.717 1.655 1.655-.772 1.656-1.655 1.656z" />
    </g>
  </Svg>
))
SvgSettings.isIcon = true
export default SvgSettings
