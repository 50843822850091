import React from 'react'
import Svg from './Svg'
export const SvgLayers = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M11.797 22.057L24 16.306l-4.411-1.474-7.743 3.653-7.396-3.031L0 17.224zM11.797 11.46L24 5.707 12.46 1.412 0 6.628z" />
      <path d="M11.797 16.869L24 11.116l-4.683-1.563-7.47 3.523-7.126-2.918L0 12.035z" />
    </g>
  </Svg>
))
SvgLayers.isIcon = true
export default SvgLayers
