import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Text } from '../';

const getStyle = (el, styleProp) => {
  if (el.currentStyle) {
    return el.currentStyle[styleProp];
  }
  return document.defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
};

const getLineHeight = el => {
  const pxNum = getStyle(el, 'line-height');
  return Number.parseFloat(pxNum.substring(0, pxNum.length - 2));
};

const Truncate = ({ children, lines, ellipsis, ...rest }) => {
  const textRef = useRef(null);
  const [text, setText] = useState('');

  useEffect(() => {
    if (textRef.current) {
      const original = children || '';
      const lineHeight = getLineHeight(textRef.current);
      const maxHeight = lineHeight * (lines || 1) + 1;
      const sanitize = original ? original.trim() : '';
      const words = sanitize.length > 0 ? original.split(' ') : [];
      const totalWords = words.length;
      let counter = 0;

      const joinText = currentCount => {
        return words.slice(0, currentCount).join(' ');
      };

      while (counter <= totalWords) {
        const appendage = totalWords === counter ? '' : ' ' + (ellipsis || '');
        textRef.current.innerText = joinText(counter) + appendage;

        if (textRef.current.clientHeight === 0 && counter === totalWords) {
          return;
        }

        if (textRef.current.clientHeight >= maxHeight) {
          const finalText = joinText(counter - 1) + appendage;
          setText(finalText);
          return;
        }

        counter++;
      }
    }
  }, [children, ellipsis, lines]);

  return (
    <Text ref={textRef} {...rest}>
      {text}
    </Text>
  );
};

Truncate.propTypes = {
  /**
   * Children node which must be a string to truncate.
   */
  children: PropTypes.string,
  /**
   * The max number of lines allowed before truncating the string.
   */
  lines: PropTypes.number.isRequired,
  /**
   * The string to append as the ellipsis.
   */
  ellipsis: PropTypes.string.isRequired,
};

Truncate.defaultProps = {
  lines: 1,
  ellipsis: '...',
};

export default Truncate;
