import debounce from 'lodash/debounce';
import { useEffect, useCallback, useLayoutEffect, useState } from 'react';
import { domRect } from '../../../../_internal/utils/domRect';

/**
 * Listens for window resize events and updates the with for the given
 * ref.
 *
 * @param ref the ref to calculate width.
 */
const useWidth = ref => {
  const [width, setWidth] = useState(null);

  const updateWidth = useCallback(() => {
    if (ref.current) {
      const rect = domRect(ref);
      const pxWidth = `${rect.width}px`;
      if (pxWidth !== width) {
        setWidth(pxWidth);
      }
    }
  }, [ref, width]);

  /*
   * Setup resize listener, returns function to remove listener on
   * unmount.
   */
  useEffect(() => {
    const debouncedUpdateWidth = debounce(updateWidth, 150);
    window.addEventListener('resize', debouncedUpdateWidth);
    return () => window.removeEventListener('resize', debouncedUpdateWidth);
  }, [updateWidth]);

  /*
   * Updates the width on layout.
   */
  useLayoutEffect(() => {
    updateWidth();
  }, [updateWidth]);

  return width;
};

export default useWidth;
