import { system } from 'styled-system';
import { getColor } from '../internal';

const borderBottomColor = system({
  borderBottomColor: {
    property: 'borderBottomColor',
    scale: 'colors',
    transform: getColor,
  },
});

export default borderBottomColor;
