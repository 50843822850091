import React from 'react'
import Svg from './Svg'
export const SvgSharing = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M11.346 6.46s-.398-1.987-3.082-1.987c-2.684 0-2.982 1.988-2.982 1.988v2.485c0 .994.1.895.2.994.198.1.496.497.496.497.298 2.584 2.386 2.485 2.386 2.485s2.087.1 2.385-2.485c0 0 .299-.497.497-.497.2-.1.2 0 .2-.994-.1-.895-.1-2.485-.1-2.485zM15.222 15.904c-1.192-.497-3.976-1.392-3.976-1.392s-.695-.198-.894-.795c-.1-.298-.795.1-.795.1-1.59 1.192-2.784 0-2.784 0s-.596-.398-.696-.1c-.198.597-.894.795-.894.795s-2.783.895-3.976 1.392c-1.193.497-1.094 3.28-1.094 3.28H16.315c0-.1.1-2.783-1.093-3.28z" />
      <g>
        <path d="M20.093 9.244s-.298-1.59-2.386-1.59c-2.087 0-2.385 1.59-2.385 1.59v1.988c0 .696 0 .696.199.795l.397.398c.298 1.988 1.889 1.889 1.889 1.889s1.59.099 1.888-1.889c0 0 .299-.398.398-.398.1-.099.199 0 .199-.795-.1-.795-.199-1.988-.199-1.988zM23.175 16.6c-.995-.398-3.082-1.094-3.082-1.094s-.497-.198-.696-.596c-.1-.298-.596.1-.596.1-1.193.894-2.187 0-2.187 0s-.497-.299-.596-.1c-.2.497-.696.596-.696.596s-2.187.696-3.082 1.094c-.994.397-.894 2.584-.894 2.584H23.87c.2-.1.299-2.187-.695-2.584z" />
      </g>
    </g>
  </Svg>
))
SvgSharing.isIcon = true
export default SvgSharing
