import styled from "@emotion/styled";
import { color, spacing } from "@modernary/ui-kit-styled-system";

const Svg = styled("svg")(
  {
    flex: "none"
  },
  spacing,
  color
);

Svg.propTypes = {
  ...spacing.propTypes,
  ...color.propTypes
};

export default Svg;
