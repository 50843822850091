import deepmerge from 'deepmerge';

const internalCreateTheme = global => ({
  defaults: {
    multiSelect: {
      borderRadius: global.control.state.normal.borderRadius,
      borderColor: global.control.state.normal.borderColor,
      borderWidth: global.control.state.normal.borderWidth,
      color: global.control.state.normal.color,
      bg: global.control.state.normal.bg,
      disabled: {
        color: global.control.state.disabled.color,
        fontStyle: global.control.state.disabled.fontStyle,
        fontWeight: global.control.state.disabled.fontWeight,
        bg: global.control.state.disabled.bg,
      },
      focus: {
        bg: global.control.state.focus.bg,
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
        fontStyle: global.control.state.focus.fontStyle,
        fontWeight: global.control.state.focus.fontWeight,
        shadow: global.control.state.focus.shadow,
      },
    },
    multiSelection: {
      minHeight: global.control.dimensions.medium.height,
      borderColor: global.control.state.normal.borderColor,
      paddingX: 'small',
      fontWeight: global.control.state.normal.fontWeight,
    },
    multiPlaceholder: {
      color: global.control.state.placeholder.color,
      fontStyle: global.control.state.placeholder.fontStyle,
      fontWeight: global.control.state.placeholder.fontWeight,
      height: global.control.dimensions.medium.height,
      paddingX: 'small',
    },
    single: {
      bg: global.control.state.normal.bg,
      borderColor: global.control.state.normal.borderColor,
      borderRadius: global.control.state.normal.borderRadius,
      borderWidth: global.control.state.normal.borderWidth,
      fontStyle: global.control.state.normal.fontStyle,
      fontWeight: global.control.state.normal.fontWeight,
      height: global.control.dimensions.medium.height,
      paddingX: 'small',
      focus: {
        bg: global.control.state.focus.bg,
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
        fontStyle: global.control.state.focus.fontStyle,
        fontWeight: global.control.state.focus.fontWeight,
        shadow: global.control.state.focus.shadow,
      },
      disabled: {
        bg: global.control.state.disabled.bg,
        borderColor: global.control.state.disabled.borderColor,
        borderWidth: global.control.state.disabled.borderWidth,
        fontStyle: global.control.state.disabled.fontStyle,
        fontWeight: global.control.state.disabled.fontWeight,
        shadow: global.control.state.disabled.shadow,
      },
    },
    options: {
      bg: global.control.state.focus.bg,
      borderColor: global.control.state.focus.borderColor,
      borderRadius: global.control.state.normal.borderRadius,
      borderWidth: global.control.state.focus.borderWidth,
      shadow: global.control.state.focus.shadow,
    },
    option: {
      color: global.control.state.normal.color,
      fontWeight: global.control.state.normal.fontWeight,
      fontSize: 14,
      lineHeight: 16,
      padding: 'small',
      hover: {
        bg: global.control.state.hover.bg,
        color: global.control.state.hover.color,
      },
      focus: {
        bg: global.control.state.hover.bg,
        color: global.control.state.hover.color,
      },
      selected: {
        bg: global.control.state.selected.bg,
        color: global.control.state.selected.color,
      },
    },
    emptyOptions: {
      bg: global.control.state.normal.bg,
      borderColor: global.control.state.normal.borderColor,
      borderRadius: global.control.state.normal.borderRadius,
      borderWidth: global.control.state.normal.borderWidth,
      color: global.control.state.normal.color,
      fontSize: global.control.dimensions.medium.fontSize,
      fontWeight: global.control.state.normal.fontWeight,
      lineHeight: 16,
      padding: 'small',
      shadow: global.control.state.normal.shadow,
    },
    placeholderText: {
      color: global.control.state.placeholder.color,
      fontStyle: global.control.state.placeholder.fontStyle,
      fontWeight: global.control.state.placeholder.fontWeight,
      fontSize: 14,
    },
    selectionText: {
      color: global.control.state.normal.color,
      fontStyle: global.control.state.normal.fontStyle,
      fontSize: 14,
      disabled: {
        color: global.control.state.disabled.color,
        fontStyle: global.control.state.disabled.fontStyle,
        fontWeight: global.control.state.disabled.fontWeight,
      },
    },
    cancel: {
      color: global.control.state.placeholder.color,
      fontStyle: global.control.state.placeholder.fontStyle,
      fontWeight: global.control.state.placeholder.fontWeight,
      fontSize: 13,
    },
    removeButton: {
      paddingY: 'xxsmall',
      paddingLeft: 'xsmall',
      paddingRight: 'xsmall',
      focus: {
        bg: global.control.state.focus.bg,
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
        shadow: global.control.state.focus.shadow,
      },
    },
    downArrowButton: {
      color: global.control.state.normal.iconColor,
      paddingY: 'xxxsmall',
      paddingLeft: 'xxsmall',
      paddingRight: 'xxsmall',
    },
    downArrowIcon: {
      color: global.control.state.normal.iconColorMuted,
      width: 16,
      height: 9,
      disabled: {
        color: global.control.state.disabled.color,
        fontStyle: global.control.state.disabled.fontStyle,
        fontWeight: global.control.state.disabled.fontWeight,
      },
    },
    removeIcon: {
      color: global.control.state.normal.iconColorMuted,
      width: 8,
      height: 8,
    },
    searchIcon: {
      color: global.control.state.normal.iconColorMuted,
      width: 12,
      height: 12,
    },
    ballotBoxIcon: {
      color: global.control.state.normal.iconColor,
      marginRight: 'small',
      width: 18,
      height: 18,
    },
    ballotBoxCheckIcon: {
      color: global.control.state.normal.iconColor,
      marginRight: 'small',
      width: 18,
      height: 18,
      selected: {
        color: global.control.state.selected.borderColor,
      },
    },
    spinnerIcon: {
      color: global.control.state.normal.iconColor,
      width: 18,
      height: 14,
    },
    searchInput: {
      color: global.control.state.normal.color,
      fontStyle: global.control.state.normal.fontStyle,
      fontWeight: global.control.state.normal.fontWeight,
      fontSize: global.control.dimensions.medium.fontSize,
      paddingX: 'small',
    },
  },
  default: {},
  xsmall: {
    single: {
      paddingX: 'small',
      height: global.control.dimensions.xsmall.height,
    },
    multiSelection: {
      minHeight: global.control.dimensions.xsmall.height,
    },
    multiPlaceholder: {
      height: global.control.dimensions.xsmall.height,
      paddingX: 'small',
    },
    selectionText: {
      fontSize: global.control.dimensions.xsmall.fontSize,
    },
    ballotBoxIcon: {
      width: 15,
      height: 15,
    },
    ballotBoxCheckIcon: {
      width: 15,
      height: 15,
    },
    cancel: {
      fontSize: global.control.dimensions.xsmall.fontSize,
    },
    searchIcon: {
      width: 8,
      height: 8,
    },
    searchInput: {
      fontSize: global.control.dimensions.xsmall.fontSize,
      paddingX: 'xsmall',
    },
    placeholderText: {
      fontSize: global.control.dimensions.xsmall.fontSize,
    },
    option: {
      fontSize: global.control.dimensions.xsmall.fontSize,
    },
    emptyOptions: {
      fontSize: global.control.dimensions.xsmall.fontSize,
    },
  },
  small: {
    single: {
      paddingX: 'small',
      height: global.control.dimensions.small.height,
    },
    multiSelection: {
      height: global.control.dimensions.small.height,
    },
    multiPlaceholder: {
      height: global.control.dimensions.small.height,
    },
    selectionText: {
      fontSize: global.control.dimensions.small.fontSize,
    },
    cancel: {
      fontSize: global.control.dimensions.small.fontSize,
    },
    searchIcon: {
      width: 10,
      height: 10,
    },
    searchInput: {
      fontSize: global.control.dimensions.small.fontSize,
      paddingX: 'xsmall',
    },
    ballotBoxIcon: {
      width: 15,
      height: 15,
    },
    ballotBoxCheckIcon: {
      width: 15,
      height: 15,
    },
    placeholderText: {
      fontSize: global.control.dimensions.small.fontSize,
    },
    option: {
      fontSize: global.control.dimensions.small.fontSize,
    },
    emptyOptions: {
      fontSize: global.control.dimensions.small.fontSize,
    },
  },
  medium: {
    single: {
      paddingX: 'small',
      height: global.control.dimensions.medium.height,
    },
    multiSelection: {
      minHeight: global.control.dimensions.medium.height,
    },
    multiPlaceholder: {
      height: global.control.dimensions.medium.height,
    },
    selectionText: {
      fontSize: global.control.dimensions.medium.fontSize,
    },
    cancel: {
      fontSize: global.control.dimensions.medium.fontSize,
    },
    searchIcon: {
      width: 11,
      height: 11,
    },
    searchInput: {
      fontSize: global.control.dimensions.medium.fontSize,
      paddingX: 'xsmall',
    },
    placeholderText: {
      fontSize: global.control.dimensions.medium.fontSize,
    },
    option: {
      fontSize: global.control.dimensions.medium.fontSize,
    },
    emptyOptions: {
      fontSize: global.control.dimensions.medium.fontSize,
    },
  },
  large: {
    single: {
      paddingX: 'medium',
      height: global.control.dimensions.large.height,
    },
    multiSelection: {
      height: global.control.dimensions.large.height,
    },
    multiPlaceholder: {
      height: global.control.dimensions.large.height,
    },
    selectionText: {
      fontSize: global.control.dimensions.large.fontSize,
    },
    cancel: {
      fontSize: global.control.dimensions.medium.fontSize,
    },
    searchIcon: {
      width: 12,
      height: 12,
    },
    searchInput: {
      fontSize: global.control.dimensions.large.fontSize,
      paddingX: 'small',
    },
    placeholderText: {
      fontSize: global.control.dimensions.large.fontSize,
    },
    option: {
      fontSize: global.control.dimensions.large.fontSize,
    },
    emptyOptions: {
      fontSize: global.control.dimensions.large.fontSize,
    },
  },
  xlarge: {
    single: {
      paddingX: 'medium',
      height: global.control.dimensions.xlarge.height,
    },
    multiSelection: {
      height: global.control.dimensions.xlarge.height,
    },
    multiPlaceholder: {
      height: global.control.dimensions.xlarge.height,
    },
    selectionText: {
      fontSize: global.control.dimensions.xlarge.fontSize,
    },
    cancel: {
      fontSize: global.control.dimensions.medium.fontSize,
    },
    searchIcon: {
      width: 12,
      height: 12,
    },
    searchInput: {
      fontSize: global.control.dimensions.xlarge.fontSize,
      paddingX: 'small',
    },
    placeholderText: {
      fontSize: global.control.dimensions.xlarge.fontSize,
    },
    option: {
      fontSize: global.control.dimensions.xlarge.fontSize,
    },
    emptyOptions: {
      fontSize: global.control.dimensions.xlarge.fontSize,
    },
  },
});

const select = ({ defaults }) => {
  const selectTheme = internalCreateTheme(defaults);
  return {
    default: deepmerge(selectTheme.defaults, selectTheme.default),
    xsmall: deepmerge(selectTheme.defaults, selectTheme.xsmall),
    small: deepmerge(selectTheme.defaults, selectTheme.small),
    medium: deepmerge(selectTheme.defaults, selectTheme.medium),
    large: deepmerge(selectTheme.defaults, selectTheme.large),
    xlarge: deepmerge(selectTheme.defaults, selectTheme.xlarge),
  };
};

export default select;
