import styled from '@emotion/styled';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../../_internal/theme/stylesFor';
import { Icon } from '../../../../../index';

const StyledIcon = styled(Icon)`
  vertical-align: middle;

  ${stylesForDefaultState('tree')};

  &:hover {
    ${stylesForHoverState('tree')};
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('tree')};
  }
`;

export default StyledIcon;
