import deepmerge from 'deepmerge';

const calcRootHeight = val => val - val / 4;
const calcIconDim = val => Math.ceil(calcRootHeight(val) / 2);

const internalCreateTheme = global => ({
  defaults: {
    root: {
      height: calcRootHeight(global.control.dimensions.medium.height),
      borderRadius: 'xxsmall',
      paddingX: 'small',
      color: 'white',
    },
    iconStart: {
      marginRight: 'xsmall',
      width: calcIconDim(global.control.dimensions.medium.height),
      height: calcIconDim(global.control.dimensions.medium.height),
    },
    iconEnd: {
      marginLeft: 'xsmall',
      width: calcIconDim(global.control.dimensions.medium.height),
      height: calcIconDim(global.control.dimensions.medium.height),
    },
  },
  default: {},
  xsmall: {
    root: {
      height: calcRootHeight(global.control.dimensions.xsmall.height),
      paddingX: 'xsmall',
      fontSize: 12,
    },
    iconStart: {
      marginRight: 'xsmall',
      width: calcIconDim(global.control.dimensions.xsmall.height),
      height: calcIconDim(global.control.dimensions.xsmall.height),
    },
    iconEnd: {
      marginLeft: 'xsmall',
      width: calcIconDim(global.control.dimensions.xsmall.height),
      height: calcIconDim(global.control.dimensions.xsmall.height),
    },
  },
  small: {
    root: {
      height: calcRootHeight(global.control.dimensions.small.height),
      paddingX: 'small',
      fontSize: 12,
    },
    iconStart: {
      marginRight: 'xsmall',
      width: calcIconDim(global.control.dimensions.small.height),
      height: calcIconDim(global.control.dimensions.small.height),
    },
    iconEnd: {
      marginLeft: 'xsmall',
      width: calcIconDim(global.control.dimensions.small.height),
      height: calcIconDim(global.control.dimensions.small.height),
    },
  },
  medium: {
    root: {
      height: calcRootHeight(global.control.dimensions.medium.height),
      paddingX: 'small',
      fontSize: 13,
    },
    iconStart: {
      marginRight: 'small',
      width: calcIconDim(global.control.dimensions.medium.height),
      height: calcIconDim(global.control.dimensions.medium.height),
    },
    iconEnd: {
      marginLeft: 'small',
      width: calcIconDim(global.control.dimensions.medium.height),
      height: calcIconDim(global.control.dimensions.medium.height),
    },
  },
  large: {
    root: {
      height: calcRootHeight(global.control.dimensions.large.height),
      paddingX: 'small',
      fontSize: 16,
    },
    iconStart: {
      marginRight: 'small',
      width: calcIconDim(global.control.dimensions.large.height),
      height: calcIconDim(global.control.dimensions.large.height),
    },
    iconEnd: {
      marginLeft: 'small',
      width: calcIconDim(global.control.dimensions.large.height),
      height: calcIconDim(global.control.dimensions.large.height),
    },
  },
  xlarge: {
    root: {
      height: calcRootHeight(global.control.dimensions.xlarge.height),
      paddingX: 'medium',
      fontSize: 18,
    },
    iconStart: {
      marginRight: 'medium',
      width: calcIconDim(global.control.dimensions.xlarge.height),
      height: calcIconDim(global.control.dimensions.xlarge.height),
    },
    iconEnd: {
      marginLeft: 'medium',
      width: calcIconDim(global.control.dimensions.xlarge.height),
      height: calcIconDim(global.control.dimensions.xlarge.height),
    },
  },
});

const create = ({ defaults }) => {
  const chipTheme = internalCreateTheme(defaults);
  return {
    default: deepmerge(chipTheme.defaults, chipTheme.default),
    xsmall: deepmerge(chipTheme.defaults, chipTheme.xsmall),
    small: deepmerge(chipTheme.defaults, chipTheme.small),
    medium: deepmerge(chipTheme.defaults, chipTheme.medium),
    large: deepmerge(chipTheme.defaults, chipTheme.large),
    xlarge: deepmerge(chipTheme.defaults, chipTheme.xlarge),
  };
};

export default create;
