import PropType from 'prop-types';
import React, { Children, Fragment, useEffect, useRef, useState } from 'react';
import Tab from './_internal/Tab';
import TabPanel from './_internal/TabsPanel';
import TabsRoot from './_internal/TabsRoot';

const Tabs = ({ children, variant, onChange, selected: controlledSelectedId, centered }) => {
  const [internalSelectedId, setSelectedId] = useState(controlledSelectedId);
  const firstTabIdRef = useRef();

  /* On mount only, set the first item as selectedKey */
  useEffect(() => {
    if (controlledSelectedId === undefined && firstTabIdRef.current !== internalSelectedId) {
      setSelectedId(firstTabIdRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isControlled = controlledSelectedId !== undefined && onChange !== undefined;

  const isSelected = child => {
    if (isControlled) {
      return controlledSelectedId === child.props.id;
    }
    return internalSelectedId !== undefined && internalSelectedId === child.props.id;
  };

  const tabs = [];
  let panel = null;

  Children.forEach(children, child => {
    const { props, type } = child;

    if (firstTabIdRef.current === undefined) {
      firstTabIdRef.current = props.id;
    }

    if (type !== Tab) {
      return tabs.push(child);
    }

    const isSelectedItem = isSelected(child);

    if (isSelectedItem) {
      panel = props.children;
    }

    const onClick = () => {
      if (isControlled) {
        onChange(props.id);
      } else {
        setSelectedId(props.id);
      }
    };

    tabs.push(
      React.cloneElement(
        child,
        {
          variant: variant,
          key: props.id,
          onClick: onClick,
          isSelected: isSelectedItem,
        },
        props.label
      )
    );
  });

  return (
    <Fragment>
      <TabsRoot variant={variant} justifyContent={centered ? 'center' : undefined}>
        {tabs}
      </TabsRoot>
      <TabPanel variant={variant}>{panel}</TabPanel>
    </Fragment>
  );
};

Tabs.propTypes = {
  /**
   * Typically children will be something like:
   *
   * &lt;Tabs.Item label="..."&gt;&lt;/Tabs.Item&gt;
   */
  children: PropType.node,
  /**
   * When tabs are in controlled mode you supply an onChange listener
   * to hold the active tab state, when is then used as the "selceted"
   * prop.
   */
  onChange: PropType.func,
  /**
   * When tabs are in controlled mode you supply the active tab id.
   */
  selected: PropType.any,
  /**
   * The theme variant.
   */
  variant: PropType.string,
  /**
   * Center tabs.
   */
  centered: PropType.bool,
};

Tabs.defaultProps = {
  children: undefined,
  onChange: undefined,
  selected: undefined,
  variant: 'default',
};

Tabs.Item = Tab;

export default Tabs;
