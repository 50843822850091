import styled from '@emotion/styled';
import {
  stylesForDefaultState,
  stylesForDisabledState,
  stylesForFocusState,
  stylesForHoverState,
  stylesForPlaceholderState,
  stylesForReadOnlyState,
} from '../../../_internal/theme/stylesFor';

const InputThemeInput = styled('input')`
  flex: 1;
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  outline: 0;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;

  ${stylesForDefaultState('input')};

  &:hover {
    ${stylesForHoverState('input')};
  }

  &:focus {
    ${stylesForFocusState('input')};
  }

  &:disabled {
    ${stylesForDisabledState('input')};
  }

  &:read-only {
    ${stylesForReadOnlyState('input')};
  }

  &::placeholder {
    ${stylesForPlaceholderState('input')};
  }
`;

export default InputThemeInput;
