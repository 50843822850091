import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const colorValue = opacity => `rgba(128, 128, 128, ${opacity})`;

const fadein = props => keyframes`
    from {
        background-color: rgba(0, 0, 0, 0.0);
    }
    to {
        background-color: ${colorValue(props.opacity)};
    }
`;

const Backdrop = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => colorValue(props.opacity)};
  animation: ${fadein} 150ms linear;
  z-index: ${props => props.zIndex};
`;

Backdrop.propTypes = {
  opacity: PropTypes.number,
  zIndex: PropTypes.number.isRequired,
};

Backdrop.defaultProps = {
  opacity: 0.1,
  zIndex: undefined,
};

export default Backdrop;
