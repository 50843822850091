const isArrowDownKey = event => event.key === 'ArrowDown' || event.key === 40;
const isArrowLeftKey = event => event.key === 'ArrowLeft' || event.key === 37;
const isArrowRightKey = event => event.key === 'ArrowRight' || event.key === 39;
const isArrowUpKey = event => event.key === 'ArrowUp' || event.key === 38;
const isBackspaceKey = event => event.key === 'Backspace' || event.key === 8;
const isDeleteKey = event => event.key === 'Delete' || event.key === 46;
const isEnterKey = event => event.key === 'Enter' || event.key === 13;
const isEscapeKey = event => event.key === 'Escape' || event.key === 69;
const isTabKey = event => (!event.shiftKey && event.key === 'Tab') || event.key === 9;
const isSpaceKey = event => event.key === ' ' || event.key === 20;
const isShiftTabKey = event => (event.shiftKey && event.key === 'Tab') || event.key === 9;
const isBackspaceOrDeleteKey = event => isBackspaceKey(event) || isDeleteKey(event);
const isKeyNext = event => isArrowRightKey(event) || isTabKey(event);
const isKeyPrevious = event => isArrowLeftKey(event) || isShiftTabKey(event);

export {
  isDeleteKey,
  isArrowLeftKey,
  isArrowUpKey,
  isArrowRightKey,
  isArrowDownKey,
  isEnterKey,
  isBackspaceKey,
  isBackspaceOrDeleteKey,
  isTabKey,
  isEscapeKey,
  isKeyNext,
  isKeyPrevious,
  isShiftTabKey,
  isSpaceKey,
};
