import { system } from 'styled-system';

const shadow = system({
  shadow: {
    property: 'boxShadow',
    scale: 'shadows',
  },
});

export default shadow;
