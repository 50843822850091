/**
 * The layout scale is used for arranging components and other UI parts into a full page layout. It
 * has larger increments that are used to control the density of a design..
 */
const layouts = {
  xxxsmall: 16,
  xxsmall: 24,
  xsmall: 32,
  small: 48,
  medium: 64,
  large: 96,
  xlarge: 160,
  xxlarge: 240,
  xxxlarge: 320,
};

export default () => layouts;
