import React from 'react'
import Svg from './Svg'
export const SvgChevronLeftDouble = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M5.11 11.996l9.441-9.441L11.997 0 0 11.996 12.001 24l2.553-2.556z" />
    <path d="M14.556 11.996l9.441-9.441L21.443 0 9.446 11.996 21.447 24 24 21.444z" />
  </Svg>
))
SvgChevronLeftDouble.isIcon = true
export default SvgChevronLeftDouble
