import PropTypes from 'prop-types';
import React from 'react';
import { CalendarThemeBox } from '../base';
import formatDate from '../functions/formatDate';

const NavigatorDate = React.forwardRef(
  ({ date, isDisabled, format, variant, onFocus, onClick, onKeyDown }, ref) => (
    <CalendarThemeBox
      isDisabled={isDisabled}
      onClick={!isDisabled ? onClick : undefined}
      onFocus={!isDisabled ? onFocus : undefined}
      onKeyDown={!isDisabled ? onKeyDown : undefined}
      ref={ref}
      tabIndex={!isDisabled ? 0 : undefined}
      variant={variant}
      variantComponent="navigatorDate"
    >
      {formatDate(date, format)}
    </CalendarThemeBox>
  )
);

NavigatorDate.displayName = 'NavigatorDate';

NavigatorDate.propTypes = {
  /**
   * Instance of date.
   */
  date: PropTypes.instanceOf(Date).isRequired,
  /**
   * Disables navigator date.
   */
  isDisabled: PropTypes.bool,
  /**
   * Date format string
   */
  format: PropTypes.string.isRequired,
  /**
   * Theme variant.
   */
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Handle onFocus event.
   */
  onFocus: PropTypes.func.isRequired,
  /**
   * Handle onClick event.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Handle onKeyDown event.
   */
  onKeyDown: PropTypes.func.isRequired,
};

export default NavigatorDate;
