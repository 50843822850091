import deepmerge from 'deepmerge';

const internalCreateTheme = global => ({
  defaults: {
    root: {
      height: global.control.dimensions.medium.height,
      bg: global.control.state.normal.bg,
      color: global.control.state.normal.color,
      fontWeight: global.control.state.normal.fontWeight,
      borderWidth: global.control.state.normal.borderWidth,
      borderColor: global.control.state.normal.borderColor,
      borderRadius: global.control.state.normal.borderRadius,
      focus: {
        bg: global.control.state.focus.bg,
        borderWidth: global.control.state.focus.borderWidth,
        borderColor: global.control.state.focus.borderColor,
        shadow: global.control.state.focus.shadow,
      },
      disabled: {
        bg: global.control.state.disabled.bg,
        color: global.control.state.disabled.color,
        borderWidth: global.control.state.disabled.borderWidth,
        borderColor: global.control.state.disabled.borderColor,
        fontWeight: global.control.state.disabled.fontWeight,
        fontStyle: global.control.state.disabled.fontStyle,
        shadow: global.control.state.disabled.shadow,
      },
      readonly: {
        bg: global.control.state.disabled.bg,
        color: global.control.state.disabled.color,
        borderWidth: global.control.state.disabled.borderWidth,
        borderColor: global.control.state.disabled.borderColor,
        fontWeight: global.control.state.disabled.fontWeight,
        fontStyle: global.control.state.disabled.fontStyle,
        shadow: global.control.state.disabled.shadow,
      },
    },
    input: {
      bg: 'transparent',
      marginX: 'xsmall',
      fontSize: 14,
      color: global.control.state.normal.color,
      placeholder: {
        color: global.control.state.placeholder.color,
        fontStyle: global.control.state.placeholder.fontStyle,
        fontWeight: global.control.state.placeholder.fontWeight,
      },
      disabled: {
        bg: global.control.state.disabled.bg,
        color: global.control.state.disabled.color,
      },
      readonly: {
        bg: global.control.state.disabled.bg,
        color: global.control.state.disabled.color,
      },
    },
    addonStart: {
      paddingX: 'xsmall',
      color: global.control.state.normal.iconColor,
      focus: {
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
        color: global.control.state.focus.iconColor,
        shadow: global.control.state.focus.shadow,
      },
    },
    addonEnd: {
      paddingX: 'xsmall',
      color: global.control.state.normal.iconColor,
      focus: {
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
        color: global.control.state.focus.iconColor,
        shadow: global.control.state.focus.shadow,
      },
    },
    iconCalendar: {
      width: 16,
      height: 16,
      color: global.control.state.normal.iconColor,
      focus: {
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
        color: global.control.state.focus.iconColor,
        shadow: global.control.state.focus.shadow,
      },
      disabled: {
        color: global.control.state.disabled.color,
      },
    },
    iconCurrency: {
      width: 16,
      height: 16,
      color: global.control.state.normal.iconColor,
      disabled: {
        color: global.control.state.disabled.color,
      },
    },
    iconClear: {
      width: 12,
      height: 12,
      color: global.control.state.normal.iconColor,
      focus: {
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
        color: global.control.state.focus.iconColor,
        shadow: global.control.state.focus.shadow,
      },
    },
  },
  default: {},
  search: {
    root: {
      borderRadius: 999,
      bg: '#F8F8F8',
    },
  },
  xsmall: {
    root: {
      height: global.control.dimensions.xsmall.height,
    },
    input: {
      fontSize: 12,
    },
  },
  small: {
    root: {
      height: global.control.dimensions.small.height,
    },
    input: {
      fontSize: 13,
    },
  },
  medium: {
    root: {
      height: global.control.dimensions.medium.height,
    },
    input: {
      fontSize: 14,
    },
  },
  large: {
    root: {
      height: global.control.dimensions.large.height,
    },
    input: {
      fontSize: 16,
    },
  },
  xlarge: {
    root: {
      height: global.control.dimensions.xlarge.height,
    },
    input: {
      fontSize: 18,
    },
  },
});

const create = ({ defaults }) => {
  const inputTheme = internalCreateTheme(defaults);
  return {
    default: deepmerge(inputTheme.defaults, inputTheme.default),
    search: deepmerge(inputTheme.defaults, inputTheme.search),
    xsmall: deepmerge(inputTheme.defaults, inputTheme.xsmall),
    small: deepmerge(inputTheme.defaults, inputTheme.small),
    medium: deepmerge(inputTheme.defaults, inputTheme.medium),
    large: deepmerge(inputTheme.defaults, inputTheme.large),
    xlarge: deepmerge(inputTheme.defaults, inputTheme.xlarge),
  };
};

export default create;
