import getMonth from 'date-fns/getMonth';
import PropTypes from 'prop-types';
import React, { useImperativeHandle, useRef } from 'react';
import { Table, TableRow } from '../base';
import { MONTHS_OF_YEAR } from '../constants';
import Month from './Month';

const monthsTable = [
  MONTHS_OF_YEAR.slice(0, 3),
  MONTHS_OF_YEAR.slice(3, 6),
  MONTHS_OF_YEAR.slice(6, 9),
  MONTHS_OF_YEAR.slice(9, 12),
];

const makeSelector = month => `div[data-month="${month}"]`;

const PickMonth = React.forwardRef(
  ({ date, onPickMonth, onFocusMonth, variant, canSelectMonth }, ref) => {
    const tableRef = useRef(null);

    const getActiveMonthInt = () => {
      return Number.parseInt(document.activeElement.getAttribute('data-month'));
    };

    const getMonthElement = year => {
      return tableRef.current.querySelector(makeSelector(year));
    };

    useImperativeHandle(ref, () => ({
      focusInitial: () => {
        const monthToFocus = getMonthElement(0);
        if (monthToFocus) {
          monthToFocus.focus();
          return true;
        }
        return false;
      },
      focusUp: () => {
        const monthToFocus = getMonthElement(getActiveMonthInt() - 3);
        if (monthToFocus) {
          monthToFocus.focus();
          return true;
        }
        return false;
      },
      focusDown: () => {
        const monthToFocus = getMonthElement(getActiveMonthInt() + 3);
        if (monthToFocus) {
          monthToFocus.focus();
          return true;
        }
        return false;
      },
      focusLeft: () => {
        const monthToFocus = getMonthElement(getActiveMonthInt() - 1);
        if (monthToFocus) {
          monthToFocus.focus();
          return true;
        }
        return false;
      },
      focusRight: () => {
        const monthToFocus = getMonthElement(getActiveMonthInt() + 1);
        if (monthToFocus) {
          monthToFocus.focus();
          return true;
        }
        return false;
      },
    }));

    return (
      <Table isCollapsed={false} ref={tableRef}>
        {monthsTable.map((row, rowIdx) => (
          <TableRow key={rowIdx}>
            {row.map(month => {
              const isCanSelectMonth = canSelectMonth(month.number);
              return (
                <Month
                  date={date}
                  isSelected={getMonth(date) === month.number}
                  key={month.short}
                  month={month}
                  onClickMonth={isCanSelectMonth ? onPickMonth : undefined}
                  onFocusMonth={onFocusMonth}
                  tabIndex={0}
                  variant={variant}
                  isDisabled={!isCanSelectMonth}
                >
                  {month.short}
                </Month>
              );
            })}
          </TableRow>
        ))}
      </Table>
    );
  }
);

PickMonth.propTypes = {
  /**
   * Callback to determine if month can be selected.
   */
  canSelectMonth: PropTypes.func.isRequired,
  /**
   * Selected date.
   */
  date: PropTypes.instanceOf(Date),
  /**
   * Calendar theme variant.
   */
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Called when month is picked.
   */
  onPickMonth: PropTypes.func.isRequired,
  /**
   * Called when a month dom element is focused.
   */
  onFocusMonth: PropTypes.func.isRequired,
};

PickMonth.defaultProps = {
  date: undefined,
  canSelectMonth: () => true,
};

PickMonth.displayName = 'PickMonth';

export default PickMonth;
