import { config, useTransition } from 'react-spring';

const useSlideTransition = ({ isOpen }) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0, transform: 'translate(-50%, 100%)' },
    enter: { opacity: 1, transform: 'translate(-50%, -50%)' },
    leave: { opacity: 0, transform: 'translate(-50%, 100%)' },
    onStart: (isOpen, lifecycle) => {
      if (isOpen && lifecycle === 'enter') {
        //console.log('calling onEnter'); TODO add onEnter cb
      }
    },
    onRest: (isOpen, lifecycle) => {
      if (!isOpen && lifecycle === 'update') {
        //console.log('calling onExited'); TODO add onExited cb
      }
    },
    config: (toast, lifecycle) => {
      if (lifecycle === 'leave') {
        return { duration: 150 };
      }
      return config.stiff;
    },
  });

  return transitions;
};

export default useSlideTransition;
