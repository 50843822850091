import React from 'react'
import Svg from './Svg'
export const SvgLocation = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M10.667 21.227V24h2.666v-2.773a9.334 9.334 0 007.894-7.894H24v-2.666h-2.773a9.334 9.334 0 00-7.894-7.894V0h-2.666v2.773a9.334 9.334 0 00-7.894 7.894H0v2.666h2.773a9.334 9.334 0 007.894 7.894zM12 5.333c3.68 0 6.667 2.987 6.667 6.667S15.68 18.667 12 18.667 5.333 15.68 5.333 12 8.32 5.333 12 5.333z" />
      <path d="M12 16c2.213 0 4-1.787 4-4s-1.787-4-4-4-4 1.787-4 4 1.787 4 4 4z" />
    </g>
  </Svg>
))
SvgLocation.isIcon = true
export default SvgLocation
