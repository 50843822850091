import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '../../../../Box';
import BallotBoxCheckIcon from './internal/BallotBoxCheckIcon';
import BallotBoxIcon from './internal/BallotBoxIcon';
import OptionPlainText from './OptionPlainText';

const CheckboxOption = ({ option, renderOption, variant, index, isSelected }) => {
  return (
    <Flex column alignItems="center">
      <Flex.Item flex={0} display="inherit">
        {isSelected ? (
          <BallotBoxCheckIcon variant={variant} selected />
        ) : (
          <BallotBoxIcon variant={variant} />
        )}
      </Flex.Item>
      <Flex.Item flex={1}>
        <OptionPlainText renderOption={renderOption} option={option} index={index} />
      </Flex.Item>
    </Flex>
  );
};

CheckboxOption.displayName = 'CheckboxOption';

CheckboxOption.propTypes = {
  option: PropTypes.any.isRequired,
  isSelected: PropTypes.bool.isRequired,
  renderOption: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  index: PropTypes.number.isRequired,
};

CheckboxOption.defaultProps = {
  optionId: undefined,
  renderOption: undefined,
  highlightedIndex: undefined,
};

export default CheckboxOption;
