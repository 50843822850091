import styled from '@emotion/styled';
import { stylesForDefaultState } from '../../../../../_internal/theme/stylesFor';

const Root = styled('ol')`
  margin: 0;
  padding: 0;
  list-style-type: none;

  ${stylesForDefaultState('tree')}

  ol {
    padding-left: 26px;
  }
`;

export default Root;
