import { system } from 'styled-system';
import { getPxAbsolute } from '../internal';

const borderBottomWidth = system({
  borderBottomWidth: {
    property: 'borderBottomWidth',
    transform: getPxAbsolute,
  },
});

export default borderBottomWidth;
