import getDate from 'date-fns/getDate';
import isSameDay from 'date-fns/isSameDay';
import PropTypes from 'prop-types';
import React from 'react';
import DateRange from '../../../_internal/model/DateRange';
import { TableRow } from '../base';
import Day from './Day';

const isSelected = (date, selected) => {
  if (selected instanceof DateRange) {
    return isSameDay(date, selected.start) || isSameDay(date, selected.end);
  }
  return isSameDay(date, selected);
};

const isHighlighted = (date, selected) => {
  if (selected instanceof DateRange) {
    return date < selected.end && date > selected.start;
  }
  return false;
};

const Week = ({ selected, days, variant, onClickDate, onFocusDate, isDisabled }) => {
  return (
    <TableRow>
      {days.map((day, idx) => {
        return (
          <Day
            day={day}
            isDisabled={isDisabled}
            isSelected={isSelected(day.date, selected)}
            isHighlighted={isHighlighted(day.date, selected)}
            key={idx}
            onClick={onClickDate}
            onFocus={onFocusDate}
            variant={variant}
          >
            {getDate(day.date)}
          </Day>
        );
      })}
    </TableRow>
  );
};

Week.propTypes = {
  days: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  onClickDate: PropTypes.func,
  onFocusDate: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(DateRange)]),
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

Week.defaultProps = {
  isDisabled: false,
  onClickDate: undefined,
  onFocusDate: undefined,
  selected: undefined,
};

export default Week;
