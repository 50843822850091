import PropTypes from 'prop-types';
import React from 'react';
import BaseThemeIcon from '../../base/BaseThemeIcon';

const SearchIcon = ({ variant }) => (
  <BaseThemeIcon name="Search" variant={variant} variantComponent="searchIcon" />
);

SearchIcon.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default SearchIcon;
