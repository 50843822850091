import styled from "@emotion/styled";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
  textAlign,
  textStyle
} from "@modernary/ui-kit-styled-system";
import PropTypes from "prop-types";

const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  ${spacing}
  ${textStyle}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${color}
  &:hover {
    text-decoration: underline;
  }
`;

Link.displayName = "Link";

Link.propTypes = {
  /**
   * Color and bg.
   */
  ...color.propTypes,
  theme: PropTypes.object,
  ...textStyle.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...spacing.propTypes
};

Link.defaultProps = {
  color: "blue.700"
};

export default Link;
