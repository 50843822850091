import React from 'react'
import Svg from './Svg'
export const SvgNoteAdd = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M3.9 19.5v-9.3h4.7c.5 0 .9-.4.9-.9v-5H19v6.4c.7.1 1.3.3 1.9.6v-7c0-1-.8-1.9-1.9-1.9H8.6c-.5 0-1 .2-1.4.6L2.5 8c-.3.4-.5.8-.5 1.3v10.2c0 1 .9 1.9 1.9 1.9h7.8c-.3-.6-.6-1.2-.8-1.9h-7zM7.7 5.3v3H4.8l2.9-3z" />
    <path d="M17.8 11.7c-3.4 0-6.2 2.8-6.2 6.2 0 3.4 2.8 6.2 6.2 6.2s6.2-2.8 6.2-6.2c0-3.5-2.8-6.2-6.2-6.2zm-2.1 6.8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1.8v-1.8c0-.3.2-.5.5-.5s.5.2.5.5v1.8h1.8c.3 0 .5.2.5.5s-.2.5-.5.5h-1.8v1.8c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-1.8h-1.8z" />
  </Svg>
))
SvgNoteAdd.isIcon = true
export default SvgNoteAdd
