import PropTypes from 'prop-types';
import React from 'react';
import BaseThemeIcon from '../../base/BaseThemeIcon';

const BallotBoxIcon = ({ variant }) => (
  <BaseThemeIcon name="BallotBox" variant={variant} variantComponent="ballotBoxIcon" />
);

BallotBoxIcon.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default BallotBoxIcon;
