import React from 'react'
import Svg from './Svg'
export const SvgCalendar = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M19.814 2.581h-.717v-.783C19.097.806 18.314 0 17.35 0c-.964 0-1.746.806-1.746 1.798v.782h-1.791v-.782C13.814.806 13.031 0 12.068 0s-1.745.806-1.745 1.798v.782H8.53v-.782C8.531.806 7.75 0 6.786 0 5.823 0 5.04.806 5.04 1.798v.782h-.916A2.782 2.782 0 001.345 5.36v15.69a2.782 2.782 0 002.779 2.78h15.69a2.782 2.782 0 002.779-2.78V5.36a2.782 2.782 0 00-2.779-2.778zm-3.228-.783c0-.45.343-.817.765-.817s.765.366.765.817v3.2c0 .451-.343.818-.765.818s-.765-.367-.765-.817V1.798zm-5.283 0c0-.45.344-.817.765-.817s.765.366.765.817v3.2c0 .451-.343.818-.765.818s-.765-.367-.765-.817V1.798zm-5.283 0c0-.45.344-.817.765-.817s.765.366.765.817v3.2c0 .451-.343.818-.765.818s-.765-.367-.765-.817V1.798zM20.96 21.05c0 .631-.514 1.145-1.145 1.145H4.124c-.631 0-1.144-.514-1.144-1.145V6.667h17.979V21.05z" />
      <path d="M4.45 9.562h3.005v2.673H4.45zM8.46 9.562h3.005v2.673H8.46zM12.473 9.562h3.004v2.673h-3.004zM16.483 9.562h3.004v2.673h-3.004zM4.45 13.13h3.005v2.672H4.45zM8.46 13.13h3.005v2.672H8.46zM12.473 13.13h3.004v2.672h-3.004zM16.483 13.13h3.004v2.672h-3.004zM4.45 16.698h3.005v2.67H4.45zM8.46 16.698h3.005v2.67H8.46zM12.473 16.698h3.004v2.67h-3.004zM16.483 16.698h3.004v2.67h-3.004z" />
    </g>
  </Svg>
))
SvgCalendar.isIcon = true
export default SvgCalendar
