import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';

export default class Hover extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isHovering: false };
  }

  mouseEnter = debounce(() => {
    this.setState({ isHovering: true });
  }, this.props.enterDelay);

  mouseLeave = debounce(() => {
    this.setState({ isHovering: false });
    this.mouseEnter.cancel();
  }, this.props.leaveDelay);

  getHoverProps = () => {
    if (this.props.disable) {
      return {};
    }

    return {
      onBlur: this.mouseLeave,
      onFocus: this.mouseEnter,
      onMouseEnter: this.mouseEnter,
      onMouseLeave: this.mouseLeave,
    };
  };

  render() {
    const { children } = this.props;

    if (!children) {
      return null;
    }

    return (
      <Fragment>
        {children({
          getHoverProps: this.getHoverProps,
          isHovering: this.state.isHovering,
        })}
      </Fragment>
    );
  }
}

Hover.propTypes = {
  disable: PropTypes.bool,
  children: PropTypes.func,
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number,
};

Hover.defaultProps = {
  disable: undefined,
  children: null,
  enterDelay: undefined,
  leaveDelay: undefined,
};
