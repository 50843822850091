import PropTypes from 'prop-types';
import React from 'react';
import { isEnterKey } from '../../../../../_internal/isKey';
import { BaseThemeBox } from '../../base';
import RemoveIcon from './RemoveIcon';

const RemoveButton = ({ variant, onClick }) => {
  const handleClick = e => {
    onClick && onClick(e);
    e.stopPropagation();
  };

  const handleKeyDown = e => {
    if (isEnterKey(e)) {
      onClick && onClick(e);
    }
  };

  return (
    <BaseThemeBox
      onClick={handleClick}
      role="button"
      tabIndex={0}
      onFocus={e => e.stopPropagation()}
      variant={variant}
      variantComponent="removeButton"
      onKeyDown={handleKeyDown}
      aria-label="Clear selection"
      title="Clear selection"
    >
      <RemoveIcon variant={variant} />
    </BaseThemeBox>
  );
};

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default RemoveButton;
