/**
 * A set of style properties for varieties of buttons.
 * These options are mostly concerned with button dimensions. Other aspects of button theme are
 * derived by a mostly static set of button variants. More options for extending button variants
 * will be forthcoming. `paddingY` will be calculated by the buttonSize style variant function,
 * based on the target height. This is necessary, because changing border widths would otherwise
 * throw off the computed height, and result in misalignment.
 *
 * TODO:  Hoist the `height` for each size up to a more general attribute for all form controls, so
 *        buttons line up with inputs, select boxes, etc.
 */
const defaultOpts = {
  defaults: {
    borderWidth: 1,
    lineHeight: 1.2, // NOTICE: These are relative units (equivalent to ems), NOT pixels!
  },
  sizes: {
    xsmall: {
      fontSize: 11,
      paddingX: 14,
      height: 28,
    },
    small: {
      fontSize: 13,
      paddingX: 17,
      height: 34,
    },
    medium: {
      fontSize: 14,
      paddingX: 19,
      height: 40,
    },
    large: {
      fontSize: 16,
      paddingX: 24,
      height: 46,
    },
    xlarge: {
      fontSize: 18,
      paddingX: 28,
      height: 52,
    },
  },
};

const createButtons = ({ buttonOpts = {} } = {}) => {
  // Spread out base options among defaults
  const buttons = {
    defaults: {
      ...defaultOpts.defaults,
      ...buttonOpts.defaults,
    },
    sizes: {
      ...defaultOpts.sizes,
      ...buttonOpts.sizes,
    },
  };
  // Fill out each entry in `sizes` with any missing properties
  buttons.sizes = Object.keys(buttons.sizes).reduce((output, key) => {
    return {
      ...output,
      [key]: {
        ...buttons.defaults,
        ...defaultOpts.sizes[key],
        ...(buttonOpts.sizes ? buttonOpts.sizes[key] : null),
      },
    };
  }, {});
  return buttons;
};

export default createButtons;
