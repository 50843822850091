import { system } from 'styled-system';
import { getColor } from '../internal';

const borderTopColor = system({
  borderTopColor: {
    property: 'borderTopColor',
    scale: 'colors',
    transform: getColor,
  },
});

export default borderTopColor;
