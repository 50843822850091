import styled from '@emotion/styled';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../_internal/theme/stylesFor';
import { Flex } from '../../../Box';

const InputThemeAddon = styled(Flex)`
  height: 100%;
  align-items: center;
  justify-content: center;
  ${stylesForDefaultState('input')};

  &:hover {
    ${stylesForHoverState('input')};
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('input')};
  }
`;

export default InputThemeAddon;
