import { compose } from 'styled-system';
import backgroundColor from './backgroundColor';
import textColor from './textColor';

export default compose(
  textColor,
  backgroundColor
);

export { textColor, backgroundColor };
