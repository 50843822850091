import { get } from 'styled-system';
import { isNotNum } from './isNum';
import px from './px';

/**
 * Returns number formatted as Xpx.
 */
const getPx = (n, scale) => {
  if (isNotNum(n)) {
    return px(get(scale, n, n));
  }

  return px(n);
};

export default getPx;
