import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '../../../../Box';
import DownArrowButton from './internal/DownArrowButton';

const DownArrowFlexItems = ({ variant, isDisabled }) => {
  return (
    <Flex.Item flex={0}>
      <DownArrowButton isDisabled={isDisabled} variant={variant} />
    </Flex.Item>
  );
};

DownArrowFlexItems.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default DownArrowFlexItems;
