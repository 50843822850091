import deepmerge from 'deepmerge';
import breakpoints from '../scales/breakpoints';
import createButtons from '../buttons';
import createColors from '../colors/createColors';
import calendar from '../controls/calendar';
import checkbox from '../controls/checkbox';
import input from '../controls/input';
import modal from '../controls/modal';
import radio from '../controls/radio';
import select from '../controls/select';
import _switch from '../controls/switch';
import tabs from '../controls/tabs';
import toasts from '../controls/toasts';
import tree from '../controls/tree';
import chip from '../controls/chip';
import createFonts from '../fonts/createFonts';
import radii from '../scales/radii';
import shadows from '../scales/shadows';
import layouts from '../scales/layouts';
import spacings from '../scales/spacings';
import createTypography from '../typography';
import globalDefaults from '../core/globalDefaults';

/**
 * A function that accepts options object and returns another object consisting of style rules.
 * Values at a specific path on that shape are used as a source of style value in your view
 * library or project.
 *
 * @param options Options used to generate the styles object.
 */
const createTheme = (options = {}) => {
  const defaults = deepmerge(globalDefaults, options.defaults || {});
  const fonts = createFonts({ fonts: options.fonts });

  return {
    defaults: defaults,
    fonts: fonts,
    breakpoints: breakpoints({ breakpoints: options.breakpoints }),
    colors: createColors({ baseColors: options.colors }),
    spacings: spacings(),
    layouts: layouts(),
    shadows: shadows(),
    radii: radii(),
    typography: createTypography({ variants: options.typography, fonts: fonts }),

    ///////
    calendar: calendar({ defaults }),
    select: select({ defaults }),
    buttons: createButtons({ buttonOpts: options.buttons }),
    checkbox: checkbox({ defaults }),
    chip: chip({ defaults }),
    input: input({ defaults }),
    modal: modal({ defaults }),
    radio: radio({ defaults }),
    switch: _switch({ defaults }),
    tabs: tabs({ defaults }),
    toasts: toasts({ defaults }),
    tree: tree({ defaults }),
  };
};

export { createTheme as default };
