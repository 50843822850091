import PropTypes from 'prop-types';
import React from 'react';
import { CalendarThemeTableCell, Table, TableRow } from '../base';
import { DAYS_OF_WEEK } from '../constants';

const DaysOfWeek = ({ variant }) => (
  <Table>
    <TableRow>
      {DAYS_OF_WEEK.map(dayOfWeek => (
        <CalendarThemeTableCell
          key={dayOfWeek.short}
          variant={variant}
          variantComponent="daysOfWeek"
          width="14.285%"
        >
          {dayOfWeek.letter}
        </CalendarThemeTableCell>
      ))}
    </TableRow>
  </Table>
);

DaysOfWeek.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default DaysOfWeek;
