import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '../../../';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../_internal/theme/stylesFor';

const StyledTab = styled(Flex.Item)`
  display: block;
  align-self: flex-end;

  ${stylesForDefaultState('tabs')}

  &:hover {
    ${stylesForHoverState('tabs')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('tabs')}
  }
`;

const Tab = ({ children, variant, ...rest }) => {
  return (
    <StyledTab variant={variant} variantComponent="tab" {...rest}>
      {children}
    </StyledTab>
  );
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

Tab.defaultProps = {
  variant: 'default',
};

export default Tab;
