import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

// Styled to make the checkbox hidden visually but
// still recognizable by screen readers.
const InputStyled = styled('input')`
  border: 0;
  clip: rect(0px, 0px, 0px, 0px);
  clipPath: inset(50%),
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  whiteSpace: nowrap
  width: 1px;
`;

const Input = React.forwardRef(
  ({ id, name, isChecked, onChange, value, isReadOnly, isDisabled }, ref) => (
    <InputStyled
      ref={ref}
      checked={isChecked || false}
      disabled={isDisabled}
      id={id}
      name={name}
      onChange={onChange}
      readOnly={isReadOnly}
      tabIndex={-1}
      type="checkbox"
      value={value || name || id}
    />
  )
);

Input.displayName = 'Input';

Input.propTypes = {
  isChecked: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

Input.defaultProps = {
  isChecked: undefined,
  isReadOnly: undefined,
  isDisabled: undefined,
  id: undefined,
  name: undefined,
  value: undefined,
  onChange: undefined,
  onClick: undefined,
};

export default Input;
