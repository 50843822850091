import deepmerge from 'deepmerge';

const internalCreateTheme = global => ({
  defaults: {
    tabs: {
      paddingTop: 'small',
      borderBottomColor: 'grey.100',
      borderBottomWidth: 1,
    },
    tab: {
      padding: 'medium',
      paddingX: 'large',
      fontSize: 15,
      color: global.control.state.normal.color,
      borderBottomColor: 'transparent',
      borderBottomWidth: 2,
      selected: {
        borderBottomColor: global.control.state.selected.borderColor,
        color: global.control.state.normal.color,
      },
    },
    panel: {
      bg: 'transparent',
    },
  },
  default: {},
});

const create = ({ defaults }) => {
  const tabsTheme = internalCreateTheme(defaults);
  return {
    default: deepmerge(tabsTheme.defaults, tabsTheme.default, defaults),
  };
};

export default create;
