import styled from '@emotion/styled';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../_internal/theme/stylesFor';

const BaseThemeInput = styled('input')`
  border: 0;
  width: 100%;
  box-sizing: border-box;
  ${stylesForDefaultState('select')}

  &:hover {
    ${stylesForHoverState('select')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('select')}
  }
`;

export default BaseThemeInput;
