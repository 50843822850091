import React from 'react'
import Svg from './Svg'
export const SvgReceipt = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M5.9 0H3.8c-.7 0-1.3.7-1.3 1.4v21.1c0 .8.6 1.4 1.3 1.4h16.4c.7 0 1.3-.6 1.3-1.4V1.4c0-.8-.6-1.4-1.3-1.4H5.9zm13.8 22.2H4.3v-17h15.4v17z" />
    <path d="M17.1 8.8H6.9c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h10.3c.5 0 .9.4.9.9s-.4.9-1 .9zM11.8 13H6.9c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h4.9c.5 0 .9.4.9.9s-.4.9-.9.9zM11.8 17.2H6.9c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h4.9c.5 0 .9.4.9.9s-.4.9-.9.9z" />
  </Svg>
))
SvgReceipt.isIcon = true
export default SvgReceipt
