import React from 'react'
import Svg from './Svg'
export const SvgBallotBoxIndeterminate = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M2.99.07A3.003 3.003 0 00.01 3.069l.066 17.934a3.004 3.004 0 003 2.978l17.935-.066a3.003 3.003 0 002.978-3l-.066-17.934a3.002 3.002 0 00-3-2.977L2.99.07zm.007 1.99l17.933-.065a.984.984 0 011 .994l.066 17.933a.983.983 0 01-.993 1l-17.933.066a.984.984 0 01-1.001-.993L2.003 3.062a.985.985 0 01.994-1.001z" />
    <path d="M5.5 4.5h13a1 1 0 011 1v13a1 1 0 01-1 1h-13a1 1 0 01-1-1v-13a1 1 0 011-1z" />
  </Svg>
))
SvgBallotBoxIndeterminate.isIcon = true
export default SvgBallotBoxIndeterminate
