import PropTypes from 'prop-types';
import React from 'react';
import { BaseThemeText } from '../../base';
import { optionLabel } from '../../functions';

const SelectionText = ({ variant, renderSelection, option, isDisabled }) => (
  <BaseThemeText variant={variant} variantComponent="selectionText" isDisabled={isDisabled}>
    {optionLabel(renderSelection, option)}
  </BaseThemeText>
);

SelectionText.displayName = 'SelectionText';

SelectionText.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  option: PropTypes.any.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  renderSelection: PropTypes.func,
};

SelectionText.defaultProps = {
  renderOption: undefined,
};

export default SelectionText;
