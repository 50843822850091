import PropTypes from 'prop-types';
import React from 'react';
import BaseInput from '../_internal/base/BaseInput';

const TextInput = React.forwardRef(
  (
    {
      addonEnd,
      addonStart,
      defaultValue,
      id,
      isDisabled,
      isReadOnly,
      isShowClear,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      value,
      variant,
      width,
    },
    ref
  ) => {
    const handleOnChange = e => {
      onChange && onChange(e.target.value);
    };

    const handleOnRequestClear = () => onChange && onChange(null);

    const canShowClear = !!(isShowClear && value && value.toString().length > 0);

    return (
      <BaseInput
        addonEnd={addonEnd}
        addonStart={addonStart}
        defaultValue={defaultValue}
        id={id}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isShowClear={canShowClear}
        name={name}
        onBlur={onBlur}
        onChange={handleOnChange}
        onFocus={onFocus}
        onRequestClear={handleOnRequestClear}
        placeholder={placeholder}
        ref={ref}
        type="text"
        value={value}
        variant={variant}
        width={width}
      />
    );
  }
);

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
  /**
   * Addon component placed at the end on the input, usually this is an Icon.
   */
  addonEnd: PropTypes.node,
  /**
   * Addon component placed at the start on the input, usually this is an Icon.
   */
  addonStart: PropTypes.node,
  /**
   * Sets value on input when intended for use as an uncontrolled input.
   */
  defaultValue: PropTypes.string,
  /**
   * Sets the input id.
   */
  id: PropTypes.string,
  /**
   * Disables the input.
   */
  isDisabled: PropTypes.bool,
  /**
   * Sets the input as readonly.
   */
  isReadOnly: PropTypes.bool,
  /**
   * Show clear button and allow user to clear input.
   */
  isShowClear: PropTypes.bool,
  /**
   * Sets the input name.
   */
  name: PropTypes.string,
  /**
   * Called when input is blurred.
   */
  onBlur: PropTypes.func,
  /**
   * Called when the input value changes.
   */
  onChange: PropTypes.func,
  /**
   * Called when input is focused.
   */
  onFocus: PropTypes.func,
  /**
   * The input placeholder.
   */
  placeholder: PropTypes.string,
  /**
   * The input value.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The input theme variant.
   */
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Outer width of the input.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextInput.defaultProps = {
  variant: 'default',
  defaultValue: undefined,
  isShowClear: true,
};

export default TextInput;
