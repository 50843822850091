import React from 'react'
import Svg from './Svg'
export const SvgWriteDoc = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M12.663 17.973l-.108-.11c-.541-.548-.974-1.205-1.299-1.753-.108.109-.217.219-.217.328l-.974 3.508c-.108.438.109.657.542.438l3.246-1.206c.109 0 .217-.109.325-.219-.757-.329-1.299-.767-1.515-.986zM12.338 14.685c0 .219 0 .877 1.299 2.082.216.22 1.082.986 1.623.986.109 0 .217 0 .325-.109l5.953-6.028.108-.109c-.758-.329-1.299-.877-1.515-1.096l-.108-.109c-.65-.658-1.191-1.315-1.407-1.974 0 0-.109 0-.109.111l-6.169 6.246zM19.806 7.233c0 .22 0 .877 1.299 2.083.216.218 1.082.986 1.623.986.109 0 .217 0 .325-.11l.866-.876c.108-.111.108-.329 0-.549l-2.598-2.63c-.108-.109-.324-.109-.541 0l-.974 1.096c0-.11 0 0 0 0z" />
    <path d="M16.559 18.301v3.727H1.948V1.972h8.442v5.042c0 .548.433.986.974.986h5.195v.987l1.948-1.864v-.109c0-.548-.216-1.097-.649-1.425L12.663.548C12.338.219 11.797 0 11.256 0H1.948A1.952 1.952 0 000 1.972v20.056C0 23.124.866 24 1.948 24h14.611a1.952 1.952 0 001.948-1.972v-5.809l-1.948 2.082zM12.338 2.959l3.139 3.069h-3.139V2.959z" />
  </Svg>
))
SvgWriteDoc.isIcon = true
export default SvgWriteDoc
