import isArray from 'lodash/isArray';

/**
 *
 */
class NodeModel {
  constructor({ nodes, value, expanded, nodeId, nodeChildren, nodeLabel, nodeValue }) {
    this.nodes = nodes;
    this.selectNodeId = nodeId;
    this.selectNodeChildren = nodeChildren;
    this.selectNodeLabel = nodeLabel;
    this.selectNodeValue = nodeValue;
    this.flatNodes = [];
    this._flattenNodes(nodes);
    this._set(value, expanded);
    this._updateDescendantsCheckedFlag();
  }

  /**
   * Returns the flat list item for the given node.
   */
  getFlatNode(node) {
    return this.flatNodes[this.selectNodeId(node)];
  }

  /**
   * Returns all node values that are checked.
   */
  getCheckedValues() {
    return this._getValues('isChecked');
  }

  /**
   * Returns all node values that are expanded.
   */
  getExpandedValues() {
    return this._getValues('isExpanded');
  }

  /**
   * Returns all flat list items.
   */
  getFlatNodes() {
    return this.flatNodes;
  }

  /**
   * Returns the previous node in the tree to focus on, we're traversing back up the tree.
   */
  getFocusPreviousFlatNode(node) {
    const currentIdx = Object.keys(this.flatNodes).findIndex(key => key === node.id);
    const current = this.flatNodes[node.id];
    const previous = Object.values(this.flatNodes)[currentIdx - 1];

    // reached top of the tree!
    if (!previous) {
      return false;
    }

    if (previous.depth !== current.depth) {
      // get all nodes up to current index and then reverse it.
      const nodes = Object.values(this.flatNodes)
        .slice(0, currentIdx)
        .reverse();

      // find next node at the same depth.
      const nodeIndexAtCurrentDepth = nodes.findIndex(n => n.depth === current.depth);
      const nodeAtCurrentDepth = nodes[nodeIndexAtCurrentDepth];

      // not found, go up one depth.
      if (nodeAtCurrentDepth === undefined) {
        const nodeIndexAtPreviousDepth = nodes.findIndex(n => n.depth === current.depth - 1);
        return nodes[nodeIndexAtPreviousDepth];
      } else if (nodeAtCurrentDepth.isParent && !nodeAtCurrentDepth.isExpanded) {
        return nodeAtCurrentDepth;
      }
    }
    return previous;
  }

  /**
   * Returns the next node in the tree to focus on, we're traversing down the tree.
   */
  getFocusNextFlatNode(node) {
    const currentIdx = Object.keys(this.flatNodes).findIndex(key => key === node.id);
    const current = this.flatNodes[node.id];

    if (current.isLeaf) {
      return Object.values(this.flatNodes)[currentIdx + 1];
    }

    if (current.isParent && current.isExpanded) {
      return Object.values(this.flatNodes)[currentIdx + 1];
    }

    if (current.isParent && !current.isExpanded) {
      const nodes = Object.values(this.flatNodes).slice(currentIdx + 1);
      const foundIndex = nodes.findIndex(n => n.depth === current.depth);
      return nodes[foundIndex];
    }
  }

  /**
   * Returns an abbreviated list of nodes by excluding all the descendants of parent nodes that
   * have all of their descendants checked. For example, If the entire tree is selected only the
   * first node will be returned. Another example, if node j is a parent and ALL it's descendents
   * are checked then only the node j is included and node j descendants are excluded.
   */
  getAbbreviatedSelections = () => {
    const toClean = Object.values(this.flatNodes);
    const cleaned = [];

    for (let i = 0; i < toClean.length; i++) {
      // They are all checked, break.
      if (toClean[i].depth === 0 && toClean[i].isAllDescendantsChecked) {
        cleaned.push(toClean[i]);
        break;
      }

      if (toClean[i].isChecked && toClean[i].isAllDescendantsChecked) {
        cleaned.push(toClean[i]);

        const nextAtSameDepth = toClean.slice(i + 1).find(f => f.depth === toClean[i].depth);

        // There's nothing more to compute.
        if (!nextAtSameDepth) {
          break;
        }

        i = toClean.findIndex(f => f.id === nextAtSameDepth.id) - 1; // subtract 1 due to i++
      } else if (toClean[i].isChecked) {
        cleaned.push(toClean[i]);
      }
    }

    return cleaned;
  };

  /**
   * Toggles checked state for the given node.
   */
  toggleChecked(node) {
    const flatNode = this.getFlatNode(node);
    const allChildren = this._asFlatList(node);
    const isAllChildrenChecked = allChildren.every(flatNode => flatNode.isChecked);
    flatNode.isChecked = !flatNode.isChecked;

    if (flatNode.isChecked) {
      allChildren.forEach(n => {
        this.flatNodes[this.selectNodeId(n)].isChecked = true;
      });
    } else if (isAllChildrenChecked) {
      allChildren.forEach(n => {
        this.flatNodes[this.selectNodeId(n)].isChecked = false;
      });
    }

    this._updateDescendantsCheckedFlag();
  }

  /**
   * Toggles expanded state for the given node.
   */
  toggleExpanded(node, expand) {
    const id = this.selectNodeId(node);
    if (expand !== undefined) {
      this.flatNodes[id].isExpanded = expand;
    } else {
      this.flatNodes[id].isExpanded = !this.flatNodes[id].isExpanded;
    }
  }

  /**
   * Recursively iterates through all of the given nodes and it's children
   * to form a new flat array.
   */
  _flattenNodes(nodes, parent, depth = 0) {
    if (!Array.isArray(nodes) || nodes.length === 0) {
      return;
    }

    nodes.forEach((node, index) => {
      const nodeId = this.selectNodeId(node);
      const children = this.selectNodeChildren(node);
      const isParent = Array.isArray(children) && children.length > 0;
      const descendantsIds = this._descendantsIds(children);

      this.flatNodes[nodeId] = {
        children,
        depth,
        id: nodeId,
        index,
        isChild: parent !== undefined,
        isLeaf: !isParent,
        isParent,
        node,
        parent,
        label: (this.selectNodeLabel && this.selectNodeLabel(node)) || '',
        value: (this.selectNodeValue && this.selectNodeValue(node)) || nodeId,
        descendantsIds: descendantsIds,
        isAllDescendantsChecked: undefined, // populated after list is flattened.
        isSomeDescendantsChecked: undefined, // populated after list is flattened.
      };

      this._flattenNodes(children, node, depth + 1);
    });
  }

  _descendantsIds = (nodes, collector = []) => {
    if (!isArray(nodes) || nodes.length === 0) {
      return;
    }
    nodes.forEach(node => {
      collector.push(this.selectNodeId(node));
      this._descendantsIds(this.selectNodeChildren(node), collector);
    });
    return collector;
  };

  _updateDescendantsCheckedFlag = () => {
    Object.keys(this.flatNodes).forEach(key => {
      const node = this.flatNodes[key];

      // Process only when there are descendants.
      if (node.descendantsIds) {
        const descendants = node.descendantsIds || [];

        this.flatNodes[key].isAllDescendantsChecked =
          descendants.length > 0 &&
          descendants.every(c => {
            return this.flatNodes[c].isChecked;
          });

        this.flatNodes[key].isSomeDescendantsChecked =
          descendants.length > 0 &&
          !this.flatNodes[key].isAllDescendantsChecked &&
          descendants.some(c => {
            return this.flatNodes[c].isChecked;
          });
      }
    });
  };

  _asFlatList(node) {
    const flatNodes = [];

    const _flatten = children => {
      if (!Array.isArray(children) || children.length === 0) {
        return;
      }
      children.forEach(node => {
        flatNodes.push(this.flatNodes[this.selectNodeId(node)]);
        _flatten(this.selectNodeChildren(node), node);
      });
    };

    _flatten(this.selectNodeChildren(node));
    return flatNodes;
  }

  _set(value = [], expanded = []) {
    Object.keys(this.flatNodes).forEach(key => {
      this.flatNodes[key].isChecked = false;
      this.flatNodes[key].isExpanded = false;
    });
    value.forEach(obj => (this.flatNodes[this.selectNodeId(obj)].isChecked = true));
    expanded.forEach(obj => (this.flatNodes[this.selectNodeId(obj)].isExpanded = true));
  }

  _getValues(propName) {
    return Object.keys(this.flatNodes)
      .filter(key => this.flatNodes[key][propName])
      .map(key => this.flatNodes[key].node);
  }
}

export default NodeModel;
