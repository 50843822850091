import PropTypes from 'prop-types';
import React, { Fragment, useRef } from 'react';
import { Popper } from '../../../index';
import DownArrowFlexItems from '../_internal/arrow/DownArrowFlexItems';
import BaseThemeBox from '../_internal/base/BaseThemeBox';
import {
  DEFAULT_POPPER_IS_USE_PORTAL,
  DEFAULT_POPPER_OFFSET_Y,
  DEFAULT_POPPER_OVERLAY_OPACITY,
  DEFAULT_POPPER_PLACEMENT,
} from '../_internal/constants';
import findOptionIndex from '../_internal/functions/findOptionIndex';
import guardOptionId from '../_internal/functions/guardOptionId';
import includesOption from '../_internal/functions/includesOption';
import isMenuOpen from '../_internal/functions/isMenuOpen';
import validateProps from '../_internal/functions/validateProps';
import useWidth from '../_internal/hooks/useWidth';
import CheckboxOption from '../_internal/option/CheckboxOption';
import Options from '../_internal/options/Options';
import WindowedOptions from '../_internal/options/WindowedOptions';
import PlaceholderFlexItems from '../_internal/placeholder/PlaceholderFlexItems';
import SearchFlexItems from '../_internal/search/SearchFlexItems';
import SelectFlexBoxContainer from '../_internal/select/SelectFlexBoxContainer';
import SelectionFlexItems from '../_internal/selection/SelectionFlexItems';
import MultiDownshift from './internal/MultiDownshift';
import MultiSelectContainerBox from './internal/MultiSelectContainerBox';

const hasOptions = options => options && options.length > 0;

const MultiSelect = ({
  id,
  isDisabled,
  isOptionsWindowed,
  isReadOnly,
  isSearching,
  menuHeight,
  onChange,
  onRequestSearch,
  optionHeight,
  optionId,
  options,
  placeholder,
  renderOption,
  renderSelection,
  searchDebounceDelay,
  value,
  variant,
}) => {
  const referenceRef = useRef(null);
  const listRef = useRef(null);
  const width = useWidth(referenceRef);

  validateProps({
    isOptionsWindowed,
    optionHeight,
    optionId,
    options,
    renderOption,
    renderSelection,
  });

  const guardedOptionId = guardOptionId(optionId);

  const handleChange = items => {
    if (!isDisabled && !isReadOnly) {
      onChange && onChange(items);
    }

    if (referenceRef.current) {
      const textBox = referenceRef.current.querySelector('[role=textbox]');
      if (textBox) {
        textBox.focus();
      }
    }
  };

  return (
    <MultiDownshift
      inputId={id}
      initialSelectedItems={value}
      itemCount={options ? options.length : 0}
      optionId={guardedOptionId}
      onChange={handleChange}
      selectedItems={value}
    >
      {downshift => {
        //
        // downshift props.
        //
        const {
          selectedItems,
          getItemProps,
          getMenuProps,
          getRootProps,
          getRemoveButtonProps,
          highlightedIndex,
          isOpen,
          getInputProps,
          toggleMenu,
          openMenu,
        } = downshift;

        //console.log('downshift', downshift);

        const isShowMenu = isMenuOpen({ isOpen, isDisabled, isReadOnly });
        const isShowPlaceholder = !isShowMenu;
        const isShowSearch = isShowMenu && onRequestSearch;
        const isInputDisabled = isDisabled || isReadOnly;
        const isInputEnabled = !isInputDisabled;
        const isShowArrow = !isShowMenu && isInputEnabled;
        const isShowInput = !(isInputDisabled && selectedItems.length > 0);

        return (
          <MultiSelectContainerBox
            width="100%"
            {...getRootProps({
              ref: referenceRef,
              variant: variant,
              variantComponent: 'multiSelect',
              isDisabled: isInputDisabled,
              isFocused: isShowMenu,
              tabIndex: 0,
              onFocus: openMenu,
            })}
          >
            <Popper
              isOpen={isShowMenu}
              isUsePortal={DEFAULT_POPPER_IS_USE_PORTAL}
              offsetY={DEFAULT_POPPER_OFFSET_Y}
              overlayOpacity={DEFAULT_POPPER_OVERLAY_OPACITY}
              placement={DEFAULT_POPPER_PLACEMENT}
              width={width}
              renderReference={({ ref }) => (
                <Fragment>
                  {selectedItems &&
                    selectedItems.map(option => (
                      <SelectFlexBoxContainer
                        key={guardedOptionId(option)}
                        variant={variant}
                        variantComponent="multiSelection"
                        tabIndex={-1}
                        toggleMenu={toggleMenu}
                        isDisabled={isDisabled || isReadOnly}
                      >
                        <SelectionFlexItems
                          option={option}
                          inputName={id}
                          renderSelection={renderSelection}
                          isReadOnly={isReadOnly}
                          isDisabled={isDisabled}
                          optionId={guardedOptionId}
                          variant={variant}
                          getRemoveButtonProps={isInputEnabled ? getRemoveButtonProps : undefined}
                        />
                      </SelectFlexBoxContainer>
                    ))}

                  {isShowInput && (
                    <SelectFlexBoxContainer
                      variant={variant}
                      toggleMenu={toggleMenu}
                      variantComponent="multiPlaceholder"
                      isFocused={isShowMenu}
                      ref={ref}
                      isDisabled={isDisabled || isReadOnly}
                    >
                      {isShowPlaceholder && (
                        <PlaceholderFlexItems
                          variant={variant}
                          isDisabled={isDisabled || isReadOnly}
                          placeholder={placeholder}
                        />
                      )}

                      {isShowSearch && (
                        <SearchFlexItems
                          getInputProps={getInputProps}
                          isSearching={isSearching}
                          onRequestSearch={onRequestSearch}
                          searchDebounceDelay={searchDebounceDelay}
                          variant={variant}
                        />
                      )}
                      {isShowArrow && (
                        <DownArrowFlexItems
                          isDisabled={isDisabled || isReadOnly}
                          isMenuOpen={isShowMenu}
                          variant={variant}
                        />
                      )}
                    </SelectFlexBoxContainer>
                  )}
                </Fragment>
              )}
              renderPopper={() => {
                //
                // Empty options
                //
                if (!hasOptions(options)) {
                  return (
                    <BaseThemeBox variant={variant} variantComponent="emptyOptions">
                      {onRequestSearch ? 'None found' : 'No options'}
                    </BaseThemeBox>
                  );
                }

                //
                // Windowed options
                //
                if (isOptionsWindowed) {
                  const selectedIndex = selectedItems
                    ? findOptionIndex(options, selectedItems[0], optionId)
                    : undefined;

                  return (
                    <WindowedOptions
                      listRef={listRef}
                      getItemProps={getItemProps}
                      highlightedIndex={highlightedIndex}
                      menuHeight={menuHeight}
                      optionHeight={optionHeight}
                      optionId={guardedOptionId}
                      options={options}
                      selectedIndex={selectedIndex}
                      isSelected={option => includesOption(selectedItems, option, optionId)}
                      variant={variant}
                    >
                      {({ option, index }) => (
                        <CheckboxOption
                          index={index}
                          option={option}
                          renderOption={renderOption}
                          isSelected={includesOption(selectedItems, option, optionId)}
                          variant={variant}
                        />
                      )}
                    </WindowedOptions>
                  );
                }

                //
                // Non-Windowed options.
                //
                return (
                  <Options
                    getItemProps={getItemProps}
                    getMenuProps={getMenuProps}
                    highlightedIndex={highlightedIndex}
                    isOpen={isShowMenu}
                    menuHeight={menuHeight}
                    optionHeight={optionHeight}
                    optionId={guardedOptionId}
                    options={options}
                    isSelected={option => includesOption(selectedItems, option, optionId)}
                    variant={variant}
                  >
                    {({ option, index }) => (
                      <CheckboxOption
                        index={index}
                        option={option}
                        renderOption={renderOption}
                        isSelected={includesOption(selectedItems, option, optionId)}
                        variant={variant}
                      />
                    )}
                  </Options>
                );
              }}
            />
          </MultiSelectContainerBox>
        );
      }}
    </MultiDownshift>
  );
};

MultiSelect.propTypes = {
  /**
   * The id and name of the input.
   */
  id: PropTypes.string,
  /**
   * Disables select control.
   */
  isDisabled: PropTypes.bool.isRequired,
  /**
   * Sets input to readOnly.
   */
  isReadOnly: PropTypes.bool.isRequired,
  /**
   * Uses windowing to render options to improve performance on long options list.
   */
  isOptionsWindowed: PropTypes.bool,
  /**
   * Used in combination with onRequestSearch to tell the select component that
   * a search request is in progress.
   */
  isSearching: PropTypes.bool.isRequired,
  /**
   * The height of the menu options panel.
   */
  menuHeight: PropTypes.number,
  /**
   * Called when the selection changes passing a the selected option as an argument.
   */
  onChange: PropTypes.func,
  /**
   * Called when a search term changes and a search is requested. The term is used
   * to filter and update the list of options supplied to the select component.
   */
  onRequestSearch: PropTypes.func,
  /**
   * The number height of the option, required when isOptionsWindowed is truthy.
   */
  optionHeight: PropTypes.number,
  /**
   * Function that should return a string or number id of an option. The function
   * accepts object with an "item" key of the option.
   */
  optionId: PropTypes.func,
  /**
   * The array of options to select from.
   */
  options: PropTypes.array,
  /**
   * PlaceholderText text when there are no selected options.
   */
  placeholder: PropTypes.string,
  /**
   * A custom render function to render an option. The function accepts an object
   * with an "item" key of the option.
   */
  renderOption: PropTypes.func,
  /**
   * A custom render function to render a selected option. The function accepts
   * an object with an "item" key of the option.
   */
  renderSelection: PropTypes.func,
  /**
   * The delay time (ms) of debounced search requests.
   */
  searchDebounceDelay: PropTypes.number,
  /**
   * The input value array.
   */
  value: PropTypes.array,
  /**
   * The theme variant.
   */
  variant: PropTypes.string,
};

MultiSelect.defaultProps = {
  id: undefined,
  isDisabled: false,
  isOptionsWindowed: false,
  isReadOnly: false,
  isSearching: false,
  menuHeight: 240,
  onChange: undefined,
  optionHeight: undefined,
  optionId: undefined,
  options: undefined,
  onRequestSearch: undefined,
  placeholder: 'Select...',
  renderOption: undefined,
  renderSelection: undefined,
  searchDebounceDelay: 150,
  value: undefined,
  variant: 'default',
};

export default MultiSelect;
