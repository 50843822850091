import PropTypes from 'prop-types';
import React from 'react';
import BaseThemeIcon from '../../base/BaseThemeIcon';

const BallotBoxCheckIcon = ({ variant, selected }) => (
  <BaseThemeIcon
    name="BallotBoxCheck"
    variant={variant}
    variantComponent="ballotBoxCheckIcon"
    isSelected={selected}
  />
);

BallotBoxCheckIcon.propTypes = {
  selected: PropTypes.bool.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default BallotBoxCheckIcon;
