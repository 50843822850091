import { system } from 'styled-system';
import { getPxAbsolute } from '../internal';

const borderBottomLeftRadius = system({
  radius: {
    property: 'borderRadius',
    scale: 'radii',
    transform: getPxAbsolute,
  },
});

export default borderBottomLeftRadius;
