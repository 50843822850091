import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isEnterKey } from '../../../_internal/isKey';
import InputThemeAddon from './InputThemeAddon';
import InputThemeFlex from './InputThemeFlex';
import InputThemeIcon from './InputThemeIcon';
import InputThemeInput from './InputThemeInput';

const BaseInput = React.forwardRef(
  (
    {
      addonEnd,
      addonStart,
      defaultValue,
      id,
      isDisabled,
      isReadOnly,
      isShowClear,
      name,
      onBlur,
      onChange,
      onFocus,
      onRequestClear,
      placeholder,
      type,
      value,
      variant,
      width,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleOnFocus = e => {
      setIsFocused(true);
      onFocus && onFocus(e);
    };

    const handleOnBlur = e => {
      setIsFocused(false);
      onBlur && onBlur(e);
    };

    const handleOnKeyDownClear = e => {
      if (isEnterKey(e)) {
        onRequestClear && onRequestClear();
      }
    };

    const isInputDisabled = isDisabled || isReadOnly;
    const isInputEnabled = !isInputDisabled;
    const canClearInput = isInputEnabled && onRequestClear && isShowClear;

    const safeValue = value || (defaultValue === undefined ? '' : undefined);

    return (
      <InputThemeFlex
        alignItems="center"
        isDisabled={isDisabled}
        isFocused={isFocused && isInputEnabled}
        isReadOnly={isReadOnly}
        tabIndex={isInputEnabled ? -1 : undefined}
        variant={variant}
        variantComponent="root"
        width={width}
      >
        {addonStart ? addonStart : null}
        <InputThemeInput
          defaultValue={defaultValue}
          disabled={isDisabled}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          id={id}
          name={name}
          onBlur={isInputEnabled ? handleOnBlur : undefined}
          onChange={isInputEnabled ? onChange : undefined}
          onFocus={isInputEnabled ? handleOnFocus : undefined}
          placeholder={placeholder}
          readOnly={isReadOnly}
          ref={ref}
          tabIndex={isInputEnabled ? 0 : undefined}
          type={type}
          value={safeValue}
          variant={variant}
          variantComponent="input"
        />
        {canClearInput && (
          <InputThemeAddon
            onClick={onRequestClear}
            onKeyDown={handleOnKeyDownClear}
            tabIndex={0}
            variant={variant}
            variantComponent="addonEnd"
          >
            <InputThemeIcon name="XCircle" variant={variant} variantComponent="iconClear" />
          </InputThemeAddon>
        )}
        {addonEnd ? addonEnd : null}
      </InputThemeFlex>
    );
  }
);

BaseInput.displayName = 'BaseInput';

BaseInput.propTypes = {
  addonEnd: PropTypes.node,
  addonStart: PropTypes.node,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isShowClear: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onRequestClear: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

BaseInput.defaultProps = {
  isShowClear: false,
  type: 'text',
  variant: 'default',
};

export default BaseInput;
