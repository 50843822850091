import React from 'react'
import Svg from './Svg'
export const SvgTanks = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M12 0C5.372 0 0 1.679 0 3.75v3c0 2.071 5.372 3.75 12 3.75s12-1.679 12-3.75v-3C24 1.679 18.628 0 12 0z" />
    <path d="M12 12.75C5.372 12.75 0 11.071 0 9v4.5c0 2.071 5.372 3.75 12 3.75s12-1.679 12-3.75V9c0 2.071-5.372 3.75-12 3.75z" />
    <path d="M12 19.5c-6.628 0-12-1.679-12-3.75v4.5C0 22.321 5.372 24 12 24s12-1.679 12-3.75v-4.5c0 2.071-5.372 3.75-12 3.75z" />
  </Svg>
))
SvgTanks.isIcon = true
export default SvgTanks
