/**
 * Use the spacing scale when building individual components. It includes small increments needed
 * to create appropriate spatial relationships for detail-level designs.
 */
const spacings = {
  xxxsmall: 2,
  xxsmall: 4,
  xsmall: 8,
  small: 12,
  medium: 16,
  large: 24,
  xlarge: 32,
  xxlarge: 40,
  xxxlarge: 48,
};

export default () => spacings;
