import React from 'react'
import Svg from './Svg'
export const SvgIntersect = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M12 3.5c-1.542 0-2.964.38-4.266 1.142S5.401 6.433 4.64 7.735 3.5 10.46 3.5 12.001s.38 2.964 1.14 4.266 1.792 2.333 3.094 3.094 2.724 1.141 4.266 1.141 2.964-.38 4.266-1.141 2.333-1.792 3.094-3.094 1.14-2.724 1.14-4.266-.38-2.964-1.14-4.266-1.792-2.333-3.094-3.093S13.542 3.5 12 3.5zM24 12c0 2.177-.536 4.184-1.61 6.024a11.944 11.944 0 01-4.366 4.367C16.186 23.464 14.178 24 12 24s-4.184-.536-6.024-1.61a11.944 11.944 0 01-4.367-4.366C.536 16.186 0 14.178 0 12s.536-4.184 1.61-6.024a11.944 11.944 0 014.366-4.367C7.814.536 9.822 0 12 0s4.184.536 6.024 1.61a11.944 11.944 0 014.367 4.366C23.464 7.816 24 9.823 24 12z" />
    <path d="M16 12c0 1.104-.392 2.047-1.173 2.828-.781.782-1.724 1.173-2.828 1.173s-2.047-.391-2.829-1.173c-.78-.78-1.172-1.724-1.172-2.828s.391-2.047 1.172-2.828C9.952 8.39 10.895 7.999 12 7.999s2.047.391 2.828 1.173A3.85 3.85 0 0116 12z" />
  </Svg>
))
SvgIntersect.isIcon = true
export default SvgIntersect
