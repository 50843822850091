import PropTypes from 'prop-types';
import React from 'react';
import { CalendarThemeBox } from '../base';

const HeaderEmptyDate = ({ variant, message }) => (
  <CalendarThemeBox variant={variant} variantComponent="headerEmptyDate">
    {message}
  </CalendarThemeBox>
);

HeaderEmptyDate.propTypes = {
  /**
   * Empty header date message.
   */
  message: PropTypes.string.isRequired,
  /**
   * Theme variant.
   */
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default HeaderEmptyDate;
