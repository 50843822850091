import format from 'date-fns/format';
import parse from 'date-fns/parse';
import IMask from 'imask';
import useMask from './useMask';

/**
 * These patterns need to work with date-fns/format|parse. If more
 * patterns are added to the list below make sure the "blocks"
 * config section is configured to handle it.
 */
export const Patterns = {
  date: 'MM/dd/yyyy',
  dateTime: 'MM/dd/yyyy hh:mm aa',
  dateTime24hour: 'MM/dd/yyyy HH:mm',
};

const useDateMask = ({
  date,
  isPlaceholderVisible = true,
  max,
  min,
  onChange,
  onComplete,
  pattern,
}) => {
  const [inputRef, setInputRef] = useMask({
    isDisabled: false,
    isPlaceholderVisible: isPlaceholderVisible,
    mask: Date,
    max: max,
    min: min,
    onChange: onChange,
    onComplete: onComplete,
    pattern: pattern,
    placeholderChar: '_',
    typed: true,
    unmask: false,
    value: date,
    format: d => {
      return d instanceof Date ? format(d, pattern, {}) : '';
    },
    parse: str => {
      return parse(str, pattern, new Date());
    },
    blocks: {
      yyyy: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 2100,
        placeholderChar: 'y',
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        placeholderChar: 'm',
      },
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        placeholderChar: 'd',
      },
      hh: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 12,
        placeholderChar: '-',
      },
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
        placeholderChar: '-',
      },
      mm: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        placeholderChar: '-',
      },
      aa: {
        mask: IMask.MaskedEnum,
        enum: ['AM', 'PM'],
        prepare: str => str.toUpperCase(),
        placeholderChar: '-',
      },
    },
  });

  return {
    ref: inputRef,
    setRef: setInputRef,
  };
};

export default useDateMask;
