import React from 'react'
import Svg from './Svg'
export const SvgCamera = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <circle cx={12.001} cy={12.286} r={3.428} />
    <path d="M21.429 5.428h-3.161c-.16 0-.36-.103-.515-.267l-1.391-2.193a.597.597 0 00-.072-.099C15.809 2.308 15.16 2 14.464 2H9.536c-.696 0-1.345.308-1.825.869a.55.55 0 00-.073.099L6.247 5.164c-.118.13-.286.267-.461.267v-.428a.857.857 0 00-.857-.857H3.642a.857.857 0 00-.856.857v.428h-.215A2.575 2.575 0 000 8.003v10.283a2.573 2.573 0 002.571 2.571h18.858A2.573 2.573 0 0024 18.286V8a2.574 2.574 0 00-2.571-2.572zm-9.428 12.001c-3.959 0-6.434-4.286-4.455-7.715 1.98-3.428 6.929-3.428 8.908 0a5.148 5.148 0 01-4.453 7.715z" />
  </Svg>
))
SvgCamera.isIcon = true
export default SvgCamera
