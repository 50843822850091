import PropTypes from 'prop-types';
import React from 'react';
import { isEnterKey } from '../../../../../_internal/isKey';
import { BaseThemeBox } from '../../base';

const CancelButton = ({ variant, onClick }) => {
  const handleKeyDown = e => {
    if (isEnterKey(e)) {
      onClick && onClick(e);
    }
  };

  return (
    <BaseThemeBox
      onClick={onClick}
      role="button"
      tabIndex={0}
      variant={variant}
      variantComponent="cancel"
      onKeyDown={handleKeyDown}
    >
      CANCEL
    </BaseThemeBox>
  );
};

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default CancelButton;
