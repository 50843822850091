import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Z_INDEX_MODAL } from '../../_internal/constants';
import randomId from '../../_internal/utils/randomId';
import Backdrop from '../Backdrop';
import { Portal } from '../index';
import useSlideTransition from './_internal/hooks/useSlideTransition';
import ModalRoot from './_internal/ModalRoot';

const Modal = ({
  children,
  isOpen,
  onRequestClose,
  backdropOpacity,
  variant,
  modalRootProps,
  zIndex,
}) => {
  const portalId = useRef(randomId());
  const transitions = useSlideTransition({ isOpen });

  const handleOnRequestClose = () => {
    onRequestClose && onRequestClose();
  };

  // needed in order to merge with react-spring transition styles.
  const { style: modalRootStyles, ...modalRootPropsRest } = modalRootProps || {};

  return (
    <Portal id={portalId.current}>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <ModalRoot
              key={key}
              style={{ ...modalRootStyles, ...props }}
              variant={variant}
              variantComponent="root"
              zIndex={zIndex}
              {...modalRootPropsRest}
            >
              {children}
            </ModalRoot>
          )
      )}
      {isOpen && (
        <Backdrop onClick={handleOnRequestClose} opacity={backdropOpacity} zIndex={zIndex - 1} />
      )}
    </Portal>
  );
};

Modal.propTypes = {
  /**
   * Sets the opacity of the backdrop. Should be a number 0 to 1.
   */
  backdropOpacity: PropTypes.number,
  /**
   * Modal content children.
   */
  children: PropTypes.node,
  /**
   * Shows the modal.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * The props object passed directly to the modal root. The modal root
   * is rendered as a BorderBox.
   */
  modalRootProps: PropTypes.object,
  /**
   * Callback to requesting that the modal should close.
   */
  onRequestClose: PropTypes.func,
  /**
   * Theme variant.
   */
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Z-index
   */
  zIndex: PropTypes.number,
};

Modal.defaultProps = {
  backdropOpacity: 0,
  isOpen: false,
  onRequestClose: undefined,
  variant: 'default',
  modalRootProps: undefined,
  zIndex: Z_INDEX_MODAL,
};

export default Modal;
