import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isArray from 'lodash/isArray';
import IMask from 'imask';
import {
  DEFAULT_DATE_PATTERN,
  DEFAULT_DATE_TIME_24_HR_PATTERN,
  DEFAULT_DATE_TIME_PATTERN,
} from '../../../_internal/constants';
import useMask from './useMask';

/**
 * These patterns need to work with date-fns/format|parse. If more
 * patterns are added to the list below make sure the "blocks"
 * config section is configured to handle it.
 */
export const Patterns = {
  date: DEFAULT_DATE_PATTERN,
  dateTime: DEFAULT_DATE_TIME_PATTERN,
  dateTime24hour: DEFAULT_DATE_TIME_24_HR_PATTERN,
};

const DEFAULT_SEPARATOR = ' - ';

const useDateRangeMask = ({
  dateRange,
  isPlaceholderVisible = true,
  max,
  min,
  onChange,
  onComplete,
  pattern,
}) => {
  const [inputRef, setInputRef] = useMask({
    isDisabled: false,
    isPlaceholderVisible: isPlaceholderVisible,
    mask: Date,
    max: max,
    min: min,
    onChange: onChange,
    onComplete: onComplete,
    pattern: `${pattern}${DEFAULT_SEPARATOR}${pattern}`,
    placeholderChar: '_',
    typed: true,
    unmask: false,
    value: dateRange,
    format: dateRange => {
      if (dateRange && isArray(dateRange)) {
        const start = dateRange[0] instanceof Date ? format(dateRange[0], pattern, {}) : '';
        const end = dateRange[1] instanceof Date ? format(dateRange[1], pattern, {}) : '';
        return `${start}${DEFAULT_SEPARATOR}${end}`;
      }
      return '';
    },
    parse: str => {
      const parts = str.split(DEFAULT_SEPARATOR);
      const start = parse(parts[0], pattern, new Date());
      const end = parse(parts[1], pattern, new Date());
      return [start, end];
    },
    blocks: {
      yyyy: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 2100,
        placeholderChar: 'y',
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        placeholderChar: 'm',
      },
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        placeholderChar: 'd',
      },
      hh: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 12,
        placeholderChar: '-',
      },
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
        placeholderChar: '-',
      },
      mm: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        placeholderChar: '-',
      },
      aa: {
        mask: IMask.MaskedEnum,
        enum: ['AM', 'PM'],
        prepare: str => str.toUpperCase(),
        placeholderChar: '-',
      },
    },
  });

  return {
    ref: inputRef,
    setRef: setInputRef,
  };
};

export default useDateRangeMask;
