/**
 * Breakpoints.
 */
const defaults = {
  all: '0px', // (for phones - screens less than 768px wide)
  xs: '544px', // (for phones - screens less than 768px wide)
  sm: '768px', // (for tablets - screens equal to or greater than 768px wide)
  md: '992px', // (for small laptops - screens equal to or greater than 992px wide)
  lg: '1200px', // (for laptops and desktops - screens equal to or greater than 1200px wide)
  xlg: '1600px', // (for laptops and desktops - screens equal to or greater than 1200px wide)
};

const createBreakpoints = ({ breakpoints = {} } = {}) => {
  let inputArray;
  let inputObj;
  if (Array.isArray(breakpoints)) {
    // Passing an array as breakpoints will override the entire set ...
    inputObj = {};
    inputArray = breakpoints;
  } else {
    // ... while passing an object with named keys will merge them into the default set
    inputObj = { ...defaults, ...breakpoints };
    inputArray = Object.keys(inputObj).reduce((array, key) => [...array, inputObj[key]], []);
  }
  // Styled-system expects 'breakpoints' to be formatted as an array
  const output = inputArray;
  // ... Though we can still add keys to the resulting array, to reference breakpoints by name
  Object.keys(inputObj).forEach(key => {
    output[key] = inputObj[key];
  });
  return output;
};

export default createBreakpoints;
