import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex } from '../../../../Box';
import { Text } from '../../../../Typography';
import formatDate from '../functions/formatDate';

const CalendarDateHeader = ({ date, title, EmptyComponent }) => {
  const titleContent = title && (
    <Text as="div" fontSize={13} color="grey.200" mb="xxxsmall">
      {title.toUpperCase()}
    </Text>
  );

  if (!date) {
    return (
      <Box p="small">
        {titleContent}
        {EmptyComponent}
      </Box>
    );
  }

  return (
    <Box p="small">
      {titleContent}
      <Flex column gutter="small" alignItems="center">
        <Flex.Item flex={0}>
          <Text as="div" fontSize={48} color="primary.400" fontWeight={300}>
            {formatDate(date, 'd')}
          </Text>
        </Flex.Item>
        <Flex.Item flex={1}>
          <Text as="div" fontSize={14} color="grey.500" mb="xxsmall">
            {formatDate(date, 'MMMM yyyy')}
          </Text>
          <Text as="div" fontSize={13} color="grey.200">
            {formatDate(date, 'EEEE')}
          </Text>
        </Flex.Item>
      </Flex>
    </Box>
  );
};

CalendarDateHeader.propTypes = {
  /**
   * Component to display when there is no date.
   */
  EmptyComponent: PropTypes.node,
  /**
   * The date to format and display.
   */
  date: PropTypes.instanceOf(Date),
  /**
   * The title shown above the formatted date.
   */
  title: PropTypes.string,
};

CalendarDateHeader.defaultProps = {
  date: undefined,
  title: undefined,
};

export default CalendarDateHeader;
