/**
 * Returns the passed inoption.
 */
const DEFAULT_OPTION_ID = option => option;

const findOptionIndex = (options = [], option, optionId = DEFAULT_OPTION_ID) => {
  if (!option) {
    return -1;
  }
  return options.findIndex(opt => optionId(opt) === optionId(option));
};

export default findOptionIndex;
