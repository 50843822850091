import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import { Icon } from '../../../index';
import { stylesForDefaultState, stylesForHoverState } from '../../../_internal/theme/stylesFor';
import isRadio from './isRadio';

// Radio input icons
const ICON_RADIO_TRUE = 'RadioCheck';
const ICON_RADIO_FALSE = 'Radio';

// Checkbox input icons
const ICON_CHECKBOX_TRUE = 'BallotBoxCheck';
const ICON_CHECKBOX_FALSE = 'BallotBox';
const ICON_CHECKBOX_INDETERMINATE = 'BallotBoxIndeterminate';

const propList = ['isChecked', 'isDisabled', 'variantComponent'];
const shouldForwardProp = prop => !propList.includes(prop);

const StyledRadioIcon = styled(Icon, { shouldForwardProp })`
  border-radius: 50%;
  transition: all 50ms linear;
  ${stylesForDefaultState('radio')}

  &:hover {
    ${stylesForHoverState('radio')}
  }
`;

const StyledCheckboxIcon = styled(Icon, { shouldForwardProp })`
  transition: all 50ms linear;
  ${stylesForDefaultState('checkbox')}

  &:hover {
    ${stylesForHoverState('checkbox')}
  }
`;

const AnimatedIcon = ({ type, isChecked, isDisabled, isIndeterminate, variant }) => {
  const { x } = useSpring({ from: { x: 0 }, x: isChecked ? 1 : 0, config: { duration: 100 } });

  const ChoiceIcon = isRadio(type) ? StyledRadioIcon : StyledCheckboxIcon;

  let iconName;

  if (isRadio(type)) {
    iconName = isChecked ? ICON_RADIO_TRUE : ICON_RADIO_FALSE;
  } else if (isIndeterminate) {
    iconName = ICON_CHECKBOX_INDETERMINATE;
  } else {
    iconName = isChecked ? ICON_CHECKBOX_TRUE : ICON_CHECKBOX_FALSE;
  }

  return (
    <ChoiceIcon
      as={animated.svg}
      name={iconName}
      isChecked={isChecked}
      isDisabled={isDisabled}
      variant={variant}
      variantComponent={isChecked ? 'iconChecked' : 'iconNotChecked'}
      style={{
        opacity: x
          .interpolate({
            range: [0, 0.35, 0.65, 1],
            output: [1.0, 0.85, 0.9, 1.0],
          })
          .interpolate(x => x),
        transform: x
          .interpolate({
            range: isChecked ? [0, 0.35, 0.5, 0.65, 1] : [1],
            output: isChecked ? [0, 0.35, 0.5, 0.65, 1.0] : [1.0],
          })
          .interpolate(x => `scale(${x})`),
      }}
    />
  );
};

AnimatedIcon.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isIndeterminate: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default AnimatedIcon;
