import { system } from 'styled-system';
import { getColor } from '../internal';

const borderLeftColor = system({
  borderLeftColor: {
    property: 'borderLeftColor',
    scale: 'colors',
    transform: getColor,
  },
});

export default borderLeftColor;
