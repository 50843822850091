import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Box } from '../../../';
import { PlacementOptions } from './constants';

/**
 * Styled function for setting placement css toasts container positioning.
 */
const placement = props => {
  switch (props.placement) {
    case 'bottom-right':
      return {
        bottom: 16,
        right: 16,
      };
    case 'top-right':
      return {
        top: 16,
        right: 16,
      };
    case 'bottom-left':
      return {
        bottom: 16,
        left: 16,
      };
    case 'bottom-center':
      return {
        bottom: 16,
        left: '50%',
        transform: 'translateX(-50%)',
      };
    case 'top-left':
      return {
        top: 16,
        left: 16,
      };
    default:
      return {};
  }
};

const ToastsContainer = styled(Box)`
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 12px;
  }
  ${placement}
`;

ToastsContainer.propTypes = {
  placement: PropTypes.oneOf(PlacementOptions).isRequired,
};

export default ToastsContainer;
