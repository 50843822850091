import setYear from 'date-fns/setYear';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { isEnterKey } from '../../../../_internal/isKey';
import { CalendarThemeTableCell } from '../base';

const Year = ({ year, date, onClickYear, onFocusYear, variant, isDisabled, isSelected }) => {
  const ref = useRef(null);

  const handleOnClickYear = () => {
    onClickYear && onClickYear(setYear(date, year));
  };

  const handleOnFocusYear = () => {
    onFocusYear && onFocusYear(setYear(date, year));
  };

  const handleOnKeyDown = e => {
    if (isEnterKey(e)) {
      handleOnClickYear();
    }
  };

  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }, [isSelected]);

  return (
    <CalendarThemeTableCell
      data-year={year}
      isDisabled={isDisabled}
      isSelected={isSelected}
      onClick={handleOnClickYear}
      onFocus={handleOnFocusYear}
      onKeyDown={handleOnKeyDown}
      ref={ref}
      tabIndex={0}
      variant={variant}
      variantComponent="year"
      width="100%"
    >
      {year}
    </CalendarThemeTableCell>
  );
};

Year.propTypes = {
  year: PropTypes.number.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  onClickYear: PropTypes.func,
  onFocusYear: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

export default Year;
