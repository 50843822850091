import deepmerge from 'deepmerge';

const internalCreateTheme = global => ({
  defaults: {
    root: {
      margin: 0,
      padding: 0,
    },
    rootLabel: {
      borderRadius: global.control.state.normal.borderRadius,
      borderWidth: global.control.state.normal.borderWidth,
      borderColor: 'transparent',
      bg: global.control.state.normal.bg,
      hover: {
        bg: global.control.state.hover.bg,
        borderWidth: global.control.state.hover.borderWidth,
        borderColor: global.control.state.hover.borderColor,
        shadow: global.control.state.hover.shadow,
      },
      focus: {
        borderColor: global.control.state.focus.borderColor,
        borderRadius: global.control.state.focus.borderRadius,
        borderWidth: global.control.state.focus.borderWidth,
        shadow: global.control.state.focus.shadow,
      },
    },
    labelText: {
      fontSize: 14,
      color: global.control.state.normal.color,
      fontWeight: global.control.state.normal.fontWeight,
    },
    collapseButton: {
      height: 28,
      borderRadius: global.control.state.normal.borderRadius,
      borderWidth: global.control.state.normal.borderWidth,
      borderColor: 'transparent',
      paddingY: 'xxxsmall',
      paddingX: 'xxsmall',
      focus: {
        borderColor: global.control.state.focus.borderColor,
        borderRadius: global.control.state.focus.borderRadius,
        borderWidth: global.control.state.focus.borderWidth,
        shadow: global.control.state.focus.shadow,
      },
    },
    collapseIcon: {
      color: global.control.state.normal.iconColor,
      width: 16,
      height: 16,
      paddingY: 'xxxsmall',
      paddingX: 'xxxsmall',
      expanded: {
        color: global.control.state.normal.iconColor,
      },
      disabled: {
        color: global.control.state.disabled.iconColor,
      },
      hover: {
        color: global.control.state.hover.iconColor,
      },
    },
  },
  default: {},
});

const createSelect = ({ defaults }) => {
  const treeTheme = internalCreateTheme(defaults);
  return {
    default: deepmerge(treeTheme.defaults, treeTheme.default),
  };
};

export default createSelect;
