import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useImperativeHandle, useRef } from 'react';
import { Icons } from '../../../../index';
import { CalendarThemeBox } from '../base';
import NavigatorButton from './NavigatorButton';
import NavigatorDate from './NavigatorDate';

const Container = styled(CalendarThemeBox)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Left = styled('div')`
  flex: 0;
`;

const Center = styled('div')`
  flex: 1;
  text-align: center;
`;

const Right = styled('div')`
  flex: 0;
`;

const Navigator = React.forwardRef(
  (
    {
      canNavigatePrevious,
      canNavigateNext,
      date,
      dateAdderFunc,
      isDisabled,
      format,
      isHidden,
      variant,
      onClickPrevious,
      onClickDate,
      onClickNext,
      onFocusPrevious,
      onFocusNext,
      onFocusDate,
    },
    ref
  ) => {
    if (isHidden) {
      return null;
    }

    const handleClickDate = () => {
      onClickDate && onClickDate(date);
    };

    const handleClickPrevious = () => {
      onClickPrevious && dateAdderFunc && onClickPrevious(dateAdderFunc(date, -1));
    };

    const handleClickNext = () => {
      onClickNext && dateAdderFunc && onClickNext(dateAdderFunc(date, 1));
    };

    const handleKeyDownPrevious = e => {
      if (e.key === 'Enter') {
        handleClickPrevious(e);
      }
    };

    const handleKeyDownDate = e => {
      if (e.key === 'Enter') {
        handleClickDate(e);
      }
    };

    const handleKeyDownNext = e => {
      if (e.key === 'Enter') {
        handleClickNext(e);
      }
    };

    const previousRef = useRef(null);
    const dateRef = useRef(null);
    const nextRef = useRef(null);

    useImperativeHandle(ref, () => ({
      focusPrevious: () => previousRef.current && previousRef.current.focus(),
      focusDate: () => dateRef.current && dateRef.current.focus(),
      focusNext: () => nextRef.current && nextRef.current.focus(),
    }));

    const isPreviousDisabled = isDisabled || !canNavigatePrevious;
    const isNextDisabled = isDisabled || !canNavigateNext;

    return (
      <Container variant={variant} variantComponent="navigator">
        <Left>
          <NavigatorButton
            icon={Icons.ChevronLeft}
            isDisabled={isPreviousDisabled}
            onClick={handleClickPrevious}
            onFocus={onFocusPrevious}
            onKeyDown={handleKeyDownPrevious}
            ref={previousRef}
            variant={variant}
          />
        </Left>
        <Center>
          <NavigatorDate
            date={date}
            format={format}
            isDisabled={isDisabled}
            onClick={handleClickDate}
            onFocus={onFocusDate}
            onKeyDown={handleKeyDownDate}
            ref={dateRef}
            variant={variant}
          />
        </Center>
        <Right>
          <NavigatorButton
            icon={Icons.ChevronRight}
            isDisabled={isNextDisabled}
            onClick={handleClickNext}
            onFocus={onFocusNext}
            onKeyDown={handleKeyDownNext}
            ref={nextRef}
            variant={variant}
          />
        </Right>
      </Container>
    );
  }
);

Navigator.displayName = 'Navigator';

Navigator.propTypes = {
  /**
   * Can navigate to previous month.
   */
  canNavigatePrevious: PropTypes.bool,
  /**
   * Can navigate to previous month.
   */
  canNavigateNext: PropTypes.bool,
  /**
   * Date in view.
   */
  date: PropTypes.instanceOf(Date),
  /**
   * Callback when the navigator button is called.
   */
  dateAdderFunc: PropTypes.func,
  /**
   * Disables navigator.
   */
  isDisabled: PropTypes.bool,
  /**
   * Date format string.
   */
  format: PropTypes.string.isRequired,
  /**
   * Hide component?
   */
  isHidden: PropTypes.bool.isRequired,
  /**
   * Called when date is clicked.
   */
  onClickDate: PropTypes.func.isRequired,
  /**
   * Called when next button is clicked.
   */
  onClickNext: PropTypes.func.isRequired,
  /**
   * Called when previous button is clicked.
   */
  onClickPrevious: PropTypes.func,
  /**
   * Called when date is focused.
   */
  onFocusDate: PropTypes.func.isRequired,
  /**
   * Called when next button is focused.
   */
  onFocusNext: PropTypes.func.isRequired,
  /**
   * Called when previous button is focused.
   */
  onFocusPrevious: PropTypes.func.isRequired,
  /**
   * Calendar theme variant.
   */
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

Navigator.defaultProps = {
  date: undefined,
  dateAdderFunc: undefined,
  isDisabled: false,
  canNavigatePrevious: true,
  canNavigateNext: true,
};

export default Navigator;
