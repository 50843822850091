import React from 'react'
import Svg from './Svg'
export const SvgTask = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M22.365 2.182h-2.66v2.252a1.976 1.976 0 11-3.954 0V2.182H8.25v2.252a1.976 1.976 0 11-3.953 0V2.182h-2.66C.735 2.182 0 2.92 0 3.82v18.546C0 23.265.737 24 1.635 24h20.73A1.64 1.64 0 0024 22.365V3.819c0-.899-.737-1.637-1.635-1.637zm-.615 19.774H2.25V8.047h19.5v13.909z" />
      <path d="M18.683 1.842a.993.993 0 00-1.978 0V3.28c.043.509.467.91.988.91a.995.995 0 00.99-.91V1.842zM7.182 2.03a.993.993 0 00-1.977 0v1.436c.043.51.467.912.988.912a.995.995 0 00.99-.912V2.03zM19.006 9.973a1.74 1.74 0 00-2.463 0l-6.22 6.218-2.646-2.25a1.742 1.742 0 00-2.257 2.654l3.871 3.292a1.737 1.737 0 002.36-.095l7.356-7.356c.68-.68.68-1.784 0-2.463z" />
    </g>
  </Svg>
))
SvgTask.isIcon = true
export default SvgTask
