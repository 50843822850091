import { compose } from 'styled-system';
import borderBottomColor from './borderBottomColor';
import borderColor from './borderColor';
import borderLeftColor from './borderLeftColor';
import borderRightColor from './borderRightColor';
import borderTopColor from './borderTopColor';

export default compose(
  borderColor,
  borderTopColor,
  borderRightColor,
  borderBottomColor,
  borderLeftColor
);

export { borderTopColor, borderRightColor, borderBottomColor, borderLeftColor };
