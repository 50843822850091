import deepmerge from 'deepmerge';

const buildVariant = (fontFamily, fontWeight, fontSize, lineHeight, letterSpacing) => ({
  fontFamily,
  fontWeight,
  fontSize,
  lineHeight,
  letterSpacing,
});

/**
 * A set of style rules for typography.
 */
const createTypography = ({ variants = {}, fonts } = {}) => {
  const defaultTypography = {
    h1: buildVariant(fonts.sans, 300, `${96 * 0.0625}rem`, 1, -1.5),
    h2: buildVariant(fonts.sans, 300, `${60 * 0.0625}rem`, 1, -0.5),
    h3: buildVariant(fonts.sans, 400, `${48 * 0.0625}rem`, 1.04, 0),
    h4: buildVariant(fonts.sans, 400, `${34 * 0.0625}rem`, 1.17, 0.25),
    h5: buildVariant(fonts.sans, 400, `${24 * 0.0625}rem`, 1.33, 0),
    h6: buildVariant(fonts.sans, 700, `${20 * 0.0625}rem`, 1.6, 0.15),
  };

  return deepmerge(defaultTypography, variants);
};

export default createTypography;
