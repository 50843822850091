import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef } from 'react';
import calcMenuHeight from '../functions/calcMenuHeight';
import scrollToSelected from '../functions/scrollToSelected';
import OptionsContainer from './internal/OptionsContainer';
import OptionContainer from '../option/OptionContainer';

const Options = ({
  children,
  getItemProps,
  getMenuProps,
  highlightedIndex,
  menuHeight,
  optionHeight,
  optionId,
  options,
  isSelected,
  variant,
}) => {
  const ref = useRef(null);

  useLayoutEffect(() => {
    scrollToSelected(ref);
  }, []);

  return (
    <OptionsContainer
      {...getMenuProps({
        ref: ref,
        variant: variant,
        variantComponent: 'options',
        height: calcMenuHeight(options, optionHeight, menuHeight),
        width: '100%',
      })}
    >
      {options.map((option, index) => (
        <OptionContainer
          getItemProps={getItemProps}
          highlightedIndex={highlightedIndex}
          index={index}
          key={index}
          option={option}
          optionId={optionId}
          variant={variant}
          isSelected={isSelected(option)}
        >
          {children({ option, index })}
        </OptionContainer>
      ))}
    </OptionsContainer>
  );
};

Options.displayName = 'Options';

Options.propTypes = {
  children: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired,
  getMenuProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  isSelected: PropTypes.func.isRequired,
  menuHeight: PropTypes.number.isRequired,
  optionHeight: PropTypes.number,
  optionId: PropTypes.func,
  options: PropTypes.array.isRequired,
  renderOption: PropTypes.func,
  selectedItem: PropTypes.any,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

Options.defaultProps = {};

export default Options;
