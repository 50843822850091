import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import {
  isArrowDownKey,
  isArrowLeftKey,
  isArrowRightKey,
  isArrowUpKey,
  isEnterKey,
  isEscapeKey,
  isShiftTabKey,
  isSpaceKey,
  isTabKey,
} from '../../../../../_internal/isKey';
import { Checkbox } from '../../../../index';
import StyledSpan from '../styled/StyledSpan';
import RootLabel from './RootLabel';

const NodeLabel = ({
  checked,
  disabled,
  expanded,
  indeterminate,
  label,
  name,
  node,
  onCheck,
  onExpandClose,
  onExpandOpen,
  onFocusNext,
  onFocusPrevious,
  parent,
  readonly,
  renderNode,
  value,
  variant,
  variantCheckbox,
}) => {
  const rootRef = useRef(null);

  const isInputDisabled = disabled || readonly;
  const isInputEnabled = !isInputDisabled;

  const handleOnClick = e => {
    e.preventDefault();
    e.stopPropagation();
    isInputEnabled && onCheck();
  };

  const handleKeyDown = e => {
    e.preventDefault();
    e.stopPropagation();

    if (isEnterKey(e) || isSpaceKey(e)) {
      onCheck();
    } else if (isArrowUpKey(e) || isShiftTabKey(e)) {
      onFocusPrevious();
    } else if (isArrowDownKey(e) || isTabKey(e)) {
      onFocusNext();
    } else if (isArrowLeftKey(e) && expanded) {
      onExpandClose();
    } else if (isArrowLeftKey(e) && !expanded) {
      onFocusPrevious();
    } else if (isArrowRightKey(e) && !expanded && parent) {
      onExpandOpen();
    } else if (isArrowRightKey(e) && !expanded) {
      onFocusNext();
    } else if (isArrowRightKey(e)) {
      onFocusNext();
    } else if (isArrowLeftKey(e)) {
      onExpandClose();
    } else if (isEscapeKey(e)) {
      rootRef.current.blur();
    }
  };

  return (
    <RootLabel
      data-node-id={node.id}
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
      ref={rootRef}
      tabIndex={0}
      variant={variant}
      variantComponent="rootLabel"
      isDisabled={isInputDisabled}
    >
      <Checkbox
        ariaLabel={label}
        isChecked={checked}
        isDisabled={disabled}
        isReadOnly={readonly}
        isIndeterminate={indeterminate}
        name={name}
        value={value}
        variant={variantCheckbox}
      />
      <StyledSpan variant={variant} variantComponent="labelText" isDisabled={isInputDisabled}>
        {renderNode({ node: node })}
      </StyledSpan>
    </RootLabel>
  );
};

NodeLabel.propTypes = {
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  name: PropTypes.string,
  node: PropTypes.object.isRequired,
  onCheck: PropTypes.func.isRequired,
  onExpandClose: PropTypes.func.isRequired,
  onExpandOpen: PropTypes.func.isRequired,
  onFocusNext: PropTypes.func.isRequired,
  onFocusPrevious: PropTypes.func.isRequired,
  parent: PropTypes.bool.isRequired,
  renderNode: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  variantCheckbox: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default NodeLabel;
