import React from 'react'
import Svg from './Svg'
export const SvgChevronCircle = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M10.655 18.535l-.731-.73 5.853-5.855-5.853-5.854.73-.73 6.585 6.584z" />
      <path d="M11.978 23.834C5.425 23.834.094 18.503.094 11.95S5.425.066 11.978.066 23.863 5.397 23.863 11.95 18.53 23.834 11.978 23.834zm0-22.735c-5.983 0-10.85 4.868-10.85 10.851s4.867 10.851 10.85 10.851 10.851-4.867 10.851-10.85S17.962 1.098 11.98 1.098z" />
    </g>
  </Svg>
))
SvgChevronCircle.isIcon = true
export default SvgChevronCircle
