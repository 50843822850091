import deepmerge from 'deepmerge';
import defaultPalette from './palette/defaults';
import algorithm1 from './shades/algorithm1';
import algorithm2 from './shades/algorithm2';

const selectShadeFunction = selection => {
  switch (selection) {
    case 1:
      return algorithm1;
    case 2:
      return algorithm2;
    default:
      return algorithm1;
  }
};

const internalCreateColors = (palette, shadeFunctionSelection = 1) => {
  const shadeFunction = selectShadeFunction(shadeFunctionSelection);
  const newColors = {};
  Object.keys(palette).map(colorKey => {
    newColors[colorKey] = shadeFunction(palette[colorKey]);
  });
  return newColors;
};

/**
 * A function to generate the colors object.
 *
 * @returns {{}}
 */
const createColors = ({ baseColors = {} } = {}) => {
  return deepmerge(internalCreateColors(defaultPalette), internalCreateColors(baseColors) || {});
};

export default createColors;
