import { compose, get, system } from 'styled-system';
import { isNotNum, px } from '../internal';
const THEME_KEY_SPACINGS = 'spacings';
const THEME_KEY_LAYOUTS = 'layouts';

/**
 * A custom transform function that selects margin or padding values from either the "layouts" or
 * "spacings" scale. The scale selection is determined by the value of an extra component prop
 * called "layout". This allows us to break up the spacing scale into two.
 */
const getSpacingOrLayout = (n, scale, props) => {
  const targetScale = props.layout
    ? get(props.theme, THEME_KEY_LAYOUTS)
    : get(props.theme, THEME_KEY_SPACINGS);

  if (isNotNum(n)) {
    return px(get(targetScale, n, n));
  }

  const value = get(targetScale, n);
  const val = !value ? n : value;
  return px(val);
};

const configs = {
  margin: {},
  padding: {},
};
const _margin = {
  property: 'margin',
  transform: getSpacingOrLayout,
};
const marginTop = {
  property: 'marginTop',
  transform: getSpacingOrLayout,
};
const marginRight = {
  property: 'marginRight',
  transform: getSpacingOrLayout,
};
const marginBottom = {
  property: 'marginBottom',
  transform: getSpacingOrLayout,
};
const marginLeft = {
  property: 'marginLeft',
  transform: getSpacingOrLayout,
};
const marginX = {
  properties: ['marginLeft', 'marginRight'],
  transform: getSpacingOrLayout,
};
const marginY = {
  properties: ['marginTop', 'marginBottom'],
  transform: getSpacingOrLayout,
};
configs.margin.m = configs.margin.margin = _margin;
configs.margin.mt = configs.margin.marginTop = marginTop;
configs.margin.mr = configs.margin.marginRight = marginRight;
configs.margin.mb = configs.margin.marginBottom = marginBottom;
configs.margin.ml = configs.margin.marginLeft = marginLeft;
configs.margin.mx = configs.margin.marginX = marginX;
configs.margin.my = configs.margin.marginY = marginY;

const _padding = {
  property: 'padding',
  transform: getSpacingOrLayout,
};
const paddingTop = {
  property: 'paddingTop',
  transform: getSpacingOrLayout,
};
const paddingRight = {
  property: 'paddingRight',
  transform: getSpacingOrLayout,
};
const paddingBottom = {
  property: 'paddingBottom',
  transform: getSpacingOrLayout,
};
const paddingLeft = {
  property: 'paddingLeft',
  transform: getSpacingOrLayout,
};
const paddingX = {
  properties: ['paddingLeft', 'paddingRight'],
  transform: getSpacingOrLayout,
};
const paddingY = {
  properties: ['paddingTop', 'paddingBottom'],
  transform: getSpacingOrLayout,
};

configs.padding.p = configs.padding.padding = _padding;
configs.padding.pt = configs.padding.paddingTop = paddingTop;
configs.padding.pr = configs.padding.paddingRight = paddingRight;
configs.padding.pb = configs.padding.paddingBottom = paddingBottom;
configs.padding.pl = configs.padding.paddingLeft = paddingLeft;
configs.padding.px = configs.padding.paddingX = paddingX;
configs.padding.py = configs.padding.paddingY = paddingY;

export const margin = system(configs.margin);
export const padding = system(configs.padding);
export const spacing = compose(
  margin,
  padding
);
