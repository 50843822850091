import PropTypes from 'prop-types';
import React from 'react';
import { BaseThemeBox } from '../base';

const OptionContainer = ({
  getItemProps,
  highlightedIndex,
  index,
  option,
  variant,
  style,
  isSelected,
  children,
}) => {
  const itemProps = getItemProps({
    variant: variant,
    variantComponent: 'option',
    tabIndex: 0,
    item: option,
    index: index,
    //isHighlighted: highlightedIndex === index,
    isFocused: highlightedIndex === index,
    isSelected: isSelected,
    style,
  });

  return (
    <BaseThemeBox {...itemProps} data-selected={isSelected}>
      {children}
    </BaseThemeBox>
  );
};

OptionContainer.displayName = 'OptionContainer';

OptionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  option: PropTypes.any,
  index: PropTypes.number.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  getItemProps: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  highlightedIndex: PropTypes.number,
  optionId: PropTypes.func,
  style: PropTypes.object,
  isSelected: PropTypes.bool.isRequired,
};

OptionContainer.defaultProps = {
  option: undefined,
  optionId: undefined,
  highlightedIndex: undefined,
};

export default OptionContainer;
