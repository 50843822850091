import styled from '@emotion/styled';
import { Icon } from '../../../../index';
import { stylesForDefaultState, stylesForHoverState } from '../../../../_internal/theme/stylesFor';

const BaseThemeIcon = styled(Icon)`
  ${stylesForDefaultState('select')}

  &:hover {
    ${stylesForHoverState('select')}
  }
`;

export default BaseThemeIcon;
