import React from 'react'
import Svg from './Svg'
export const SvgDial = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M12 4.7c-5.3 0-9.7 4.3-9.7 9.7 0 5.3 4.3 9.7 9.7 9.7s9.7-4.3 9.7-9.7c0-5.4-4.3-9.7-9.7-9.7zm0 17.7c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8c.1 4.4-3.5 8-8 8z" />
    <path d="M8.7 12.7L12 7.1l3.3 5.6z" />
    <g>
      <circle cx={3.9} cy={4.7} r={1.5} />
      <circle cx={7.7} cy={2.5} r={1.5} />
      <circle cx={12} cy={1.5} r={1.5} />
      <circle cx={16.4} cy={2.5} r={1.5} />
      <circle cx={20.2} cy={4.7} r={1.5} />
    </g>
  </Svg>
))
SvgDial.isIcon = true
export default SvgDial
