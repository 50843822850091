import React from 'react'
import Svg from './Svg'
export const SvgHelp = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-21.844c-5.437 0-9.844 4.407-9.844 9.844 0 5.437 4.407 9.844 9.844 9.844 5.437 0 9.844-4.407 9.844-9.844 0-5.437-4.407-9.844-9.844-9.844zm1.106 11.899v1.264h-2.212v-2.213c0-.611.495-1.106 1.106-1.106a2.214 2.214 0 10-2.213-2.213c0 .406.116.78.307 1.107H7.732a4.351 4.351 0 01-.157-1.107 4.425 4.425 0 018.85 0c0 2.059-1.413 3.775-3.319 4.268zm0 4.582h-2.212v-2.212h2.212v2.212z" />
    <path d="M96 96h512v512H96z" />
  </Svg>
))
SvgHelp.isIcon = true
export default SvgHelp
