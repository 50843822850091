import React from 'react'
import Svg from './Svg'
export const SvgCompanies = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M23.1 5.961h-.745V3.726a.746.746 0 00-.745-.746h-2.236V.745a.746.746 0 00-1.491 0V2.98h-2.235a.746.746 0 00-.745.746v2.235h-.745a.746.746 0 00-.745.745v3.726h4.47a1.49 1.49 0 011.491 1.491v10.432h-1.491v-9.687a.744.744 0 00-.745-.745H8.197a.745.745 0 00-.745.745v9.687H5.961V11.923a1.49 1.49 0 011.491-1.491h2.98V3.726a.745.745 0 00-.745-.746H.745A.746.746 0 000 3.726V23.1c0 .411.334.745.745.745H23.1a.746.746 0 00.745-.745V6.706a.746.746 0 00-.745-.745zm-6.707 2.981h-1.49v-1.49h1.49v1.49zM7.452 4.471h1.49v1.49h-1.49v-1.49zm0 2.981h1.49v1.49h-1.49v-1.49zM4.471 4.471h1.49v1.49h-1.49v-1.49zM2.98 17.883H1.49v-1.49h1.49v1.49zm0-2.98H1.49v-1.49h1.49v1.49zm0-2.98H1.49v-1.491h1.49v1.491zm0-2.981H1.49v-1.49h1.49v1.49zm0-2.981H1.49v-1.49h1.49v1.49zm1.491 1.491h1.49v1.49h-1.49v-1.49zm11.922 14.903H8.942v-8.942h7.451v8.942zm2.981-13.413h-1.491v-1.49h1.491v1.49zm2.981 8.941h-1.491v-2.98h1.491v2.98zm0-4.47h-1.491v-2.981h1.491v2.981zm0-4.471h-1.491v-1.49h1.491v1.49z" />
    <path d="M10.432 14.903h1.491v1.49h-1.491v-1.49zM13.413 14.903h1.49v1.49h-1.49v-1.49zM10.432 17.883h1.491v1.491h-1.491v-1.491zM13.413 17.883h1.49v1.491h-1.49v-1.491z" />
  </Svg>
))
SvgCompanies.isIcon = true
export default SvgCompanies
