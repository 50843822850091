import setMonth from 'date-fns/setMonth';
import PropTypes from 'prop-types';
import React from 'react';
import { isEnterKey } from '../../../../_internal/isKey';
import { CalendarThemeTableCell } from '../base';

const Month = ({ month, date, onClickMonth, onFocusMonth, variant, isDisabled, isSelected }) => {
  const handleClickMonth = () => {
    onClickMonth && onClickMonth(setMonth(date, month.number));
  };

  const handleOnFocus = () => {
    onFocusMonth && onFocusMonth(setMonth(date, month.number));
  };

  const handleOnKeyDown = e => {
    if (isEnterKey(e)) {
      handleClickMonth();
    }
  };

  return (
    <CalendarThemeTableCell
      data-month={month.number}
      isDisabled={isDisabled}
      isSelected={isSelected}
      onClick={handleClickMonth}
      onFocus={handleOnFocus}
      onKeyDown={handleOnKeyDown}
      tabIndex={0}
      variant={variant}
      variantComponent="month"
      width="33.333%"
    >
      {month.short}
    </CalendarThemeTableCell>
  );
};

Month.propTypes = {
  month: PropTypes.shape({
    number: PropTypes.number.isRequired,
    letter: PropTypes.string.isRequired,
    short: PropTypes.string.isRequired,
    long: PropTypes.string.isRequired,
  }).isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  onClickMonth: PropTypes.func,
  onFocusMonth: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
};

Month.defaultProps = {
  month: undefined,
  onClickMonth: undefined,
  onFocusMonth: undefined,
};

export default Month;
