import IMask from 'imask';
import { useEffect, useRef } from 'react';

const useMask = ({
  blocks,
  format,
  isDisabled = false,
  isPlaceholderVisible,
  mask,
  max,
  min,
  onChange,
  onComplete,
  parse,
  pattern,
  placeholderChar,
  typed,
  unmask,
  value,
}) => {
  const inputRef = useRef(null);
  const imaskRef = useRef(null);

  /**
   * Return to caller to set ref on the input.
   */
  const setInputRef = node => (inputRef.current = node);

  /**
   * Mount
   */
  useEffect(() => {
    if (isDisabled) {
      return;
    }

    imaskRef.current = IMask(inputRef.current, {
      mask: mask,
    });

    return () => {
      imaskRef.current.off('accept');
      imaskRef.current.off('complete');
      imaskRef.current.destroy();
    };
  }, [isDisabled, mask]);

  /**
   * Update imask options.
   */
  useEffect(() => {
    if (isDisabled) {
      return;
    }

    if (imaskRef.current) {
      imaskRef.current.updateOptions({
        blocks: blocks,
        format: format,
        lazy: !isPlaceholderVisible,
        mask: mask,
        max: max,
        min: min,
        parse: parse,
        pattern: pattern,
        placeholderChar: placeholderChar,
      });
    }
  }, [
    blocks,
    format,
    isDisabled,
    isPlaceholderVisible,
    mask,
    max,
    min,
    parse,
    pattern,
    placeholderChar,
  ]);

  /**
   * Sets value on masked input.
   */
  useEffect(() => {
    if (isDisabled) {
      return;
    }

    if (imaskRef.current && imaskRef.current.value !== value) {
      if (typed) {
        imaskRef.current.typedValue = value;
      } else if (unmask) {
        imaskRef.current.unmaskedValue = value || '';
      } else {
        imaskRef.current.value = value || '';
      }
    }
  }, [isDisabled, value, unmask, typed]);

  /**
   * Sets up and binds onComplete and onAccept handlers to input.
   */
  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const onChangeComplete = () => {
      if (onComplete) {
        if (typed) {
          onComplete(imaskRef.current.typedValue);
        } else if (unmask) {
          onComplete(imaskRef.current.unmaskedValue);
        } else {
          onComplete(imaskRef.current.value);
        }
      }
    };

    const onChangeAccept = () => {
      onChange && onChange(unmask ? imaskRef.current.unmaskedValue : imaskRef.current.value);
    };

    imaskRef.current.on('accept', onChangeAccept);
    imaskRef.current.on('complete', onChangeComplete);

    return () => {
      imaskRef.current.off('accept');
      imaskRef.current.off('complete');
    };
  }, [isDisabled, onComplete, onChange, unmask, typed]);

  return [inputRef, setInputRef];
};

export default useMask;
