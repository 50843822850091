import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { isEnterKey, isSpaceKey } from '../../_internal/isKey';
import Background from './_internal/Background';
import Handle from './_internal/Handle';
import Input from './_internal/Input';
import Root from './_internal/Root';

const Switch = ({ id, isChecked, name, onChange, variant, value, isReadOnly, isDisabled }) => {
  const inputRef = useRef(null);

  const isControlDisabled = isReadOnly || isDisabled;
  const isControlEnabled = !isControlDisabled;

  const handleOnClickRoot = e => {
    if (e.target.checked === undefined) {
      e.stopPropagation();
      e.preventDefault();
      inputRef.current.click();
    }
  };

  const handleOnChange = () => {
    onChange && onChange(!isChecked, value);
  };

  const handleKeyDown = e => {
    if (isEnterKey(e) || isSpaceKey(e)) {
      handleOnClickRoot(e);
    }
  };

  return (
    <Root
      isDisabled={isControlDisabled}
      onClick={isControlEnabled ? handleOnClickRoot : undefined}
      onKeyDown={isControlEnabled ? handleKeyDown : undefined}
      tabIndex={0}
      variant={variant}
      variantComponent="root"
    >
      <Background
        isChecked={isChecked}
        isDisabled={isControlDisabled}
        variant={variant}
        variantComponent="background"
      >
        <Handle variant={variant} isChecked={isChecked} isDisabled={isControlDisabled} />
        <Input
          ref={inputRef}
          id={id}
          name={name}
          isChecked={isChecked}
          value={value}
          onChange={isControlEnabled ? handleOnChange : undefined}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
        />
      </Background>
    </Root>
  );
};

Switch.propTypes = {
  /**
   * The input id.
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Set the checked attribute on the checkbox input.
   */
  isChecked: PropTypes.bool,
  /**
   * Disables the switch control.
   */
  isDisabled: PropTypes.bool,
  /**
   * Set the switch control as read only.
   */
  isReadOnly: PropTypes.bool,
  /**
   * The input name.
   */
  name: PropTypes.string,
  /**
   * Sets the onChange handler on checkbox input.
   */
  onChange: PropTypes.func,
  /**
   * Sets the value on checkbox input.
   */
  value: PropTypes.any,
  /**
   * Sets the theme variant.
   */
  variant: PropTypes.string,
};

Switch.defaultProps = {
  id: undefined,
  isChecked: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  name: undefined,
  onChange: undefined,
  value: undefined,
  variant: 'medium',
};

export default Switch;
