import React from 'react'
import Svg from './Svg'
export const SvgDownload = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M11 15.8c.6.6 1.5.6 2.1 0l4.9-5c1.4-1.4-.7-3.5-2.1-2.1l-1.7 1.7c-.4.4-.7.3-.7-.3V3.4c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v6.7c0 .5-.3.7-.7.3L8.2 8.7c-1.4-1.4-3.5.7-2.1 2.1l4.9 5z" />
    <path d="M19.4 19.6c0 .3-.2.5-.5.5H5.1c-.3 0-.5-.2-.5-.5v-2.9c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5V21c0 1.1.9 2 2 2h16.7c1.1 0 2-.9 2-2v-4.4c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v3z" />
  </Svg>
))
SvgDownload.isIcon = true
export default SvgDownload
