import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../_internal/theme/stylesFor';

const CheckboxRoot = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  border-color: transparent;

  ${stylesForDefaultState('checkbox')}

  &:hover {
    ${stylesForHoverState('checkbox')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('checkbox')}
  }
`;

CheckboxRoot.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CheckboxRoot;
