import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { animated, config, useTransition } from 'react-spring';
import randomId from '../../_internal/utils/randomId';
import { Portal } from '../../Util';
import { PlacementOptions } from './_internal/constants';
import Toast from './_internal/Toast';
import ToastsContainer from './_internal/ToastContainer';

const ToastsContext = createContext({});

/**
 * Defines a css `transform` for a specific placement and spring
 * lifecycle (from, enter, leave, etc.). See its usage in useTransition
 * below.
 */
const cssTransform = (lifecycle, placement) => {
  switch (placement) {
    case 'top-right':
      switch (lifecycle) {
        case 'from':
          return 'translateX(200%) scale(1)';
        case 'enter':
          return 'translateX(0%) scale(1)';
        case 'leave':
          return 'translateX(0%) scale(0.8)';
        default:
          return {};
      }

    case 'top-left':
      switch (lifecycle) {
        case 'from':
          return 'translateX(-200%) scale(1)';
        case 'enter':
          return 'translateX(0%) scale(1)';
        case 'leave':
          return 'translateX(0%) scale(0.8)';
        default:
          return {};
      }

    case 'bottom-right':
      switch (lifecycle) {
        case 'from':
          return 'translateX(200%) scale(1)';
        case 'enter':
          return 'translateX(0%) scale(1)';
        case 'leave':
          return 'translateX(0%) scale(0.8)';
        default:
          return {};
      }

    case 'bottom-left':
      switch (lifecycle) {
        case 'from':
          return 'translateX(-200%) scale(1)';
        case 'enter':
          return 'translateX(0%) scale(1)';
        case 'leave':
          return 'translateX(0%) scale(0.8)';
        default:
          return {};
      }

    case 'bottom-center':
      switch (lifecycle) {
        case 'from':
          return 'translateY(200%) scale(1)';
        case 'enter':
          return 'translateY(0%) scale(1)';
        case 'leave':
          return 'translateY(0%) scale(0.8)';
        default:
          return {};
      }
  }
};

const ToastProvider = ({
  children,
  placement,
  autoDismiss: autoDismissDefault,
  autoDismissTimeout,
}) => {
  const [portalId] = useState(randomId());
  const [toasts, setToasts] = useState([]);
  const [cancelMap] = useState(() => new WeakMap());
  const containerRef = useRef(null);
  const transitions = useTransition(toasts, toast => toast.id, {
    from: {
      transform: cssTransform('from', placement),
      opacity: 0,
    },
    enter: {
      transform: cssTransform('enter', placement),
      opacity: 1,
    },
    leave: item => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({
        transform: cssTransform('leave', placement),
        opacity: 0,
      });
    },
    config: (toast, lifecycle) => {
      if (lifecycle === 'leave') {
        return { duration: 350 };
      }
      return config.stiff;
    },
  });

  /**
   * Adds a toast to internal toasts state.
   */
  const pushToast = toast => setToasts([...toasts, toast]);

  /**
   * Removes the toast from internal toasts array state.
   */
  const remove = useCallback(
    toast => {
      const found = toasts.findIndex(t => t.id === toast.id);
      if (found !== -1) {
        cancelMap.has(toast) && cancelMap.get(toast)();
        setToasts(toasts.filter(i => i.id !== toast.id));
      }
    },
    [toasts, cancelMap]
  );

  /**
   * Dispatches "dismiss-toast" event.
   * @param id the toast id.
   */
  const dispatchDismissToastEvent = id => {
    containerRef.current.dispatchEvent(new CustomEvent('dismiss-toast', { detail: id }));
  };

  /**
   * Adds a listener for 'dismiss-toast' events.
   */
  useEffect(() => {
    const toastsContainer = containerRef.current;

    const removeById = e => {
      const toast = toasts.find(t => t.id === e.detail);
      if (toast) {
        remove(toast);
      }
    };

    toastsContainer.addEventListener('dismiss-toast', removeById);

    return () => {
      toastsContainer.removeEventListener('dismiss-toast', removeById);
    };
  }, [remove, toasts]);

  /**
   * Constructs a toast function for a specific variant. The "toastConfig"
   * parameter can be function or a config object.
   */
  const toast = variant => toastConfig => {
    const id = randomId();

    if (isFunction(toastConfig)) {
      /**
       * Call the toastConfig function and spread the returned config options.
       */
      const { message, autoDismiss = autoDismissDefault, addonStart, iconName } = toastConfig({
        dismiss: () => dispatchDismissToastEvent(id),
      });

      return pushToast({
        addonStart,
        autoDismiss,
        iconName,
        id,
        message,
        variant: variant,
      });
    }

    const { message, autoDismiss = autoDismissDefault, addonStart, iconName } = toastConfig;

    return pushToast({
      addonStart,
      autoDismiss,
      iconName,
      id,
      message,
      variant: variant,
    });
  };

  /**
   * Toast variants.
   */
  const success = useCallback(toast('success'), [pushToast]);
  const info = useCallback(toast('info'), [pushToast]);
  const warning = useCallback(toast('warning'), [pushToast]);
  const danger = useCallback(toast('danger'), [pushToast]);

  const contextValue = useMemo(() => {
    return { success, info, warning, danger };
  }, [success, info, warning, danger]);

  return (
    <ToastsContext.Provider value={contextValue}>
      <Portal id={portalId}>
        <ToastsContainer placement={placement} ref={containerRef}>
          {transitions.map(({ item: toast, props, key }) => {
            return (
              <animated.div
                key={key}
                style={{
                  width: '100%',
                  ...props,
                }}
              >
                <Toast toast={toast} onDismiss={remove} autoDismissTimeout={autoDismissTimeout} />
              </animated.div>
            );
          })}
        </ToastsContainer>
      </Portal>
      {children}
    </ToastsContext.Provider>
  );
};

ToastProvider.propTypes = {
  /**
   * Children, most likely the rest of your app.
   */
  children: PropTypes.node,
  /**
   * Should toasts autoDismiss after timeout.
   */
  autoDismiss: PropTypes.bool.isRequired,
  /**
   * The number of milliseconds before auto dismissing toasts.
   */
  autoDismissTimeout: PropTypes.number.isRequired,
  /**
   * Where to place the toast notifications.
   */
  placement: PropTypes.oneOf(PlacementOptions).isRequired,
};

ToastProvider.defaultProps = {
  children: null,
  autoDismiss: true,
  autoDismissTimeout: 5500,
  placement: 'top-right',
};

export { ToastProvider, ToastsContext };
