import tinycolor from 'tinycolor2';

const multiply = (rgb1, rgb2) => {
  rgb1.b = Math.floor((rgb1.b * rgb2.b) / 255);
  rgb1.g = Math.floor((rgb1.g * rgb2.g) / 255);
  rgb1.r = Math.floor((rgb1.r * rgb2.r) / 255);
  return tinycolor(`rgb ${rgb1.r} ${rgb1.g} ${rgb1.b}`);
};

export default multiply;
