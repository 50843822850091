import tinycolor from 'tinycolor2';
import multiply from './internal/multiply';
import asColorObject from './internal/asColorObject';

const algorithm2 = hex => {
  const baseLight = tinycolor('#ffffff');
  const baseDark = multiply(tinycolor(hex).toRgb(), tinycolor(hex).toRgb());
  const baseTriad = tinycolor(hex).tetrad();

  return {
    50: asColorObject(tinycolor.mix(baseLight, hex, 12), '50'),
    100: asColorObject(tinycolor.mix(baseLight, hex, 30), '100'),
    200: asColorObject(tinycolor.mix(baseLight, hex, 50), '200'),
    300: asColorObject(tinycolor.mix(baseLight, hex, 70), '300'),
    400: asColorObject(tinycolor.mix(baseLight, hex, 85), '400'),
    500: asColorObject(tinycolor.mix(baseLight, hex, 100), '500'),
    600: asColorObject(tinycolor.mix(baseDark, hex, 87), '600'),
    700: asColorObject(tinycolor.mix(baseDark, hex, 70), '700'),
    800: asColorObject(tinycolor.mix(baseDark, hex, 54), '800'),
    900: asColorObject(tinycolor.mix(baseDark, hex, 25), '900'),
    A100: asColorObject(
      tinycolor
        .mix(baseDark, baseTriad[4], 15)
        .saturate(80)
        .lighten(65),
      'A100'
    ),
    A200: asColorObject(
      tinycolor
        .mix(baseDark, baseTriad[4], 15)
        .saturate(80)
        .lighten(55),
      'A200'
    ),
    A400: asColorObject(
      tinycolor
        .mix(baseDark, baseTriad[4], 15)
        .saturate(100)
        .lighten(45),
      'A400'
    ),
    A700: asColorObject(
      tinycolor
        .mix(baseDark, baseTriad[4], 15)
        .saturate(100)
        .lighten(40),
      'A700'
    ),
  };
};

export default algorithm2;
