import React from 'react'
import Svg from './Svg'
export const SvgHorizontalDots = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M5.957 10.647c-1.024 0-1.82.796-1.82 1.82.113.91.91 1.707 1.82 1.707s1.821-.796 1.821-1.82c0-.91-.796-1.707-1.82-1.707zM11.989 10.647c-1.024 0-1.82.796-1.82 1.82 0 1.025.796 1.821 1.82 1.821s1.82-.796 1.82-1.82c-.113-1.025-.91-1.821-1.82-1.821zM18.02 10.647c-1.024 0-1.82.796-1.82 1.82 0 1.025.796 1.821 1.82 1.821 1.025 0 1.821-.796 1.821-1.82-.114-1.025-.91-1.821-1.82-1.821z" />
    </g>
  </Svg>
))
SvgHorizontalDots.isIcon = true
export default SvgHorizontalDots
