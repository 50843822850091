import Downshift from 'downshift';
import remove from 'lodash/remove';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import includesOption from '../../_internal/functions/includesOption';
import isSameOption from '../../_internal/functions/isSameOption';

const MultiDownshift = ({
  children,
  selectedItems,
  initialSelectedItems,
  optionId,
  onChange,
  ...rest
}) => {
  const [internalSelectedItems, setInternalSelectedItems] = useState(initialSelectedItems);

  useEffect(() => {
    setInternalSelectedItems(selectedItems);
  }, [selectedItems]);

  const removeItem = item => {
    remove(internalSelectedItems, i => isSameOption(i, item, optionId));
    const newItems = [...initialSelectedItems];
    setInternalSelectedItems(newItems);
    onChange(newItems);
  };

  const addItem = item => {
    const newItems = initialSelectedItems ? [...initialSelectedItems, item] : [item];
    setInternalSelectedItems(newItems);
    onChange(newItems);
  };

  const onStateChange = ({ selectedItem }) => {
    if (selectedItem) {
      if (includesOption(internalSelectedItems, selectedItem, optionId)) {
        removeItem(selectedItem);
      } else {
        addItem(selectedItem);
      }
    }
  };

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true,
          inputValue: '',
        };
      default:
        return changes;
    }
  };

  const getRemoveButtonProps = ({ onClick, item, ...props } = {}) => {
    if (item === undefined || item === null) {
      throw new Error('getRemoveButtonProps requires that you pass an "item" prop.');
    }
    return {
      onClick: e => {
        onClick && onClick(e);
        removeItem(item);
      },
      ...props,
    };
  };

  const getStateAndHelpers = downshift => {
    return {
      getRemoveButtonProps,
      selectedItems: internalSelectedItems,
      ...downshift,
    };
  };

  return (
    <Downshift
      {...rest}
      onStateChange={onStateChange}
      stateReducer={stateReducer}
      selectedItem={null}
      itemToString={optionId}
    >
      {downshift => children(getStateAndHelpers(downshift))}
    </Downshift>
  );
};

MultiDownshift.propTypes = {
  /**
   * Called when selection changes.
   */
  initialSelectedItems: PropTypes.array,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  render: PropTypes.func,
  children: PropTypes.func,
};

MultiDownshift.defaultProps = {
  initialSelectedItems: [],
  onSelect: undefined,
  onChange: undefined,
};

export default MultiDownshift;
