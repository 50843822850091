import styled from '@emotion/styled';
import { Box } from '../../../../Box';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../_internal/theme/stylesFor';

const MultiSelectContainerBox = styled(Box)`
  position: relative;
  ${stylesForDefaultState('select')}

  &:hover {
    ${stylesForHoverState('select')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('select')}
  }

  & > div:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
`;

export default MultiSelectContainerBox;
