import styled from '@emotion/styled';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../../_internal/theme/stylesFor';

const RootLabel = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: background-color 150ms ease, border 150ms ease;
  ${stylesForDefaultState('tree')}

  &:hover {
    cursor: pointer;
    ${stylesForHoverState('tree')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('tree')};
  }
`;

export default RootLabel;
