import { system } from 'styled-system';
import { getColor } from '../internal';

const borderRightColor = system({
  borderRightColor: {
    property: 'borderRightColor',
    scale: 'colors',
    transform: getColor,
  },
});

export default borderRightColor;
