import { useContext } from 'react';
import { ToastsContext } from './Toasts';

const useToasts = () => {
  const { info, success, warning, danger } = useContext(ToastsContext);

  return {
    info,
    success,
    warning,
    danger,
  };
};

export default useToasts;
