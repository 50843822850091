import { system } from 'styled-system';
import { getPxAbsolute } from '../internal';

const borderTopWidth = system({
  borderTopWidth: {
    property: 'borderTopWidth',
    transform: getPxAbsolute,
  },
});

export default borderTopWidth;
