import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '../../../../Box';
import PlaceholderText from './internal/PlaceholderText';

const PlaceholderFlexItems = ({ placeholder, variant, isDisabled }) => {
  return (
    <Flex.Item flex={1}>
      <PlaceholderText variant={variant} isDisabled={isDisabled}>
        {placeholder}
      </PlaceholderText>
    </Flex.Item>
  );
};

PlaceholderFlexItems.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default PlaceholderFlexItems;
