/**
 * Returns the passed inoption.
 */
const DEFAULT_OPTION_ID = option => option;

/**
 * Returns true when the option can be found in the options array.
 *
 * @param options the array of options to check.
 * @param option the option.
 * @param optionId custom option id selector.
 * @returns {boolean} true when the option can be found in the options array.
 */
const includesOption = (options = [], option, optionId = DEFAULT_OPTION_ID) => {
  if (!options || !option) {
    return false;
  }
  return options.findIndex(o => optionId(o) === optionId(option)) !== -1;
};

export default includesOption;
