import { compose } from 'styled-system';
import borderBottomStyle from './borderBottomStyle';
import borderLeftStyle from './borderLeftStyle';
import borderRightStyle from './borderRightStyle';
import borderTopStyle from './borderTopStyle';
import borderStyle from './borderStyle';

export default compose(
  borderStyle,
  borderTopStyle,
  borderRightStyle,
  borderBottomStyle,
  borderLeftStyle
);

export { borderTopStyle, borderRightStyle, borderBottomStyle, borderLeftStyle };
