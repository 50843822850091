import isBefore from 'date-fns/isBefore';

export default class DateRange {
  constructor(start, end) {
    this.start = start;
    this.end = end;
  }

  isValidRange() {
    return this.start && this.end && isBefore(this.start, this.end);
  }
}
