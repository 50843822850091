import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { BorderBox } from '../../../';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../_internal/theme/stylesFor';

const StyledTabPanel = styled(BorderBox)`
  ${stylesForDefaultState('tabs')}

  &:hover {
    ${stylesForHoverState('tabs')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('tabs')}
  }
`;

const TabPanel = ({ children, variant, ...rest }) => {
  return (
    <StyledTabPanel variant={variant} variantComponent="panel" {...rest}>
      {children}
    </StyledTabPanel>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

export default TabPanel;
