import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Flex } from '../../../../Box';
import { BaseThemeInput } from '../base';
import CancelButton from './internal/CancelButton';
import SearchIcon from './internal/SearchIcon';
import SpinnerIcon from './internal/SpinnerIcon';

const SearchFlexItems = ({
  getInputProps,
  isSearching,
  onRequestSearch,
  searchDebounceDelay,
  variant,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef(null);

  const debounceRequestSearch = useCallback(
    debounce(term => {
      onRequestSearch && onRequestSearch(term);
    }, searchDebounceDelay),
    [onRequestSearch]
  );

  const handleTermChange = e => {
    const v = e.target.value;
    setSearchTerm(v);
    debounceRequestSearch(v);
  };

  const handleCancel = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    onRequestSearch && onRequestSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Flex.Item flex={0}>
        <SearchIcon variant={variant} />
      </Flex.Item>
      <Flex.Item flex={1}>
        <BaseThemeInput
          role="textbox"
          {...getInputProps({
            onChange: handleTermChange,
            ref: inputRef,
            value: searchTerm,
            variant: variant,
            variantComponent: 'searchInput',
            placeholder: 'Search...',
          })}
        />
      </Flex.Item>
      {isSearching && (
        <Flex.Item flex={0}>
          <SpinnerIcon variant={variant} />
        </Flex.Item>
      )}
      <Flex.Item flex={0}>
        <CancelButton variant={variant} onClick={handleCancel}>
          CANCEL
        </CancelButton>
      </Flex.Item>
    </Fragment>
  );
};

SearchFlexItems.displayName = 'OptionsFilter';

SearchFlexItems.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  onRequestSearch: PropTypes.func,
  searchDebounceDelay: PropTypes.number.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default SearchFlexItems;
