export { default as Applications } from './Applications';
export { default as ArrowDown } from './ArrowDown';
export { default as ArrowRight } from './ArrowRight';
export { default as BallotBox } from './BallotBox';
export { default as BallotBoxCheck } from './BallotBoxCheck';
export { default as BallotBoxIndeterminate } from './BallotBoxIndeterminate';
export { default as Bell } from './Bell';
export { default as Calendar } from './Calendar';
export { default as Camera } from './Camera';
export { default as CameraPlus } from './CameraPlus';
export { default as ChatBubble } from './ChatBubble';
export { default as Check } from './Check';
export { default as CheckmarkCircle } from './CheckmarkCircle';
export { default as ChevronCircle } from './ChevronCircle';
export { default as ChevronDown } from './ChevronDown';
export { default as ChevronLeft } from './ChevronLeft';
export { default as ChevronLeftDouble } from './ChevronLeftDouble';
export { default as ChevronRight } from './ChevronRight';
export { default as ChevronRightDouble } from './ChevronRightDouble';
export { default as ChevronUp } from './ChevronUp';
export { default as Clipboard } from './Clipboard';
export { default as Clock } from './Clock';
export { default as Cloud } from './Cloud';
export { default as Columns } from './Columns';
export { default as Comment } from './Comment';
export { default as CommentPlus } from './CommentPlus';
export { default as Companies } from './Companies';
export { default as Complaints } from './Complaints';
export { default as Contacts } from './Contacts';
export { default as Copy } from './Copy';
export { default as Dashboard } from './Dashboard';
export { default as Dial } from './Dial';
export { default as Documents } from './Documents';
export { default as Dollar } from './Dollar';
export { default as Download } from './Download';
export { default as EnforcementActions } from './EnforcementActions';
export { default as Enter } from './Enter';
export { default as Exit } from './Exit';
export { default as Eye } from './Eye';
export { default as EyeWithLine } from './EyeWithLine';
export { default as Facilities } from './Facilities';
export { default as File } from './File';
export { default as FileUpload } from './FileUpload';
export { default as Form } from './Form';
export { default as FormRequired } from './FormRequired';
export { default as Gear } from './Gear';
export { default as Grid } from './Grid';
export { default as Help } from './Help';
export { default as Hibiscus } from './Hibiscus';
export { default as History } from './History';
export { default as Home } from './Home';
export { default as HorizontalDots } from './HorizontalDots';
export { default as HorizontalDotsCircle } from './HorizontalDotsCircle';
export { default as Inbox } from './Inbox';
export { default as Incidents } from './Incidents';
export { default as Info } from './Info';
export { default as Inspections } from './Inspections';
export { default as Intersect } from './Intersect';
export { default as Investigations } from './Investigations';
export { default as Layers } from './Layers';
export { default as LoadingBubbles } from './LoadingBubbles';
export { default as Location } from './Location';
export { default as Lock } from './Lock';
export { default as MagnifyingGlass } from './MagnifyingGlass';
export { default as Marker } from './Marker';
export { default as MeasureArea } from './MeasureArea';
export { default as MeasureLine } from './MeasureLine';
export { default as Menu } from './Menu';
export { default as Minus } from './Minus';
export { default as NavCaret } from './NavCaret';
export { default as Note } from './Note';
export { default as NoteAdd } from './NoteAdd';
export { default as Notification } from './Notification';
export { default as Pencil } from './Pencil';
export { default as Permits } from './Permits';
export { default as Plus } from './Plus';
export { default as PlusCircle } from './PlusCircle';
export { default as Print } from './Print';
export { default as QuestionMarkCircle } from './QuestionMarkCircle';
export { default as Radio } from './Radio';
export { default as RadioCheck } from './RadioCheck';
export { default as Receipt } from './Receipt';
export { default as Reports } from './Reports';
export { default as RequiredStar } from './RequiredStar';
export { default as Search } from './Search';
export { default as Settings } from './Settings';
export { default as Sharing } from './Sharing';
export { default as SortArrow } from './SortArrow';
export { default as SortAsc } from './SortAsc';
export { default as SortDesc } from './SortDesc';
export { default as Tanks } from './Tanks';
export { default as Task } from './Task';
export { default as Trash } from './Trash';
export { default as Unlock } from './Unlock';
export { default as User } from './User';
export { default as ValidationError } from './ValidationError';
export { default as VerticalDots } from './VerticalDots';
export { default as Viewer } from './Viewer';
export { default as Warning } from './Warning';
export { default as Wells } from './Wells';
export { default as Windows } from './Windows';
export { default as Wrench } from './Wrench';
export { default as WriteDoc } from './WriteDoc';
export { default as X } from './X';
export { default as XCircle } from './XCircle';

  export const Icons = {
Applications: 'Applications',
ArrowDown: 'ArrowDown',
ArrowRight: 'ArrowRight',
BallotBox: 'BallotBox',
BallotBoxCheck: 'BallotBoxCheck',
BallotBoxIndeterminate: 'BallotBoxIndeterminate',
Bell: 'Bell',
Calendar: 'Calendar',
Camera: 'Camera',
CameraPlus: 'CameraPlus',
ChatBubble: 'ChatBubble',
Check: 'Check',
CheckmarkCircle: 'CheckmarkCircle',
ChevronCircle: 'ChevronCircle',
ChevronDown: 'ChevronDown',
ChevronLeft: 'ChevronLeft',
ChevronLeftDouble: 'ChevronLeftDouble',
ChevronRight: 'ChevronRight',
ChevronRightDouble: 'ChevronRightDouble',
ChevronUp: 'ChevronUp',
Clipboard: 'Clipboard',
Clock: 'Clock',
Cloud: 'Cloud',
Columns: 'Columns',
Comment: 'Comment',
CommentPlus: 'CommentPlus',
Companies: 'Companies',
Complaints: 'Complaints',
Contacts: 'Contacts',
Copy: 'Copy',
Dashboard: 'Dashboard',
Dial: 'Dial',
Documents: 'Documents',
Dollar: 'Dollar',
Download: 'Download',
EnforcementActions: 'EnforcementActions',
Enter: 'Enter',
Exit: 'Exit',
Eye: 'Eye',
EyeWithLine: 'EyeWithLine',
Facilities: 'Facilities',
File: 'File',
FileUpload: 'FileUpload',
Form: 'Form',
FormRequired: 'FormRequired',
Gear: 'Gear',
Grid: 'Grid',
Help: 'Help',
Hibiscus: 'Hibiscus',
History: 'History',
Home: 'Home',
HorizontalDots: 'HorizontalDots',
HorizontalDotsCircle: 'HorizontalDotsCircle',
Inbox: 'Inbox',
Incidents: 'Incidents',
Info: 'Info',
Inspections: 'Inspections',
Intersect: 'Intersect',
Investigations: 'Investigations',
Layers: 'Layers',
LoadingBubbles: 'LoadingBubbles',
Location: 'Location',
Lock: 'Lock',
MagnifyingGlass: 'MagnifyingGlass',
Marker: 'Marker',
MeasureArea: 'MeasureArea',
MeasureLine: 'MeasureLine',
Menu: 'Menu',
Minus: 'Minus',
NavCaret: 'NavCaret',
Note: 'Note',
NoteAdd: 'NoteAdd',
Notification: 'Notification',
Pencil: 'Pencil',
Permits: 'Permits',
Plus: 'Plus',
PlusCircle: 'PlusCircle',
Print: 'Print',
QuestionMarkCircle: 'QuestionMarkCircle',
Radio: 'Radio',
RadioCheck: 'RadioCheck',
Receipt: 'Receipt',
Reports: 'Reports',
RequiredStar: 'RequiredStar',
Search: 'Search',
Settings: 'Settings',
Sharing: 'Sharing',
SortArrow: 'SortArrow',
SortAsc: 'SortAsc',
SortDesc: 'SortDesc',
Tanks: 'Tanks',
Task: 'Task',
Trash: 'Trash',
Unlock: 'Unlock',
User: 'User',
ValidationError: 'ValidationError',
VerticalDots: 'VerticalDots',
Viewer: 'Viewer',
Warning: 'Warning',
Wells: 'Wells',
Windows: 'Windows',
Wrench: 'Wrench',
WriteDoc: 'WriteDoc',
X: 'X',
XCircle: 'XCircle',
};

