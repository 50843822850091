import styled from '@emotion/styled';
import isInteger from 'lodash/isInteger';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '../../';

const Root = styled('span')`
  position: relative;
  display: inline-block;
`;

const placement = ({ placement }) => {
  switch (placement) {
    case 'top-right':
      return {
        transform: 'translateX(-9px)',
        left: '100%',
        top: '-10px',
      };
    case 'top-left':
      return {
        transform: 'translateX(-9px)',
        right: '0%',
        top: '-10px',
      };
    case 'bottom-right':
      return {
        transform: 'translateX(-9px)',
        left: '100%',
        bottom: '-6px',
      };
    case 'bottom-left':
      return {
        transform: 'translateX(-9px)',
        right: '0%',
        bottom: '-6px',
      };
    default:
      return {};
  }
};

const circular = { borderRadius: '50%', height: '18px', width: '18px' };
const notCircular = { borderRadius: '999px' };

// styles for controlling the radius of the badge.
const radius = ({ label }) => {
  if (isString(label) && label.length === 1) {
    return circular;
  }
  if (isInteger(label) && label <= 9 && label >= 0) {
    return circular;
  }
  return notCircular;
};

const Label = styled(Box)`
  display: inline-block;
  font-size: 12px;
  box-sizing: border-box;
  height: 18px;
  line-height: 18px;
  min-width: 18px;
  overflow: hidden;
  padding: 0 6px 0 6px;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  width: auto;
  z-index: 1;
  ${radius}
  ${placement}
`;

const Badge = React.forwardRef(({ children, label, bg, color, placement, ...rest }, ref) => {
  return (
    <Root>
      {children}
      <Label ref={ref} bg={bg} color={color} placement={placement} label={label} {...rest}>
        {label}
      </Label>
    </Root>
  );
});

Badge.displayName = 'Badge';

Badge.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bg: PropTypes.string,
  color: PropTypes.string,
  placement: PropTypes.oneOf(['top-right', 'top-left', 'bottom-right', 'bottom-left']),
};

Badge.defaultProps = {
  children: null,
  color: 'white',
  placement: 'bottom-right',
  bg: 'primary.600',
};

export default Badge;
