import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../_internal/theme/stylesFor';
import { Flex } from '../../../';

const StyledTabsRoot = styled(Flex)`
  ${stylesForDefaultState('tabs')}

  &:hover {
    ${stylesForHoverState('tabs')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('tabs')}
  }
`;

const TabsRoot = ({ children, variant, ...rest }) => {
  return (
    <StyledTabsRoot variant={variant} variantComponent="tabs" {...rest}>
      {children}
    </StyledTabsRoot>
  );
};

TabsRoot.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default TabsRoot;
