import styled from "@emotion/styled";
import {
  alignContent,
  alignItems,
  alignSelf,
  flex,
  justifyContent,
  order,
  px
} from "@modernary/ui-kit-styled-system";
import PropTypes from "prop-types";
import React from "react";
import BorderBox from "../BorderBox";

const row = props => (props.row ? { flexDirection: "column" } : null);
const column = props => (props.column ? { flexDirection: "row" } : null);
const wrap = props => (props.wrap ? { flexWrap: "wrap" } : null);
const inline = props => (props.inline ? { display: "inline-flex" } : null);

const gutter = props => {
  if (!props.gutter) {
    return;
  }

  const column = `& > *:not(:last-child) {
      margin-right: ${px(props.theme.spacings[props.gutter] || props.gutter)}
      }`;

  const row = `& > *:not(:last-child) {
      margin-bottom: ${px(props.theme.spacings[props.gutter] || props.gutter)}
      }`;

  if (props.column) {
    return column;
  } else {
    return row;
  }
};

const StyledFlex = styled(BorderBox)`
  display: flex;
  ${row}
  ${column}
  ${wrap}
  ${inline}
  ${alignItems}
  ${justifyContent}
  ${alignContent}
  ${gutter}
`;

const Flex = React.forwardRef((props, ref) => <StyledFlex ref={ref} {...props} />);

Flex.Item = styled(BorderBox)`
  ${order}
  ${flex}
  ${alignSelf}
`;

Flex.displayName = "Flex";

Flex.propTypes = {
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...alignContent.propTypes,
  row: PropTypes.bool,
  column: PropTypes.bool,
  inline: PropTypes.bool,
  wrap: PropTypes.bool,
  theme: PropTypes.object
};

Flex.defaultProps = {
  row: undefined,
  column: undefined,
  inline: undefined,
  wrap: undefined
};

Flex.Item.displayName = "Flex.Item";

Flex.Item.propTypes = {
  ...order.propTypes,
  ...flex.propTypes,
  ...alignSelf.propTypes,
  theme: PropTypes.object
};

export default Flex;
