import React from 'react'
import Svg from './Svg'
export const SvgPrint = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M6 2h12v3H6V2zM22.5 6.5h-21C.675 6.5 0 7.175 0 8v7.5c0 .825.675 1.5 1.5 1.5H6v6h12v-6h4.5c.825 0 1.5-.675 1.5-1.5V8c0-.825-.675-1.5-1.5-1.5zM3 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm13.5 10.5h-9V14h9v7.5z" />
    </g>
  </Svg>
))
SvgPrint.isIcon = true
export default SvgPrint
