import styled from '@emotion/styled';
import { Box } from '../../../../Box';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../_internal/theme/stylesFor';

const propList = ['variantComponent'];
const shouldForwardProp = prop => !propList.includes(prop);

const ToastsThemeBox = styled(Box, { shouldForwardProp })`
  ${stylesForDefaultState('toasts')}

  &:hover {
    ${stylesForHoverState('toasts')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('toasts')}
  }
`;

export default ToastsThemeBox;
