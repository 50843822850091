import React from 'react'
import Svg from './Svg'
export const SvgCopy = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M3.6 16.7V2.6h6.9v3.1H12V3.4l2.5 2.4h1.7c.2 0 .4 0 .6.1l-.3-.3-4.2-4c-.2-.4-.6-.6-1.1-.6H3.6C2.7 1 2 1.7 2 2.6v14.1c0 .9.7 1.6 1.6 1.6H6v-1.6H3.6z" />
    <path d="M17.3 7.2c-.1-.2-.3-.3-.5-.3-.2-.1-.4-.1-.6-.1H8.6C7.7 6.7 7 7.5 7 8.3v14.1c0 .3.1.6.3.9.2.4.7.7 1.3.7h11.9c.6 0 1-.3 1.3-.7.2-.3.3-.6.3-.9v-10c0-.4-.2-.9-.5-1.2l-4.3-4zm2.3 4.4H17V9.1l2.6 2.5zm.8 10.8H8.6V8.3h6.9v4c0 .4.4.8.8.8h4.2v9.3z" />
  </Svg>
))
SvgCopy.isIcon = true
export default SvgCopy
