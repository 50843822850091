const validateProps = ({
  isOptionsWindowed,
  optionHeight,
  optionId,
  options = [],
  renderOption,
  renderSelection,
}) => {
  if (options && options.length > 0) {
    if (typeof options[0] === 'object' && !optionId) {
      throw new Error('You need to provide the "optionId" prop when options are objects');
    }

    if (typeof options[0] === 'object' && !renderOption) {
      throw new Error('You need to provide the "renderOption" prop when options are objects');
    }

    if (typeof options[0] === 'object' && !renderSelection) {
      throw new Error('You need to provide the "renderSelection" prop when options are objects');
    }
  }

  if (isOptionsWindowed) {
    if (!optionHeight) {
      throw new Error('You must set the `optionHeight` prop when `isOptionsWindowed` is set.');
    }
  }
};

export default validateProps;
