import styled from "@emotion/styled";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
  textAlign,
  textStyle,
  themeGet,
  variant
} from "@modernary/ui-kit-styled-system";
import PropTypes from "prop-types";

// Text variants are defined in the theme under the 'typography' key.
const variants = props =>
  variant({
    prop: "variant",
    variants: {
      ...themeGet(`typography`)(props)
    }
  });

const Text = styled("div")`
  ${spacing}
  ${textStyle}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${color}
  ${variants}
`;

Text.displayName = "Typography";

Text.propTypes = {
  /**
   * Typography variants are defined in the theme under the 'typography' key.
   */
  variant: PropTypes.string,
  /**
   * The theme.
   */
  theme: PropTypes.object,
  ...textStyle.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...spacing.propTypes,
  ...color.propTypes
};

export default Text;
