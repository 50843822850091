import { system } from 'styled-system';
import { getPxAbsolute } from '../internal';

const borderWidth = system({
  borderWidth: {
    property: 'borderWidth',
    transform: getPxAbsolute,
  },
});

export default borderWidth;
