import PropTypes from 'prop-types';
import React from 'react';
import { isEnterKey } from '../../../_internal/isKey';
import InputThemeAddon from '../../_internal/base/InputThemeAddon';
import InputThemeIcon from '../../_internal/base/InputThemeIcon';
import DatePicker from './DatePicker';

const DatePickerAddon = ({ isDateRange, isVisible, value, onClose, onChange, variant }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <DatePicker isDateRange={isDateRange} value={value} onChange={onChange} onAfterClose={onClose}>
      {({ ref, open }) => (
        <InputThemeAddon
          variant={variant}
          variantComponent="addonEnd"
          ref={ref}
          onClick={e => {
            e.stopPropagation();
            open(e);
          }}
          tabIndex={0}
          onKeyDown={e => isEnterKey(e) && open(e)}
        >
          <InputThemeIcon variant={variant} variantComponent="iconCalendar" name="Calendar" />
        </InputThemeAddon>
      )}
    </DatePicker>
  );
};

DatePickerAddon.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isDateRange: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.arrayOf(Date)]),
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

DatePickerAddon.defaultProps = {
  isDateRange: false,
};

export default DatePickerAddon;
