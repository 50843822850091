import useMask from './useMask';

const useCurrencyMask = ({ allowNegative = true, max, min, onComplete, onChange, value }) => {
  const [inputRef, setInputRef] = useMask({
    mask: 'number',
    blocks: {
      number: {
        mask: Number,
        signed: allowNegative,
        thousandsSeparator: ',',
        radix: '.',
        padFractionalZeros: false,
        normalizeZeros: false,
        min: min,
        max: max,
      },
    },

    onComplete: onComplete,
    onChange: onChange,
    value: value,
  });

  return {
    ref: inputRef,
    setRef: setInputRef,
  };
};

export default useCurrencyMask;
