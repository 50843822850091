import styled from '@emotion/styled';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
  isArrowDownKey,
  isArrowUpKey,
  isKeyNext,
  isKeyPrevious,
} from '../../../../_internal/isKey';
import { Box } from '../../../../Box';
import getMonthlyCalendar from '../functions/getMonthlyCalendar';
import Navigator from '../Navigator';
import PickDate from '../PickDate';
import PickMonth from '../PickMonth';
import PickYear from '../PickYear';

const Body = styled(Box)`
  overflow-y: auto;
  flex: 1;
`;

const getNavigatorFormat = pickerInView => {
  switch (pickerInView) {
    case CalendarWithNavigator.SelectMode.Day:
      return 'MMMM yyyy';
    case CalendarWithNavigator.SelectMode.Month:
      return 'yyyy';
    default:
      return 'MMMM yyyy';
  }
};

const getDateAdderFunc = pickerInView => {
  switch (pickerInView) {
    case CalendarWithNavigator.SelectMode.Day:
      return addMonths;
    case CalendarWithNavigator.SelectMode.Month:
      return addYears;
    default:
      return undefined;
  }
};

const CalendarWithNavigator = ({
  calendarDate,
  canNavigateNext,
  canNavigatePrevious,
  canSelectMonth,
  canSelectYear,
  isDisabled,
  onChangeCalendarDate,
  onChangeSelected,
  onChangeSelectMode,
  onKeyDown,
  selected,
  selectMode,
  variant,
}) => {
  const [internalSelectMode, setInternalSelectMode] = useState(selectMode);
  const [navigatorPartFocused, setNavigatorPartFocused] = useState(null);

  useEffect(() => {
    setInternalSelectMode(selectMode);
  }, [selectMode]);

  const calendar = getMonthlyCalendar({ date: calendarDate });

  const navigatorRef = useRef(null);
  const pickDayRef = useRef(null);
  const pickMonthRef = useRef(null);
  const pickYearRef = useRef(null);

  const changePicker = mode => {
    setInternalSelectMode(mode);
    onChangeSelectMode && onChangeSelectMode(mode);
  };

  const handleOnNavigatorDirection = date => {
    onChangeCalendarDate && onChangeCalendarDate(date);
  };

  const handleOnPickDate = date => {
    onChangeCalendarDate && onChangeCalendarDate(date);
    onChangeSelected && onChangeSelected(date);
  };

  const handleOnPickMonth = date => {
    onChangeCalendarDate && onChangeCalendarDate(date);
    changePicker(CalendarWithNavigator.SelectMode.Day);
  };

  const handleOnPickYear = date => {
    onChangeCalendarDate && onChangeCalendarDate(date);
    changePicker(CalendarWithNavigator.SelectMode.Month);
  };

  const handleOnFocusMonth = () => {
    setNavigatorPartFocused(null);
  };

  const handleOnClickNavigatorDate = () => {
    switch (internalSelectMode) {
      case CalendarWithNavigator.SelectMode.Day:
        changePicker(CalendarWithNavigator.SelectMode.Month);
        break;
      case CalendarWithNavigator.SelectMode.Month:
        changePicker(CalendarWithNavigator.SelectMode.Year);
        break;
      case CalendarWithNavigator.SelectMode.Year:
        changePicker(CalendarWithNavigator.SelectMode.Day);
        break;
      default:
        break;
    }
  };

  const handleOnFocusNavigator = part => {
    setNavigatorPartFocused(part);
  };

  const handleOnFocusNavigatorPrevious = () => handleOnFocusNavigator('P');

  const handleOnFocusNavigatorDate = () => handleOnFocusNavigator('D');

  const handleOnFocusNavigatorNext = () => handleOnFocusNavigator('N');

  const handleOnFocusDate = () => {
    setNavigatorPartFocused(null);
  };

  const handleOnFocusYear = () => {
    setNavigatorPartFocused(null);
  };

  const handleOnKeydown = e => {
    e.preventDefault();

    const isNavigatorPartDate = () => navigatorPartFocused === 'D';
    const isNavigatorPartNext = () => navigatorPartFocused === 'N';
    const isNavigatorPartPrev = () => navigatorPartFocused === 'P';
    const isNavigatorPart = () => navigatorPartFocused !== null;
    const isDayPickerInView = () => internalSelectMode === CalendarWithNavigator.SelectMode.Day;
    const isMonthPickerInView = () => internalSelectMode === CalendarWithNavigator.SelectMode.Month;
    const isYearPickerInView = () => internalSelectMode === CalendarWithNavigator.SelectMode.Year;

    if (isDisabled) {
      return;
    }

    onKeyDown && onKeyDown(e);

    if (!e.isPropagationStopped()) {
      if (isNavigatorPartPrev() && isKeyNext(e)) {
        navigatorRef.current.focusDate();
      } else if (isNavigatorPartDate() && isKeyNext(e)) {
        navigatorRef.current.focusNext();
      } else if (isNavigatorPartDate() && isKeyPrevious(e)) {
        navigatorRef.current.focusPrevious();
      } else if (isNavigatorPartNext() && isKeyPrevious(e)) {
        navigatorRef.current.focusDate();
      } else if (isNavigatorPart() && (isArrowDownKey(e) || isKeyNext(e))) {
        if (isDayPickerInView()) {
          pickDayRef.current.focusInitial();
        } else if (isYearPickerInView()) {
          pickYearRef.current.focusInitial();
        } else if (isMonthPickerInView()) {
          pickMonthRef.current.focusInitial();
        }
      } else if (isDayPickerInView() && isArrowUpKey(e)) {
        if (!pickDayRef.current.focusUp()) {
          navigatorRef.current.focusDate();
        }
      } else if (isDayPickerInView() && isArrowDownKey(e)) {
        pickDayRef.current.focusDown();
      } else if (isDayPickerInView() && isKeyPrevious(e)) {
        if (!pickDayRef.current.focusLeft()) {
          navigatorRef.current.focusNext();
        }
      } else if (isDayPickerInView() && isKeyNext(e)) {
        pickDayRef.current.focusRight();
      } else if (isMonthPickerInView() && isArrowUpKey(e)) {
        if (!pickMonthRef.current.focusUp()) {
          navigatorRef.current.focusDate();
        }
      } else if (isMonthPickerInView() && isArrowDownKey(e)) {
        pickMonthRef.current.focusDown();
      } else if (isMonthPickerInView() && isKeyPrevious(e)) {
        if (!pickMonthRef.current.focusLeft()) {
          pickMonthRef.current.focusUp();
        }
      } else if (isMonthPickerInView() && isKeyNext(e)) {
        pickMonthRef.current.focusRight();
      } else if (isYearPickerInView() && isArrowDownKey(e)) {
        pickYearRef.current.focusNext();
      } else if (isYearPickerInView() && isArrowUpKey(e)) {
        pickYearRef.current.focusPrevious();
      }
    }
  };

  return (
    <Body onKeyDown={handleOnKeydown} tabIndex={0}>
      <Navigator
        date={calendarDate}
        dateAdderFunc={getDateAdderFunc(internalSelectMode)}
        isDisabled={isDisabled}
        format={getNavigatorFormat(internalSelectMode)}
        isHidden={internalSelectMode === CalendarWithNavigator.SelectMode.Year}
        onClickDate={handleOnClickNavigatorDate}
        onClickNext={handleOnNavigatorDirection}
        onClickPrevious={handleOnNavigatorDirection}
        onFocusDate={handleOnFocusNavigatorDate}
        onFocusNext={handleOnFocusNavigatorNext}
        onFocusPrevious={handleOnFocusNavigatorPrevious}
        canNavigatePrevious={canNavigatePrevious}
        canNavigateNext={canNavigateNext}
        ref={navigatorRef}
        variant={variant}
      />
      <Box m="xsmall">
        {
          {
            [CalendarWithNavigator.SelectMode.Day]: (
              <PickDate
                calendar={calendar}
                date={calendarDate}
                isDisabled={isDisabled}
                onFocusDate={handleOnFocusDate}
                onPickDate={handleOnPickDate}
                ref={pickDayRef}
                selected={selected}
                variant={variant}
              />
            ),
            [CalendarWithNavigator.SelectMode.Month]: (
              <PickMonth
                date={calendarDate}
                isDisabled={isDisabled}
                onFocusMonth={handleOnFocusMonth}
                onPickMonth={handleOnPickMonth}
                ref={pickMonthRef}
                variant={variant}
                canSelectMonth={canSelectMonth}
              />
            ),
            [CalendarWithNavigator.SelectMode.Year]: (
              <PickYear
                date={calendarDate}
                isDisabled={isDisabled}
                onFocusYear={handleOnFocusYear}
                onPickYear={handleOnPickYear}
                ref={pickYearRef}
                variant={variant}
                canSelectYear={canSelectYear}
              />
            ),
          }[internalSelectMode]
        }
      </Box>
    </Body>
  );
};

CalendarWithNavigator.SelectMode = {
  Day: 'Day',
  Month: 'Month',
  Year: 'Year',
};

CalendarWithNavigator.propTypes = {
  calendarDate: PropTypes.instanceOf(Date),
  variant: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  canNavigateNext: PropTypes.bool.isRequired,
  canNavigatePrevious: PropTypes.bool.isRequired,
  canSelectMonth: PropTypes.func.isRequired,
  canSelectYear: PropTypes.func.isRequired,
};

CalendarWithNavigator.defaultProps = {
  calendarDate: undefined,
  variant: 'default',
  selectMode: CalendarWithNavigator.SelectMode.Day,
  isDisabled: false,
  canNavigateNext: true,
  canNavigatePrevious: true,
  canSelectMonth: () => true,
  canSelectYear: () => true,
};

export default CalendarWithNavigator;
