import { system } from 'styled-system';
import { getPxAbsolute } from '../internal';

const borderRightWidth = system({
  borderRightWidth: {
    property: 'borderRightWidth',
    transform: getPxAbsolute,
  },
});

export default borderRightWidth;
