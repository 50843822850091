import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import CalendarThemeBox from './CalendarThemeBox';

const CalendarThemeTable = styled(CalendarThemeBox)`
  display: table;
  width: 100%;
  border-collapse: ${props => (props.isCollapsed ? 'collapse' : 'separate')};
`;

CalendarThemeTable.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
};

CalendarThemeTable.defaultProps = {
  isCollapsed: true,
};

export default CalendarThemeTable;
