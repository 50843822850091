import upperFirst from 'lodash/upperFirst';
import React from 'react';
import * as icons from './index';

const Icon = React.forwardRef(({ name, ...props }, ref) => {
  const Component = icons[name] || icons[upperFirst(name)];
  if (!Component) {
    return false;
  }
  return <Component {...props} ref={ref} />;
});

Icon.defaultProps = {
  size: '1.25em',
};

Icon.displayName = 'Icon';

Icon.propTypes = {
  name: (props, key, componentName) => {
    const name = props[key];
    if (!icons[name] && !icons[upperFirst(name)]) {
      return new Error(`Unknown name prop \`${name}\` supplied to \`${componentName}\``);
    } else if (!icons[name]) {
      return new Error(
        `Icon name prop should be uppercase.\n` + `Use \`${upperFirst(name)}\` instead of ${name}.`
      );
    }
  },
};

Icon.Icons = icons.Icons;

export default Icon;
