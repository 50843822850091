import styled from '@emotion/styled';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Relative } from '../../../Box';
import { Popper } from '../../../index';
import DownArrowFlexItems from '../_internal/arrow/DownArrowFlexItems';
import BaseThemeBox from '../_internal/base/BaseThemeBox';
import {
  DEFAULT_POPPER_IS_USE_PORTAL,
  DEFAULT_POPPER_OFFSET_Y,
  DEFAULT_POPPER_OVERLAY_OPACITY,
  DEFAULT_POPPER_PLACEMENT,
} from '../_internal/constants';
import findOptionIndex from '../_internal/functions/findOptionIndex';
import guardOptionId from '../_internal/functions/guardOptionId';
import isMenuOpen from '../_internal/functions/isMenuOpen';
import isSameOption from '../_internal/functions/isSameOption';
import validateProps from '../_internal/functions/validateProps';
import useWidth from '../_internal/hooks/useWidth';
import OptionPlainText from '../_internal/option/OptionPlainText';
import Options from '../_internal/options/Options';
import WindowedOptions from '../_internal/options/WindowedOptions';
import PlaceholderFlexItems from '../_internal/placeholder/PlaceholderFlexItems';
import SearchFlexItems from '../_internal/search/SearchFlexItems';
import SelectFlexBoxContainer from '../_internal/select/SelectFlexBoxContainer';
import SelectionFlexItems from '../_internal/selection/SelectionFlexItems';

const HiddenLabel = styled('label')`
  display: block;
  width: 0px;
  height: 0px;
  overflow: hidden;
`;

const hasOptions = options => options && options.length > 0;

const SingleSelect = ({
  allowClear,
  id,
  isDisabled,
  isOptionsWindowed,
  isReadOnly,
  isSearching,
  menuHeight,
  onChange,
  onRequestSearch,
  optionHeight,
  optionId,
  options,
  placeholder,
  renderOption,
  renderSelection,
  searchDebounceDelay,
  value,
  variant,
}) => {
  const referenceRef = useRef(null);
  const listRef = useRef(null);
  const width = useWidth(referenceRef);

  validateProps({
    isOptionsWindowed,
    optionHeight,
    optionId,
    options,
    renderOption,
    renderSelection,
  });

  const guardedOptionId = guardOptionId(optionId);

  const handleChange = (selectedItem, { setHighlightedIndex }) => {
    if (!isDisabled && !isReadOnly) {
      onChange && onChange(selectedItem);
      // when a selection occurs set the highlighted index, on next
      // menu open the option next to the last selection will be
      // highLighted.
      if (selectedItem) {
        setHighlightedIndex(findOptionIndex(options, selectedItem));
      }
    }

    if (referenceRef.current) {
      const textbox = referenceRef.current.querySelector('[role=textbox]');
      if (textbox) {
        textbox.focus();
      }
    }
  };

  const handleStateChange = changes => {
    if (changes.highlightedIndex) {
      if (listRef.current !== null && listRef.current.scrollToItem) {
        listRef.current.scrollToItem(changes.highlightedIndex);
      }
    }
  };

  const stateReducer = (state, changes) => {
    //console.log(changes);
    return changes;
  };

  return (
    <Downshift
      initialSelectedItem={value}
      initialHighlightedIndex={value ? findOptionIndex(options, value, optionId) : 0}
      inputId={id}
      itemCount={options ? options.length : 0}
      itemToString={guardedOptionId}
      onChange={handleChange}
      onStateChange={handleStateChange}
      stateReducer={stateReducer}
      selectedItem={value}
    >
      {downshift => {
        //
        // downshift props.
        //
        const {
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getRootProps,
          highlightedIndex,
          isOpen,
          selectedItem,
          toggleMenu,
        } = downshift;

        const isShowMenu = isMenuOpen({ isOpen, isDisabled, isReadOnly });
        const isShowPlaceholder = selectedItem === null && !isShowMenu;
        const isShowSelection = selectedItem !== null && !isShowMenu;
        const isShowSearch = isShowMenu && onRequestSearch;
        const isShowArrow = !isShowMenu && !isDisabled && !isReadOnly;
        const getRemoveButtonProps = obj => ({
          onClick: clearSelection,
          ...obj,
        });

        return (
          <Relative
            width="100%"
            {...getRootProps({
              ref: referenceRef,
              tabIndex: -1,
            })}
          >
            <HiddenLabel {...getLabelProps()}>A hidden label</HiddenLabel>
            <Popper
              isOpen={isShowMenu}
              isUsePortal={DEFAULT_POPPER_IS_USE_PORTAL}
              offsetY={DEFAULT_POPPER_OFFSET_Y}
              overlayOpacity={DEFAULT_POPPER_OVERLAY_OPACITY}
              placement={DEFAULT_POPPER_PLACEMENT}
              width={width}
              renderReference={({ ref }) => (
                <SelectFlexBoxContainer
                  variant={variant}
                  variantComponent="single"
                  toggleMenu={toggleMenu}
                  ref={ref}
                  isDisabled={isDisabled || isReadOnly}
                  isFocused={isShowMenu}
                  tabIndex={0}
                  onFocus={!isOpen ? toggleMenu : undefined}
                >
                  {isShowPlaceholder && (
                    <PlaceholderFlexItems
                      variant={variant}
                      isDisabled={isDisabled || isReadOnly}
                      placeholder={placeholder}
                    />
                  )}
                  {isShowSelection && (
                    <SelectionFlexItems
                      allowClear={allowClear}
                      option={selectedItem}
                      inputName={id}
                      renderSelection={renderSelection}
                      isReadOnly={isReadOnly}
                      isDisabled={isDisabled}
                      optionId={guardedOptionId}
                      variant={variant}
                      getRemoveButtonProps={getRemoveButtonProps}
                    />
                  )}
                  {isShowSearch && (
                    <SearchFlexItems
                      getInputProps={getInputProps}
                      isSearching={isSearching}
                      onRequestSearch={onRequestSearch}
                      searchDebounceDelay={searchDebounceDelay}
                      variant={variant}
                    />
                  )}
                  {isShowArrow && (
                    <DownArrowFlexItems
                      isDisabled={isDisabled || isReadOnly}
                      isMenuOpen={isShowMenu}
                      variant={variant}
                    />
                  )}
                </SelectFlexBoxContainer>
              )}
              renderPopper={() => {
                //
                // Empty options
                //
                if (!hasOptions(options)) {
                  return (
                    <BaseThemeBox variant={variant} variantComponent="emptyOptions">
                      {onRequestSearch ? 'None found' : 'No options'}
                    </BaseThemeBox>
                  );
                }

                //
                // Windowed options
                //
                if (isOptionsWindowed) {
                  if (!optionHeight) {
                    throw new Error(
                      'You must set the `optionHeight` prop when `isOptionsWindowed` is set'
                    );
                  }

                  return (
                    <WindowedOptions
                      listRef={listRef}
                      getItemProps={getItemProps}
                      highlightedIndex={highlightedIndex}
                      menuHeight={menuHeight}
                      optionHeight={optionHeight}
                      optionId={guardedOptionId}
                      options={options}
                      selectedIndex={findOptionIndex(options, selectedItem, optionId)}
                      isSelected={option => isSameOption(selectedItem, option, optionId)}
                      variant={variant}
                    >
                      {({ option, index }) => (
                        <OptionPlainText
                          renderOption={renderOption}
                          option={option}
                          index={index}
                        />
                      )}
                    </WindowedOptions>
                  );
                }

                return (
                  <Options
                    getItemProps={getItemProps}
                    getMenuProps={getMenuProps}
                    highlightedIndex={highlightedIndex}
                    isOpen={isShowMenu}
                    menuHeight={menuHeight}
                    optionHeight={optionHeight}
                    optionId={guardedOptionId}
                    options={options}
                    isSelected={option => isSameOption(selectedItem, option, optionId)}
                    variant={variant}
                  >
                    {({ option, index }) => (
                      <OptionPlainText renderOption={renderOption} option={option} index={index} />
                    )}
                  </Options>
                );
              }}
            />
          </Relative>
        );
      }}
    </Downshift>
  );
};

SingleSelect.propTypes = {
  /**
   * Allow user to clear selection.
   */
  allowClear: PropTypes.bool.isRequired,
  /**
   * The id and name of the input.
   */
  id: PropTypes.string,
  /**
   * Disables select control.
   */
  isDisabled: PropTypes.bool.isRequired,
  /**
   * Sets input to readOnly.
   */
  isReadOnly: PropTypes.bool.isRequired,
  /**
   * Uses windowing to render options to improve performance on long options list.
   */
  isOptionsWindowed: PropTypes.bool,
  /**
   * Used in combination with onRequestSearch to tell the select component that
   * a search request is in progress.
   */
  isSearching: PropTypes.bool.isRequired,
  /**
   * The height of the menu options panel.
   */
  menuHeight: PropTypes.number,
  /**
   * Called when the selection changes passing a the selected option as an argument.
   */
  onChange: PropTypes.func,
  /**
   * Called when a search term changes and a search is requested. The term is used
   * to filter and update the list of options supplied to the select component.
   */
  onRequestSearch: PropTypes.func,
  /**
   * The number height of the option, required when isOptionsWindowed is truthy.
   */
  optionHeight: PropTypes.number,
  /**
   * Function that should return a string or number id of an option. The function
   * accepts object with an "item" key of the option.
   */
  optionId: PropTypes.func,
  /**
   * The array of options to select from.
   */
  options: PropTypes.array,
  /**
   * PlaceholderText text when there are no selected options.
   */
  placeholder: PropTypes.string,
  /**
   * A custom render function to render an option. The function accepts an object
   * with an "item" key of the option.
   */
  renderOption: PropTypes.func,
  /**
   * A custom render function to render a selected option. The function accepts
   * an object with an "item" key of the option.
   */
  renderSelection: PropTypes.func,
  /**
   * The delay time (ms) of debounced search requests.
   */
  searchDebounceDelay: PropTypes.number,
  /**
   * The input value.
   */
  value: PropTypes.any,
  /**
   * The theme variant.
   */
  variant: PropTypes.string,
};

SingleSelect.defaultProps = {
  allowClear: true,
  id: undefined,
  isDisabled: false,
  isOptionsWindowed: false,
  isReadOnly: false,
  isSearching: false,
  menuHeight: 240,
  onChange: undefined,
  onRequestSearch: undefined,
  optionHeight: undefined,
  optionId: undefined,
  options: undefined,
  placeholder: 'Select...',
  renderOption: undefined,
  renderSelection: undefined,
  searchDebounceDelay: 150,
  value: undefined,
  variant: 'default',
};

export default SingleSelect;
