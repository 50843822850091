/**
 * TODO move to theme  (theme.zIndexes.tooltip)
 */
export const Z_INDEX = 1000;

/**
 * TODO move to theme  (theme.tooltips.default, theme.tooltips.success, ?, etc.)
 */
export const DEFAULT_BG_COLOR = '#232323';
export const DEFAULT_COLOR = 'white';
