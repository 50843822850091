import get from 'lodash/get';
import getColor from './getColor';

const colorsScale = props => get(props, 'theme.colors');

const getThemeColor = (path, fallback = null) => props => {
  if (!props || !props.theme) {
    if (fallback) {
      return fallback;
    }
    return getColor(path, colorsScale(props));
  }

  const c = get(props, `'theme.colors.${path}`);

  if (!c && fallback) {
    return fallback;
  }
  return getColor(path, colorsScale(props));
};

export default getThemeColor;
