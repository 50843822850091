/**
 * calculates the height of the options menu. Does not set an explicit
 * height when there are 5 or less items.
 *
 * @param options the menu options.
 * @param optionHeight height of each option.
 * @param defaultHeight default height
 * @returns {undefined|number|*}
 */
const calcMenuHeight = (options = [], optionHeight, defaultHeight) => {
  if (options.length <= 5 && optionHeight) {
    return options.length * optionHeight;
  } else if (options.length <= 5) {
    return undefined;
  }
  return defaultHeight;
};

export default calcMenuHeight;
