/**
 * Global styles for controls.
 */
const globalDefaults = {
  palette: {
    text: {
      base: 'grey',
      light: 'grey.100',
      dark: 'grey.800',
      active: 'grey.600',
      normal: 'grey.500',
      muted: 'grey.200',
    },
    primary: {
      base: 'blue',
      light: 'blue.100',
      dark: 'blue.800',
      active: 'blue.600',
      normal: 'blue.500',
      muted: 'blue.200',
    },
    secondary: {
      base: 'amber',
      light: 'amber.100',
      dark: 'amber.800',
      active: 'amber.600',
      normal: 'amber.500',
      muted: 'amber.200',
    },
    neutral: {
      base: 'blueGrey',
      light: 'blueGrey.100',
      dark: 'blueGrey.800',
      active: 'blueGrey.600',
      normal: 'blueGrey.500',
      muted: 'blueGrey.200',
    },
    warning: {
      base: 'yellow',
      light: 'yellow.100',
      dark: 'yellow.800',
      active: 'yellow.600',
      normal: 'yellow.500',
      muted: 'yellow.200',
    },
    danger: {
      base: 'red',
      light: 'red.100',
      dark: 'red.800',
      active: 'red.600',
      normal: 'red.500',
      muted: 'red.200',
    },
    success: {
      success: 'green',
      light: 'green.100',
      dark: 'green.800',
      active: 'green.600',
      normal: 'green.500',
      muted: 'green.200',
    },
  },
  control: {
    dimensions: {
      xsmall: { height: 28, fontSize: 11 },
      small: { height: 34, fontSize: 12 },
      medium: { height: 40, fontSize: 14 },
      large: { height: 46, fontSize: 16 },
      xlarge: { height: 52, fontSize: 18 },
    },
    state: {
      normal: {
        bg: 'white',
        color: '#111',
        iconColor: 'primary.700',
        iconColorMuted: 'grey.400',
        borderColor: 'grey.100',
        borderRadius: 'xxsmall',
        borderWidth: 1,
        shadow: undefined,
        fontStyle: 'normal',
        fontWeight: 400,
      },
      placeholder: {
        color: 'grey.300',
        iconColor: 'grey.200',
        fontStyle: 'italic',
        fontWeight: 400,
      },
      focus: {
        color: '#111',
        bg: 'white',
        borderColor: 'primary.200',
        borderWidth: 1,
        fontStyle: undefined,
        fontWeight: 400,
        iconColor: 'primary.600',
        shadow: '0px 0px 5px primary.100',
      },
      hover: {
        color: '#111',
        bg: 'primary.200',
        borderColor: 'primary.100',
        iconColor: 'primary.800',
        borderWidth: 1,
        shadow: undefined,
        fontStyle: undefined,
        fontWeight: 400,
      },
      disabled: {
        color: 'grey.400',
        bg: 'blueGrey.50',
        iconColor: 'blueGrey.200',
        borderColor: 'blueGrey.100',
        borderWidth: 1,
        shadow: undefined,
        fontStyle: 'italic',
        fontWeight: 400,
      },
      selected: {
        color: '#111',
        bg: 'primary.100',
        bgDark: 'primary.700',
        borderColor: 'primary.700',
        borderWidth: 1,
        shadow: undefined,
        fontStyle: undefined,
        fontWeight: 400,
      },
      selected_disabled: {
        color: 'white',
        bg: 'blueGrey.300',
        iconColor: 'primary.200',
        borderColor: 'grey.100',
        borderWidth: 1,
        shadow: undefined,
        fontStyle: 'italic',
        fontWeight: 400,
      },
    },
  },
};

export default globalDefaults;
