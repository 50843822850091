import React from 'react'
import Svg from './Svg'
export const SvgFormRequired = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M17.98 22.112H4.396V2.013h7.869v5.03a.916 1.002 0 00.916 1.002h6.633V7.033a1.833 2.004 0 00-.563-1.406L14.45.56A1.841 2.013 0 0013.18 0H4.395a1.841 2.013 0 00-1.833 2.013v20.099a1.841 2.013 0 001.833 2.013H17.98a1.841 2.013 0 001.832-2.013V20.42h-1.832zM14.097 2.979l2.918 3.052h-2.918z" />
      <path d="M22.1 11.74l-2.816 1.472.386-3.411h-2.412l.378 3.356-2.808-1.416-.74 2.519 3.018.643-2.076 2.492 2.06 1.306 1.269-2.52 1.614 2.74 1.95-1.554-2.101-2.436 3.035-.671-.757-2.52z" />
    </g>
  </Svg>
))
SvgFormRequired.isIcon = true
export default SvgFormRequired
