import PropTypes from 'prop-types';
import React from 'react';
import { animated, Spring } from 'react-spring/renderprops.cjs';
import { Box } from '../../Box';

const Rotate = ({ on, deg, children, ...rest }) => {
  return (
    <Spring
      native
      from={{ transform: 'rotate(0deg)' }}
      to={{ transform: `rotate(${on ? deg : 0}deg)` }}
      config={{ duration: 100 }}
    >
      {styles => (
        <Box
          as={animated.div}
          inline="true"
          style={{
            transformStyle: 'preserve-3d',
            transformOrigin: 'center center',
            ...styles,
          }}
          {...rest}
        >
          {children}
        </Box>
      )}
    </Spring>
  );
};

Rotate.propTypes = {
  children: PropTypes.object.isRequired,
  on: PropTypes.bool.isRequired,
  deg: PropTypes.number,
};

Rotate.defaultProps = {
  deg: 180,
};

export default Rotate;
