import styled from '@emotion/styled';
import { Icon } from '../../../../index';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../_internal/theme/stylesFor';

const CalendarThemeIcon = styled(Icon)`
  ${stylesForDefaultState('calendar')};

  &:hover {
    ${stylesForHoverState('calendar')};
  }

  &:focus {
    ${stylesForFocusState('calendar')};
  }
`;

export default CalendarThemeIcon;
