import PropTypes from 'prop-types';
import React from 'react';
import { BaseThemeText } from '../../base';

const PlaceholderText = ({ variant, isDisabled, ...props }) => (
  <BaseThemeText
    variant={variant}
    variantComponent="placeholderText"
    isDisabled={isDisabled}
    {...props}
  />
);

PlaceholderText.displayName = 'PlaceholderText';

PlaceholderText.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default PlaceholderText;
