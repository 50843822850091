import tinycolor from 'tinycolor2';

const asColorObject = value => {
  const c = tinycolor(value);
  return {
    hex: c.toHexString().toUpperCase(),
    rgb: c.toRgbString(),
    isLight: c.isLight(),
    isDark: c.isDark(),
  };
};

export default asColorObject;
