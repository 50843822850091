import React from 'react'
import Svg from './Svg'
export const SvgCommentPlus = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M21.261 13.805a7.008 7.008 0 00.762-3.047c0-4.761-4.921-8.616-10.993-8.616S0 5.966 0 10.727a7.48 7.48 0 002.285 5.241l-.54 5.135 5.027-2.415a13.567 13.567 0 004.22.662 13.712 13.712 0 002.18-.175 5.431 5.431 0 108.074-5.332zm-7.328 4.715a4.67 4.67 0 114.662 4.662 4.662 4.662 0 01-4.662-4.662zm.853-8.798a1.196 1.196 0 11-1.219 1.18 1.196 1.196 0 011.196-1.18zm-7.557 2.392a1.196 1.196 0 111.196-1.212 1.196 1.196 0 01-1.196 1.212zm3.771 0a1.196 1.196 0 111.196-1.212A1.196 1.196 0 0111 12.114z" />
      <path d="M20.895 17.758h-1.561v-1.523a.762.762 0 00-1.524 0v1.523h-1.523a.762.762 0 000 1.524h1.523v1.523a.762.762 0 001.524 0v-1.523h1.561a.762.762 0 000-1.524z" />
    </g>
  </Svg>
))
SvgCommentPlus.isIcon = true
export default SvgCommentPlus
