import styled from "@emotion/styled";
import {
  textAlign,
  borderRadius,
  color,
  layout,
  shadow,
  spacing
} from "@modernary/ui-kit-styled-system";
import PropTypes from "prop-types";
import React from "react";

const inline = props => (props.inline ? { display: "inline-block" } : null);

const StyledBox = styled("div")`
  ${color}
  ${borderRadius}
  ${shadow}
  ${spacing}
  ${layout}
  ${inline}
  ${textAlign}
`;

const Box = React.forwardRef((props, ref) => <StyledBox ref={ref} {...props} />);

Box.displayName = "Box";

Box.propTypes = {
  ...color.propTypes,
  ...borderRadius.propTypes,
  ...shadow.propTypes,
  ...spacing.propTypes,
  ...layout.propTypes,
  ...textAlign.propTypes,
  /**
   * Use inline block.
   */
  inline: PropTypes.bool,
  theme: PropTypes.object
};

Box.defaultProps = {
  inline: undefined
};

export default Box;
