import deepmerge from 'deepmerge';

const internalCreateTheme = () => ({
  defaults: {
    root: {
      bg: 'white',
      width: '75%',
      maxWidth: '600px',
      height: 'auto',
      borderWidth: 1,
      borderColor: 'grey.100',
      shadow: 'xxxlarge',
    },
  },
  default: {
    root: {
      maxHeight: 'calc(100vh - 25vh)',
    },
  },
  fullScreen: {
    root: {
      width: '100vw',
      height: '100vh',
    },
  },
});

const create = ({ defaults }) => {
  const modalTheme = internalCreateTheme(defaults);
  return {
    default: deepmerge(modalTheme.defaults, modalTheme.default),
    fullScreen: deepmerge(modalTheme.defaults, modalTheme.fullScreen),
  };
};

export default create;
