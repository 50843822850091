import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect, useRef } from 'react';

const useScrollLock = ({ reserveScrollBarGap = false }) => {
  const ref = useRef(null);

  useEffect(() => {
    const elem = ref.current;

    disableBodyScroll(elem, {
      reserveScrollBarGap: reserveScrollBarGap,
    });

    return () => {
      enableBodyScroll(elem);
    };
  }, [reserveScrollBarGap]);

  /**
   * Return to caller to set ref.
   */
  const setRef = node => (ref.current = node);

  return [setRef];
};

export default useScrollLock;
