import styled from '@emotion/styled';
import { Flex } from '../../../../Box';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../_internal/theme/stylesFor';

const BaseThemeFlexBox = styled(Flex)`
  ${stylesForDefaultState('select')}

  &:hover {
    ${stylesForHoverState('select')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('select')}
  }
`;

export default BaseThemeFlexBox;
