import React from 'react'
import Svg from './Svg'
export const SvgWindows = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M14.769 17.769v1.846H1.846v-7.384h3.692V8.538H0v12.924h16.615v-3.693h-1.846z" />
    <path d="M7.385 3v12.923H24V3H7.385zm14.769 11.077H9.231V6.692h12.923v7.385z" />
  </Svg>
))
SvgWindows.isIcon = true
export default SvgWindows
