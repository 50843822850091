import styled from '@emotion/styled';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../../_internal/theme/stylesFor';

const StyledSpan = styled('span')`
  display: block;
  ${stylesForDefaultState('tree')};

  &:hover {
    ${stylesForHoverState('tree')};
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('tree')};
  }
`;

export default StyledSpan;
