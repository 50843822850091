export const DAYS_OF_WEEK = [
  {
    letter: 'S',
    short: 'Sun',
    long: 'Sunday',
  },
  {
    letter: 'M',
    short: 'Mon',
    long: 'Monday',
  },
  {
    letter: 'T',
    short: 'Tue',
    long: 'Tuesday',
  },
  {
    letter: 'W',
    short: 'Wed',
    long: 'Wednesday',
  },
  {
    letter: 'T',
    short: 'Thu',
    long: 'Thursday',
  },
  {
    letter: 'F',
    short: 'Fri',
    long: 'Friday',
  },
  {
    letter: 'S',
    short: 'Sat',
    long: 'Saturday',
  },
];

export const MONTHS_OF_YEAR = [
  {
    number: 0,
    letter: 'J',
    short: 'Jan',
    long: 'January',
  },
  {
    number: 1,
    letter: 'F',
    short: 'Feb',
    long: 'February',
  },
  {
    number: 2,
    letter: 'M',
    short: 'Mar',
    long: 'March',
  },
  {
    number: 3,
    letter: 'A',
    short: 'Apr',
    long: 'April',
  },
  {
    number: 4,
    letter: 'M',
    short: 'May',
    long: 'May',
  },
  {
    number: 5,
    letter: 'J',
    short: 'Jun',
    long: 'June',
  },
  {
    number: 6,
    letter: 'J',
    short: 'Jul',
    long: 'July',
  },
  {
    number: 7,
    letter: 'A',
    short: 'Aug',
    long: 'August',
  },
  {
    number: 8,
    letter: 'S',
    short: 'Sep',
    long: 'September',
  },
  {
    number: 9,
    letter: 'O',
    short: 'Oct',
    long: 'October',
  },
  {
    number: 10,
    letter: 'N',
    short: 'Nov',
    long: 'November',
  },
  {
    number: 11,
    letter: 'D',
    short: 'Dec',
    long: 'December',
  },
];
