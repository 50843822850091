import { system } from 'styled-system';
import { getColor } from '../internal';

const borderColor = system({
  borderColor: {
    property: 'borderColor',
    scale: 'colors',
    transform: getColor,
  },
});

export default borderColor;
