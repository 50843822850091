import styled from '@emotion/styled';
import { Box } from '../../../../../Box';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../../../_internal/theme/stylesFor';

const OptionsContainer = styled(Box)`
  overflow: auto;
  ${stylesForDefaultState('select')}

  &:hover {
    cursor: pointer;
    ${stylesForHoverState('select')}
  }

  &:focus {
    ${stylesForFocusState('select')}
  }
`;

export default OptionsContainer;
