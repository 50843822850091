import PropTypes from 'prop-types';
import React from 'react';
import BaseThemeBox from '../../base/BaseThemeBox';
import BaseThemeIcon from '../../base/BaseThemeIcon';

const DownArrowButton = ({ variant, isDisabled }) => (
  <BaseThemeBox variant={variant} variantComponent="downArrowButton">
    <BaseThemeIcon
      name="SortArrow"
      variant={variant}
      isDisabled={isDisabled}
      variantComponent="downArrowIcon"
    />
  </BaseThemeBox>
);

DownArrowButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

DownArrowButton.defaultProps = {
  isDisabled: false,
};

export default DownArrowButton;
