const defaults = {
  amber: '#ffc107',
  blue: '#2196f3',
  blueGrey: '#607d8b',
  darkBlueGrey: '#515F71',
  brown: '#795548',
  cyan: '#00bcd4',
  deepOrange: '#ff5722',
  deepPurple: '#673ab7',
  green: '#4caf50',
  grey: '#7F7F7F',
  indigo: '#3f51b5',
  lightBlue: '#03a9f4',
  lightGreen: '#8bc34a',
  lime: '#cddc39',
  orange: '#ff9800',
  pink: '#e91e63',
  purple: '#9c27b0',
  red: '#f44336',
  teal: '#009688',
  yellow: '#ffeb3b',
  white: '#ffffff',
};

defaults.primary = defaults.blue;
defaults.neutral = defaults.darkBlueGrey;
defaults.warning = defaults.yellow;
defaults.danger = defaults.red;
defaults.success = defaults.green;
defaults.link = defaults.primary;

export default defaults;
