/**
 * Is the menu open?
 *
 * @param isOpen
 * @param isDisabled
 * @param isReadOnly
 * @returns {*|boolean}
 */
const isMenuOpen = ({ isOpen, isDisabled, isReadOnly }) => isOpen && !isDisabled && !isReadOnly;

export default isMenuOpen;
