import styled from '@emotion/styled';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../_internal/theme/stylesFor';
import { Icon } from '../../../index';

const InputThemeIcon = styled(Icon)`
  ${stylesForDefaultState('input')};

  &:hover {
    ${stylesForHoverState('input')};
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('input')};
  }
`;

export default InputThemeIcon;
