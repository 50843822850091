import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { borderColor, borderWidth, borderStyle } from "@modernary/ui-kit-styled-system";
import Box from "../Box";

const BorderBox = styled(Box)`
  border-color: #000;
  border-width: 0;
  border-style: solid;
  ${borderColor}
  ${borderWidth}
  ${borderStyle}
`;

BorderBox.displayName = "BorderBox";

BorderBox.propTypes = {
  ...Box.propTypes,
  ...borderColor.propTypes,
  ...borderWidth.propTypes,
  ...borderStyle.propTypes,
  theme: PropTypes.object
};

export default BorderBox;
