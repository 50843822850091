import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import CollapseButton from './collapse/CollapseButton';
import NodeLabel from './label/NodeLabel';

const NodeLine = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ariaExpanded = (isParent, isExpanded) => {
  if (!isParent) {
    return undefined;
  }
  return isExpanded ? 'true' : 'false';
};

const TreeNode = ({
  checked,
  children,
  expanded,
  parent,
  name,
  label,
  leaf,
  node,
  onCheck,
  onExpandClose,
  indeterminate,
  readonly,
  disabled,
  onExpandOpen,
  onExpandToggle,
  onFocusPrevious,
  onFocusNext,
  renderNode,
  value,
  variant,
  variantCheckbox,
}) => {
  return (
    <li role="treeitem" aria-expanded={ariaExpanded(parent, expanded)} aria-label={label}>
      <NodeLine>
        <CollapseButton
          expanded={expanded}
          leaf={leaf}
          onExpandClose={onExpandClose}
          onExpandOpen={onExpandOpen}
          onExpandToggle={onExpandToggle}
          variant={variant}
        />
        <NodeLabel
          checked={checked}
          disabled={disabled}
          expanded={expanded}
          indeterminate={indeterminate}
          label={label}
          name={name}
          node={node}
          onCheck={onCheck}
          onExpandClose={onExpandClose}
          onExpandOpen={onExpandOpen}
          onFocusNext={onFocusNext}
          onFocusPrevious={onFocusPrevious}
          parent={!leaf}
          readonly={readonly}
          renderNode={renderNode}
          value={value}
          variant={variant}
          variantCheckbox={variantCheckbox}
        />
      </NodeLine>
      {expanded && children}
    </li>
  );
};

TreeNode.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  expanded: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  leaf: PropTypes.bool.isRequired,
  name: PropTypes.string,
  node: PropTypes.object.isRequired,
  onCheck: PropTypes.func.isRequired,
  onExpandClose: PropTypes.func.isRequired,
  onExpandOpen: PropTypes.func.isRequired,
  onExpandToggle: PropTypes.func.isRequired,
  onFocusNext: PropTypes.func.isRequired,
  onFocusPrevious: PropTypes.func.isRequired,
  parent: PropTypes.bool.isRequired,
  readonly: PropTypes.bool,
  renderNode: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  variantCheckbox: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

TreeNode.defaultProps = {
  children: null,
};

export default TreeNode;
