import tinycolor2 from "tinycolor2";
import { variant, getThemeColor } from "@modernary/ui-kit-styled-system";

const buttonStyle = props => {
  return variant({
    prop: "fill",
    variants: {
      solid: {
        fontWeight: 500,
        backgroundColor: getThemeColor(`${props.baseColor}.600`)(props),
        borderColor: getThemeColor(`${props.baseColor}.600`)(props),
        color: getThemeColor("white")(props),
        "&:hover, &:focus": {
          backgroundColor: getThemeColor(`${props.baseColor}.300`)(props),
          borderColor: getThemeColor(`${props.baseColor}.300`)(props)
        },
        "&:active": {
          backgroundColor: getThemeColor(`${props.baseColor}.800`)(props),
          borderColor: getThemeColor(`${props.baseColor}.800`)(props)
        }
      },
      hollow: {
        color: getThemeColor(`${props.baseColor}.700`)(props),
        backgroundColor: "transparent",
        borderColor: getThemeColor(`${props.baseColor}.500`)(props),
        borderWidth: 1,
        fontWeight: 600,
        "&:hover, &:focus": {
          color: getThemeColor(`${props.baseColor}.800`)(props),
          backgroundColor: getThemeColor(`${props.baseColor}.100`)(props)
        }
      },
      ghost: {
        backgroundColor: "transparent",
        color: getThemeColor(`${props.baseColor}.700`)(props),
        borderColor: "transparent",
        fontWeight: 600,
        backgroundClip: "padding-box",
        "&:hover, &:focus": {
          backgroundColor: tinycolor2(getThemeColor(`${props.baseColor}.300`)(props))
            .setAlpha(0.25)
            .toHslString(),
          borderColor: tinycolor2(getThemeColor(`${props.baseColor}.300`)(props))
            .setAlpha(0.25)
            .toHslString(),
          color: getThemeColor(`${props.baseColor}.900`)(props)
        }
      }
    }
  });
};

export default buttonStyle;
