import { system } from 'styled-system';
import { getColor } from '../internal';

const config = {
  color: {
    property: 'color',
    scale: 'colors',
    transform: getColor,
  },
};

export default system(config);
