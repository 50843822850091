import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Flex } from '../../../Box';
import { Popper } from '../../../Util';
import { NodeModel } from '../../CheckboxTree';
import DownArrowFlexItems from '../_internal/arrow/DownArrowFlexItems';
import BaseThemeBox from '../_internal/base/BaseThemeBox';
import {
  DEFAULT_POPPER_IS_USE_PORTAL,
  DEFAULT_POPPER_OFFSET_Y,
  DEFAULT_POPPER_OVERLAY_OPACITY,
  DEFAULT_POPPER_PLACEMENT,
} from '../_internal/constants';
import isMenuOpen from '../_internal/functions/isMenuOpen';
import useWidth from '../_internal/hooks/useWidth';
import PlaceholderFlexItems from '../_internal/placeholder/PlaceholderFlexItems';
import SearchFlexItems from '../_internal/search/SearchFlexItems';
import SelectFlexBoxContainer from '../_internal/select/SelectFlexBoxContainer';
import SelectionFlexItems from '../_internal/selection/SelectionFlexItems';
import MultiSelectContainerBox from '../MultiSelect/internal/MultiSelectContainerBox';
import AbbreviatedSelectionsFlexItems from './_internal/AbbreviatedSelectionsFlexItems';
import CheckboxTreeOptions from './_internal/CheckboxTreeOptions';

const hasOptions = options => options && options.length > 0;

const TreeMultiSelect = ({
  expanded,
  id,
  isAbbreviateSelections,
  isDisabled,
  isReadOnly,
  isSearching,
  menuHeight,
  menuWidth,
  nodeId,
  nodeLabel,
  nodeValue,
  nodeChildren,
  nodes,
  onChange,
  onExpand,
  onRequestSearch,
  placeholder,
  renderNode,
  renderSelection,
  searchDebounceDelay,
  value,
  variant,
  variantCheckboxTree,
}) => {
  const referenceRef = useRef(null);
  const width = useWidth(referenceRef);
  const [isOpen, setOpen] = useState(false);
  const [nodeModel, setNodeModel] = useState(
    new NodeModel({ nodes, value, expanded, nodeId, nodeChildren, nodeLabel, nodeValue })
  );
  const [selectedItems, setSelectedItems] = useState(value);
  const [expandedItems, setExpandedItems] = useState(expanded);

  const hasSelections = selectedItems && selectedItems.length > 0;
  const isInputDisabled = isDisabled || isReadOnly;
  const isInputEnabled = !isInputDisabled;
  const isShowArrow = !isShowMenu && isInputEnabled;
  const isShowInput = !(isInputDisabled && selectedItems.length > 0);
  const isShowMenu = isMenuOpen({ isOpen, isDisabled, isReadOnly });
  const isShowSearch = isShowMenu && onRequestSearch;
  const isEnumerateSelections = !isAbbreviateSelections && selectedItems;
  const isShowAbbreviatedSelections = isAbbreviateSelections && hasSelections;
  const isShowPlaceholder = !isShowMenu && !isShowAbbreviatedSelections;

  /**
   * Update expanded items when they change from the outside.
   */
  useEffect(() => {
    setExpandedItems(expanded);
  }, [expanded]);

  /**
   * Update value items when they change from the outside.
   */
  useEffect(() => {
    setSelectedItems(value);
  }, [value]);

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  const getRemoveButtonProps = ({ item }) => {
    return {
      onClick: () => {
        const nodes = selectedItems.filter(i => nodeId(item) !== nodeId(i));
        handleOnChange(nodes);
      },
    };
  };

  const getInputProps = ({ ...rest }) => {
    return {
      ...rest,
    };
  };

  const handleOnChange = nodes => {
    setSelectedItems(nodes);
    onChange && onChange(nodes);
  };

  const handleOnExpand = expanded => {
    setExpandedItems(expanded);
    onExpand && onExpand(expanded);
  };

  const handleOnModelChanged = model => {
    setNodeModel(model);
  };

  return (
    <MultiSelectContainerBox
      width="100%"
      ref={referenceRef}
      variant={variant}
      variantComponent="multiSelect"
      isDisabled={isInputDisabled}
      onClick={toggleMenu}
    >
      <Popper
        isOpen={isShowMenu}
        isUsePortal={DEFAULT_POPPER_IS_USE_PORTAL}
        offsetY={DEFAULT_POPPER_OFFSET_Y}
        overlayOpacity={DEFAULT_POPPER_OVERLAY_OPACITY}
        placement={DEFAULT_POPPER_PLACEMENT}
        width={width}
        renderReference={({ ref }) => (
          <Fragment>
            {isEnumerateSelections &&
              selectedItems.map(option => (
                <SelectFlexBoxContainer
                  key={nodeId(option)}
                  variant={variant}
                  variantComponent="multiSelection"
                  toggleMenu={toggleMenu}
                  isDisabled={isDisabled || isReadOnly}
                >
                  <SelectionFlexItems
                    option={option}
                    inputName={id}
                    renderSelection={renderSelection}
                    isReadOnly={isReadOnly}
                    isDisabled={isDisabled}
                    optionId={nodeId}
                    variant={variant}
                    getRemoveButtonProps={isInputEnabled ? getRemoveButtonProps : undefined}
                  />
                </SelectFlexBoxContainer>
              ))}

            {isShowInput && (
              <SelectFlexBoxContainer
                variant={variant}
                toggleMenu={toggleMenu}
                variantComponent="multiPlaceholder"
                ref={ref}
                isDisabled={isDisabled || isReadOnly}
              >
                {isShowPlaceholder && (
                  <PlaceholderFlexItems
                    variant={variant}
                    isDisabled={isDisabled || isReadOnly}
                    placeholder={placeholder}
                  />
                )}
                {isShowSearch && (
                  <SearchFlexItems
                    getInputProps={getInputProps}
                    isSearching={isSearching}
                    onRequestSearch={onRequestSearch}
                    searchDebounceDelay={searchDebounceDelay}
                    variant={variant}
                  />
                )}
                {isShowAbbreviatedSelections && (
                  <AbbreviatedSelectionsFlexItems
                    isDisabled={isDisabled || isReadOnly}
                    model={nodeModel}
                    selectedItems={selectedItems}
                    variant={variant}
                  />
                )}
                {!isShowPlaceholder && !isShowSearch && !isShowAbbreviatedSelections && (
                  <Flex.Item flex={1} />
                )}
                {isShowArrow && (
                  <DownArrowFlexItems
                    isDisabled={isDisabled || isReadOnly}
                    isMenuOpen={isShowMenu}
                    variant={variant}
                  />
                )}
              </SelectFlexBoxContainer>
            )}
          </Fragment>
        )}
        renderPopper={() => {
          //
          // Empty options
          //
          if (!hasOptions(nodes)) {
            return (
              <BaseThemeBox variant={variant} variantComponent="emptyOptions">
                {onRequestSearch ? 'None found' : 'No options'}
              </BaseThemeBox>
            );
          }

          //
          // Non-Windowed options.
          //
          return (
            <CheckboxTreeOptions
              expanded={expandedItems}
              id={`${id}-tree`}
              menuHeight={menuHeight}
              menuWidth={menuWidth}
              nodeChildren={nodeChildren}
              nodeId={nodeId}
              nodeLabel={nodeLabel}
              nodeValue={nodeValue}
              nodes={nodes}
              onChange={handleOnChange}
              onExpand={handleOnExpand}
              onModelChange={handleOnModelChanged}
              renderNode={renderNode}
              value={value}
              variant={variant}
              variantCheckboxTree={variantCheckboxTree}
            />
          );
        }}
      />
    </MultiSelectContainerBox>
  );
};

TreeMultiSelect.propTypes = {
  /**
   * Array of nodes to expand when menu opens.
   */
  expanded: PropTypes.array,
  /**
   * The id and name of the input.
   */
  id: PropTypes.string,
  /**
   * Disables select control.
   */
  isDisabled: PropTypes.bool.isRequired,
  /**
   * Sets input to readOnly.
   */
  isReadOnly: PropTypes.bool.isRequired,
  /**
   * Summarize the selections rather than listing all selections. Listing all selections could
   * strain UI real estate when many options are selected. This option will summarize those
   * selections into a single line.
   */
  isAbbreviateSelections: PropTypes.bool.isRequired,
  /**
   * Used in combination with onRequestSearch to tell the select component that
   * a search request is in progress.
   */
  isSearching: PropTypes.bool.isRequired,
  /**
   * The height of the menu options panel.
   */
  menuHeight: PropTypes.number,
  /**
   * The min-width of the menu options panel. Set this to enable horizontal scrolling.
   */
  menuWidth: PropTypes.number,
  /**
   * Called when a search term changes and a search is requested. The term is used
   * to filter and update the list of options supplied to the select component.
   */
  onRequestSearch: PropTypes.func,
  /**
   * Called when the selection changes passing a the selected options as an argument.
   */
  onChange: PropTypes.func,
  /**
   * Called when the expanded items changed.
   */
  onExpand: PropTypes.func,
  /**
   * Function that should return either a string or number id of a node. The function
   * accepts object with a "node" key of the node.
   */
  nodeId: PropTypes.func.isRequired,
  /**
   * Function to return the ADA label of each tree node.
   */
  nodeLabel: PropTypes.func.isRequired,
  /**
   * Function that returns the value to use as the input checkbox value.
   */
  nodeValue: PropTypes.func,
  /**
   * Function that should return an array of children for the node argument. The function
   * accepts object with an "node" key of the node.
   */
  nodeChildren: PropTypes.func.isRequired,
  /**
   * The array of options to select from.
   */
  nodes: PropTypes.array,
  /**
   * PlaceholderText text when there are no selected options.
   */
  placeholder: PropTypes.string,
  /**
   * A custom render function to render an option. The function accepts an object
   * with an "item" key of the option.
   */
  renderNode: PropTypes.func,
  /**
   * A custom render function to render a selected option. The function accepts
   * an object with an "item" key of the option.
   */
  renderSelection: PropTypes.func,
  /**
   * The delay time (ms) of debounced search requests.
   */
  searchDebounceDelay: PropTypes.number,
  /**
   * The input value array.
   */
  value: PropTypes.array,
  /**
   * The theme variant.
   */
  variant: PropTypes.string,
  /**
   * The theme variant of the CheckboxTree.
   */
  variantCheckboxTree: PropTypes.string,
};

TreeMultiSelect.defaultProps = {
  expanded: [],
  id: undefined,
  isAbbreviatedSelections: false,
  isDisabled: false,
  isReadOnly: false,
  isSearching: false,
  menuHeight: 240,
  nodeId: undefined,
  nodeLabel: undefined,
  nodeValue: undefined,
  nodeChildren: undefined,
  nodes: undefined,
  onChange: undefined,
  onExpand: undefined,
  onRequestSearch: undefined,
  placeholder: 'Select...',
  renderOption: undefined,
  renderSelection: undefined,
  searchDebounceDelay: 150,
  value: undefined,
  variant: 'default',
  variantCheckboxTree: 'default',
};

export default TreeMultiSelect;
