import styled from "@emotion/styled";
import { zIndex } from "@modernary/ui-kit-styled-system";
import PropTypes from "prop-types";
import React from "react";
import { animated } from "react-spring";
import { BorderBox } from "../../../";
import { stylesForDefaultState } from "../../../_internal/theme/stylesFor";
import useScrollLock from "./hooks/useScrollLock";

const propList = ["variantComponent", "zIndex"];
const shouldForwardProp = prop => !propList.includes(prop);

const Root = styled(BorderBox, { shouldForwardProp })`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  overflow-y: auto;
  ${zIndex}
  ${stylesForDefaultState("modal")}
`;

const ModalRoot = ({ children, variant, zIndex, style, ...rest }) => {
  const [setRef] = useScrollLock({ reserveScrollBarGap: true });

  return (
    <Root
      ref={setRef}
      as={animated.div}
      variant={variant}
      variantComponent="root"
      zIndex={zIndex}
      style={style}
      {...rest}
    >
      {children}
    </Root>
  );
};

ModalRoot.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  style: PropTypes.object,
  zIndex: PropTypes.number.isRequired
};

ModalRoot.defaultProps = {
  variant: "default"
};

export default ModalRoot;
