import { getThemeColor, px } from "@modernary/ui-kit-styled-system";
import deepmerge from "deepmerge";
import get from "lodash/get";
import isArray from "lodash/isArray";
import merge from "lodash/merge";

export const hasThemeColor = style => style && style.indexOf(".") !== -1;
export const findAndReplaceThemeColorKey = (props, style) => {
  if (!hasThemeColor(style)) {
    return style;
  }
  const parts = style.split(" ");
  const colorPartIndex = parts.findIndex(p => p.indexOf(".") !== -1);
  parts[colorPartIndex] = getThemeColor(parts[colorPartIndex])(props);
  return parts.join(" ");
};

const selectThemeSpacing = (props, path) => (props.theme ? props.theme.spacings[path] : undefined);

const selectThemeShadow = (props, path) =>
  props.theme && props.theme.shadows[path]
    ? props.theme.shadows[path]
    : findAndReplaceThemeColorKey(props, path);

const selectThemeRadii = (props, path) =>
  props.theme && props.theme.radii[path] ? props.theme.radii[path] : px(path);

const selectThemeFont = (props, path) => (props.theme ? props.theme.fonts[path] : undefined);

/**
 * Returns the path the look up style values on the theme object. The path is constructed using the
 * given parameters.
 *
 * @param component theme component section (e.g. "calendar", "input").
 * @param variant theme section (e.g. "default", "small", "medium).
 * @param variantComponent theme section component type (e.g. "container", "small", "medium).
 * @param state the property name of the state variant (e.g. "hover", "disabled").
 */
const themePath = (component, variant, variantComponent, state) => {
  return !state
    ? `${component}.${variant}.${variantComponent}`
    : `${component}.${variant}.${variantComponent}.${state}`;
};

/**
 * Returns an object containing styles.
 */
const stylesAtPath = (props, componentThemeRootPath, state) => {
  const { theme, variant, variantComponent } = props;
  const variants = isArray(variant) ? variant : [variant];
  return variants.reduce((styles, variant) => {
    const path = themePath(componentThemeRootPath, variant, variantComponent, state);
    const themeAtPath = get(theme, path, {});
    const variantStyles = {
      backgroundColor: getThemeColor(themeAtPath.bg)(props),
      borderColor: getThemeColor(themeAtPath.borderColor)(props),
      borderRadius: selectThemeRadii(props, themeAtPath.borderRadius),
      borderStyle: themeAtPath.borderWidth && "solid",
      borderWidth: themeAtPath.borderWidth,

      borderBottomStyle: themeAtPath.borderBottomWidth && "solid",
      borderBottomColor: getThemeColor(themeAtPath.borderBottomColor)(props),
      borderBottomWidth: themeAtPath.borderBottomWidth,
      borderTopStyle: themeAtPath.borderTopWidth && "solid",
      borderTopColor: getThemeColor(themeAtPath.borderTopColor)(props),
      borderTopWidth: themeAtPath.borderTopWidth,
      borderRightColor: getThemeColor(themeAtPath.borderRightColor)(props),
      borderRightWidth: themeAtPath.borderRightWidth,
      borderLeftColor: getThemeColor(themeAtPath.borderLeftColor)(props),
      borderLeftWidth: themeAtPath.borderLeftWidth,

      boxShadow: selectThemeShadow(props, themeAtPath.shadow),
      color: getThemeColor(themeAtPath.color)(props),
      fontFamily: selectThemeFont(props, themeAtPath.fontFamily),
      fontSize: themeAtPath.fontSize,
      fontWeight: themeAtPath.fontWeight,
      fontStyle: themeAtPath.fontStyle,
      minHeight: themeAtPath.minHeight,
      maxHeight: themeAtPath.maxHeight,
      height: themeAtPath.height,
      margin: selectThemeSpacing(props, themeAtPath.margin),
      marginTop:
        selectThemeSpacing(props, themeAtPath.marginTop) ||
        selectThemeSpacing(props, themeAtPath.marginY),
      marginLeft:
        selectThemeSpacing(props, themeAtPath.marginLeft) ||
        selectThemeSpacing(props, themeAtPath.marginX),
      marginRight:
        selectThemeSpacing(props, themeAtPath.marginRight) ||
        selectThemeSpacing(props, themeAtPath.marginX),
      marginBottom:
        selectThemeSpacing(props, themeAtPath.marginBottom) ||
        selectThemeSpacing(props, themeAtPath.marginY),
      padding: selectThemeSpacing(props, themeAtPath.padding),
      paddingLeft:
        selectThemeSpacing(props, themeAtPath.paddingLeft) ||
        selectThemeSpacing(props, themeAtPath.paddingX),
      paddingRight:
        selectThemeSpacing(props, themeAtPath.paddingRight) ||
        selectThemeSpacing(props, themeAtPath.paddingX),
      paddingTop: selectThemeSpacing(props, themeAtPath.paddingY),
      paddingBottom: selectThemeSpacing(props, themeAtPath.paddingY),
      width: themeAtPath.width,
      minWidth: themeAtPath.minWidth,
      maxWidth: themeAtPath.maxWidth
    };
    return deepmerge(styles, variantStyles);
  }, {});
};

/**
 * generic style function for styling a box.
 */
const stylesForState = (componentThemeRootPath, state) => props => {
  const { variantComponent } = props;

  if (!variantComponent) {
    return undefined;
  }

  if (props.isChecked && props.isDisabled) {
    return merge(
      stylesAtPath(props, componentThemeRootPath),
      cursorDisabled(props),
      stylesAtPath(props, componentThemeRootPath, "disabled+checked")
    );
  }

  if (props.isSelected && props.isDisabled) {
    return merge(
      stylesAtPath(props, componentThemeRootPath),
      cursorDisabled(props),
      stylesAtPath(props, componentThemeRootPath, "disabled+selected")
    );
  }

  if (props.isFocused) {
    return merge(
      stylesAtPath(props, componentThemeRootPath),
      stylesAtPath(props, componentThemeRootPath, "focus")
    );
  }

  if (props.isChecked) {
    return merge(
      stylesAtPath(props, componentThemeRootPath),
      stylesAtPath(props, componentThemeRootPath, "checked")
    );
  }

  if (props.isSelected) {
    return merge(
      stylesAtPath(props, componentThemeRootPath),
      stylesAtPath(props, componentThemeRootPath, "selected")
    );
  }

  if (props.isDisabled) {
    return merge(
      stylesAtPath(props, componentThemeRootPath),
      cursorDisabled(props),
      stylesAtPath(props, componentThemeRootPath, "disabled")
    );
  }

  if (props.isReadOnly) {
    return merge(
      stylesAtPath(props, componentThemeRootPath),
      cursorDisabled(props),
      stylesAtPath(props, componentThemeRootPath, "readonly")
    );
  }

  if (props.isExpanded) {
    return merge(
      stylesAtPath(props, componentThemeRootPath),
      stylesAtPath(props, componentThemeRootPath, "expanded")
    );
  }

  if (props.isHighlighted) {
    return merge(
      stylesAtPath(props, componentThemeRootPath),
      stylesAtPath(props, componentThemeRootPath, "highlighted")
    );
  }

  switch (state) {
    case "normal":
      return stylesAtPath(props, componentThemeRootPath);
    case "hover":
      return merge(stylesAtPath(props, componentThemeRootPath, "hover"), cursor(props));
    case "focus":
      return stylesAtPath(props, componentThemeRootPath, "focus");
    case "placeholder":
      return stylesAtPath(props, componentThemeRootPath, "placeholder");
    case "disabled":
      return stylesAtPath(props, componentThemeRootPath, "disabled");
    case "readonly":
      return stylesAtPath(props, componentThemeRootPath, "readonly");
  }
};

const cursor = props => ({
  cursor: props.onClick && !props.isDisabled && !props.isReadOnly ? "pointer" : undefined
});

const cursorDisabled = props => ({
  cursor: props.isDisabled || props.isReadOnly ? "not-allowed" : undefined
});

export const stylesForDefaultState = componentThemeRootPath => props =>
  stylesForState(componentThemeRootPath, "normal")(props);

export const stylesForHoverState = componentThemeRootPath => props =>
  stylesForState(componentThemeRootPath, "hover")(props);

export const stylesForFocusState = componentThemeRootPath => props =>
  stylesForState(componentThemeRootPath, "focus")(props);

export const stylesForPlaceholderState = componentThemeRootPath => props =>
  stylesForState(componentThemeRootPath, "placeholder")(props);

export const stylesForDisabledState = componentThemeRootPath => props =>
  stylesForState(componentThemeRootPath, "disabled")(props);

export const stylesForReadOnlyState = componentThemeRootPath => props =>
  stylesForState(componentThemeRootPath, "readonly")(props);
