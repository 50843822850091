import deepmerge from 'deepmerge';

const checkboxDim = size => size * 0.5;

const internalCreateTheme = global => ({
  defaults: {
    root: {
      width: global.control.dimensions.medium.height,
      height: global.control.dimensions.medium.height,
      borderRadius: global.control.state.normal.borderRadius,
      focus: {
        bg: global.control.state.focus.bg,
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
        shadow: global.control.state.focus.shadow,
      },
    },
    iconChecked: {
      width: global.control.dimensions.medium.height,
      height: global.control.dimensions.medium.height,
      color: global.control.state.normal.iconColor,
      disabled: {
        color: global.control.state.disabled.iconColor,
      },
      ['disabled+checked']: {
        color: global.control.state.selected_disabled.iconColor,
      },
      hover: {
        color: global.control.state.hover.iconColor,
      },
    },
    iconNotChecked: {
      width: global.control.dimensions.medium.height,
      height: global.control.dimensions.medium.height,
      color: global.control.state.normal.iconColor,
      disabled: {
        color: global.control.state.disabled.iconColor,
      },
      ['disabled+checked']: {
        color: global.control.state.selected_disabled.iconColor,
      },
      hover: {
        color: global.control.state.hover.iconColor,
      },
    },
  },
  default: {},
  xsmall: {
    root: {
      width: global.control.dimensions.xsmall.height,
      height: global.control.dimensions.xsmall.height,
    },
    iconChecked: {
      width: checkboxDim(global.control.dimensions.xsmall.height),
      height: checkboxDim(global.control.dimensions.xsmall.height),
    },
    iconNotChecked: {
      width: checkboxDim(global.control.dimensions.xsmall.height),
      height: checkboxDim(global.control.dimensions.xsmall.height),
    },
  },
  small: {
    root: {
      width: global.control.dimensions.small.height,
      height: global.control.dimensions.small.height,
    },
    iconChecked: {
      width: checkboxDim(global.control.dimensions.small.height),
      height: checkboxDim(global.control.dimensions.small.height),
    },
    iconNotChecked: {
      width: checkboxDim(global.control.dimensions.small.height),
      height: checkboxDim(global.control.dimensions.small.height),
    },
  },
  medium: {
    root: {
      width: global.control.dimensions.medium.height,
      height: global.control.dimensions.medium.height,
    },
    iconChecked: {
      width: checkboxDim(global.control.dimensions.medium.height),
      height: checkboxDim(global.control.dimensions.medium.height),
    },
    iconNotChecked: {
      width: checkboxDim(global.control.dimensions.medium.height),
      height: checkboxDim(global.control.dimensions.medium.height),
    },
  },
  large: {
    root: {
      width: global.control.dimensions.large.height,
      height: global.control.dimensions.large.height,
    },
    iconChecked: {
      width: checkboxDim(global.control.dimensions.large.height),
      height: checkboxDim(global.control.dimensions.large.height),
    },
    iconNotChecked: {
      width: checkboxDim(global.control.dimensions.large.height),
      height: checkboxDim(global.control.dimensions.large.height),
    },
  },
  xlarge: {
    root: {
      width: global.control.dimensions.xlarge.height,
      height: global.control.dimensions.xlarge.height,
    },
    iconChecked: {
      width: checkboxDim(global.control.dimensions.xlarge.height),
      height: checkboxDim(global.control.dimensions.xlarge.height),
    },
    iconNotChecked: {
      width: checkboxDim(global.control.dimensions.xlarge.height),
      height: checkboxDim(global.control.dimensions.xlarge.height),
    },
  },
});

const create = ({ defaults } = {}) => {
  const checkboxTheme = internalCreateTheme(defaults);
  return {
    default: deepmerge(checkboxTheme.defaults, checkboxTheme.default),
    xsmall: deepmerge(checkboxTheme.defaults, checkboxTheme.xsmall),
    small: deepmerge(checkboxTheme.defaults, checkboxTheme.small),
    medium: deepmerge(checkboxTheme.defaults, checkboxTheme.medium),
    large: deepmerge(checkboxTheme.defaults, checkboxTheme.large),
    xlarge: deepmerge(checkboxTheme.defaults, checkboxTheme.xlarge),
  };
};

export default create;
