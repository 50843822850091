/**
 * Returns the passed inoption.
 */
const DEFAULT_OPTION_ID = option => option;

/**
 * Returns true when "a" and "b" are the same.
 *
 * @param a left side of comparison
 * @param b right side of comparison
 * @param optionId function to select id from the option.
 * @returns {boolean} true or false.
 */
const isSameOption = (a, b, optionId = DEFAULT_OPTION_ID) => {
  if (!b || !a) {
    return false;
  }

  return optionId(a) === optionId(b);
};

export default isSameOption;
