import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '../../../../Box';
import { Chip } from '../../../../Display';
import { NodeModel } from '../../../CheckboxTree';
import { BaseThemeText } from '../../_internal/base';

const AbbreviatedSelectionsFlexItems = ({ model, selectedItems, variant, isDisabled }) => {
  const abbreviatedSelected = model.getAbbreviatedSelections();
  const hasAbbreviatedSelectionsExt = selectedItems && selectedItems.length > 1;
  const firstAbbreviatedSelection = abbreviatedSelected[0];
  const abbreviatedHasMoreCount = selectedItems ? selectedItems.length - 1 : 0;
  const label = firstAbbreviatedSelection.label;
  const moreCount = hasAbbreviatedSelectionsExt ? abbreviatedHasMoreCount : 0;

  return (
    <>
      <Flex.Item flex="0 1 auto" mr="xsmall">
        <BaseThemeText
          as="span"
          variant={variant}
          variantComponent="selectionText"
          isDisabled={isDisabled}
        >
          {label}
        </BaseThemeText>
      </Flex.Item>
      {moreCount === 0 && <Flex.Item flex={1} />}
      {moreCount > 0 && (
        <Flex.Item flex={1}>
          <BaseThemeText variant={variant} variantComponent="selectionText" isDisabled={isDisabled}>
            <Chip baseColor="primary" variant="xsmall">
              +{moreCount}
            </Chip>
          </BaseThemeText>
        </Flex.Item>
      )}
    </>
  );
};

AbbreviatedSelectionsFlexItems.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  model: PropTypes.instanceOf(NodeModel),
  selectedItems: PropTypes.array,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default AbbreviatedSelectionsFlexItems;
