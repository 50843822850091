import PropTypes from 'prop-types';
import React from 'react';
import { BaseThemeFlexBox } from '../base';

const SelectFlexBoxContainer = React.forwardRef(
  ({ isDisabled, variantComponent, children, variant, tabIndex, toggleMenu, ...rest }, ref) => {
    return (
      <BaseThemeFlexBox
        isDisabled={isDisabled}
        onClick={toggleMenu}
        ref={ref}
        tabIndex={tabIndex || 0}
        variant={variant}
        variantComponent={variantComponent}
        alignItems="center"
        column
        {...rest}
      >
        {children}
      </BaseThemeFlexBox>
    );
  }
);

SelectFlexBoxContainer.displayName = 'SelectFlexBoxContainer';

SelectFlexBoxContainer.propTypes = {
  children: PropTypes.node.isRequired,
  tabIndex: PropTypes.number,
  isDisabled: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  variantComponent: PropTypes.string.isRequired,
};

SelectFlexBoxContainer.defaultProps = {};

export default SelectFlexBoxContainer;
