import deepmerge from 'deepmerge';

const internalCreateTheme = global => ({
  defaults: {
    root: {
      bg: 'white',
      borderColor: global.palette.primary.normal,
      borderWidth: 0,
      borderRadius: 'xxxsmall',
      width: 320,
      marginY: 'xxxsmall',
    },
    addonStart: {
      padding: 'small',
    },
    addonStartIcon: {
      width: 18,
      height: 18,
    },
    message: {
      color: global.palette.text.dark,
      padding: 'small',
      fontSize: 14,
    },
    remove: {
      padding: 'small',
    },
    removeIcon: {
      color: global.palette.text.normal,
      width: 14,
      height: 14,
    },
    progressBar: {
      height: 2,
      bg: global.palette.primary.normal,
    },
  },
  default: {},
  info: {
    root: {
      bg: 'white',
      borderColor: global.palette.primary.normal,
      borderWidth: 1,
    },
    addonStartIcon: {
      color: global.palette.primary.dark,
    },
    progressBar: {
      bg: global.palette.primary.normal,
    },
  },
  success: {
    root: {
      bg: global.palette.success.light,
      borderColor: global.palette.success.normal,
      borderWidth: 1,
    },
    addonStartIcon: {
      color: global.palette.success.normal,
    },
    progressBar: {
      bg: global.palette.success.normal,
    },
  },
  danger: {
    root: {
      bg: global.palette.danger.light,
      borderColor: global.palette.danger.normal,
      borderWidth: 1,
    },
    addonStartIcon: {
      color: global.palette.danger.normal,
    },
    progressBar: {
      bg: global.palette.danger.normal,
    },
  },
  warning: {
    root: {
      bg: global.palette.warning.light,
      borderColor: global.palette.warning.normal,
      borderWidth: 1,
    },
    addonStartIcon: {
      color: global.palette.warning.normal,
    },
    progressBar: {
      bg: global.palette.warning.normal,
    },
  },
});

const create = ({ defaults }) => {
  const toastsTheme = internalCreateTheme(defaults);
  return {
    default: deepmerge(toastsTheme.defaults, toastsTheme.default),
    info: deepmerge(toastsTheme.defaults, toastsTheme.info),
    success: deepmerge(toastsTheme.defaults, toastsTheme.success),
    danger: deepmerge(toastsTheme.defaults, toastsTheme.danger),
    warning: deepmerge(toastsTheme.defaults, toastsTheme.warning),
  };
};

export default create;
