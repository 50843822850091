import { compose } from 'styled-system';
import borderBottomLeftRadius from './borderBottomLeftRadius';
import borderBottomRightRadius from './borderBottomRightRadius';
import borderRadius from './borderRadius';
import borderTopLeftRadius from './borderTopLeftRadius';
import borderTopRightRadius from './borderTopRightRadius';

export default compose(
  borderRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderTopLeftRadius,
  borderTopRightRadius
);

export {
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
};
