import styled from "@emotion/styled";
import { border, fontWeight, margin, padding, width } from "@modernary/ui-kit-styled-system";
import { withTheme } from "emotion-theming";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../index";
import fill from "./variants/fill";
import shape from "./variants/shape";
import size from "./variants/size";

const transitionDuration = 50;

const block = ({ block }) => (block === true ? { width: "100%" } : undefined);

const StyledButton = styled("button")`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  padding: .75em 1.5em;
  border: 1px solid;
  line-height: 1.25em;
  font-weight: 500;
  ${fontWeight}
  font-family: inherit;
  text-decoration: none;
  text-transform: none;
  background: none;
  color: inherit;
  user-select: none;
  appearance: none;
  transition: border-color ${transitionDuration}ms linear,
    background-color ${transitionDuration}ms linear,
    transform ${transitionDuration * 2}ms ease;
  &:focus {
    outline: 0;
  }
  /* "Not-disabled" styles */
  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
    &:active {
      transform: scale(.98);
    }
  `}
  /* Disabled styles */
  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    opacity: .4;
    pointer-events: none;
  `}

  ${size}
  ${shape}
  ${fill}
  ${block}
  ${width}
  ${padding}
  ${margin}
  ${border}
`;

const makeIconComponent = ({ icon, iconColor, iconSize, mr, ml }) => {
  if (icon) {
    if (typeof icon === "string") {
      return <Icon name={icon} size={iconSize || 14} mr={mr} ml={ml} color={iconColor} />;
    } else {
      return React.cloneElement(icon, {
        color: icon.props.color || iconColor,
        mr: mr,
        ml: ml
      });
    }
  }
  return null;
};

const getButtonHeight = (theme, size) => {
  return get(theme, `defaults.control.dimensions.${size}.height`);
};

const Button = React.forwardRef(
  (
    {
      block,
      children,
      fill,
      shape,
      size,
      baseColor,
      icon,
      iconStart,
      iconEnd,
      iconColor,
      iconSize,
      theme,
      ...rest
    },
    ref
  ) => {
    let Icon = makeIconComponent({ icon: icon, iconColor, iconSize });

    if (Icon) {
      return (
        <StyledButton
          baseColor={baseColor}
          fill={fill}
          pl={Icon ? 0 : undefined}
          pr={Icon ? 0 : undefined}
          ref={ref}
          shape={shape}
          size={size}
          width={Icon ? getButtonHeight(theme, size) : undefined}
          block={block}
          {...rest}
        >
          {Icon}
        </StyledButton>
      );
    }

    let IconStart = makeIconComponent({ icon: iconStart, iconColor, iconSize, mr: "xsmall" });
    let IconEnd = makeIconComponent({ icon: iconEnd, iconColor, iconSize, ml: "xsmall" });

    return (
      <StyledButton
        baseColor={baseColor}
        fill={fill}
        pl={Icon ? 0 : undefined}
        pr={Icon ? 0 : undefined}
        ref={ref}
        shape={shape}
        size={size}
        width={Icon ? width : undefined}
        block={block}
        {...rest}
      >
        {IconStart}
        {Icon !== null ? Icon : children}
        {IconEnd}
      </StyledButton>
    );
  }
);

Button.displayName = "Button";

Button.propTypes = {
  /**
   * Shortcut to 100% width.
   */
  block: PropTypes.bool,
  /**
   * Children, typically just text.
   */
  children: PropTypes.node,
  /**
   * Button fill.
   */
  fill: PropTypes.oneOf(["solid", "hollow", "ghost"]).isRequired,
  /**
   * Set the icon to make this button a solo icon button - children will be ignored.
   */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Sets the icon color.
   */
  iconColor: PropTypes.string,
  /**
   * Sets the icon size.
   */
  iconSize: PropTypes.number,
  /**
   * Icon string name or jsx to be placed at the start of the button.
   */
  iconStart: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Icon string name or jsx to be placed at the end of the button.
   */
  iconEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Shape of the button.
   */
  shape: PropTypes.oneOf(["soft", "round", "square"]).isRequired,
  /**
   * Size of the button.
   */
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large", "xlarge"]).isRequired,
  /**
   * Base color of the button.
   */
  baseColor: PropTypes.string,
  /**
   * Theme object.
   */
  theme: PropTypes.object
};

Button.defaultProps = {
  block: undefined,
  children: null,
  fill: "solid",
  shape: "soft",
  size: "medium",
  baseColor: "neutral"
};

export default withTheme(Button);
