import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import { Flex } from '../../../';
import ToastsThemeBox from './base/ToastsThemeBox';
import ToastsThemeFlexItem from './base/ToastsThemeFlexItem';
import ToastsThemeIcon from './base/ToastsThemeIcon';

const Root = styled(ToastsThemeBox)`
  overflow: hidden;
`;

const Toast = ({ toast, onDismiss, autoDismissTimeout }) => {
  const handleOnRest = () => {
    if (toast.autoDismiss) {
      onDismiss && onDismiss(toast);
    }
  };

  const springStyles = useSpring({
    from: { width: '100%' },
    to: { width: '0%' },
    config: { duration: autoDismissTimeout },
    onRest: handleOnRest,
  });

  const handleOnClickDismiss = () => {
    onDismiss && onDismiss(toast);
  };

  const themeVariant = toast.variant || 'default';

  return (
    <Root variant={themeVariant} variantComponent="root">
      <Flex column alignItems="flex-start" width="100%">
        {toast.addonStart && (
          <ToastsThemeFlexItem flex={0} variant={themeVariant} variantComponent="addonStart">
            {toast.addonStart}
          </ToastsThemeFlexItem>
        )}
        {toast.iconName && (
          <ToastsThemeFlexItem flex={0} variant={themeVariant} variantComponent="addonStart">
            <ToastsThemeIcon
              name={toast.iconName}
              variant={themeVariant}
              variantComponent="addonStartIcon"
            />
          </ToastsThemeFlexItem>
        )}
        <ToastsThemeFlexItem flex={1} variant={themeVariant} variantComponent="message">
          {toast.message || ''}
        </ToastsThemeFlexItem>
        <Flex.Item flex={0}>
          <ToastsThemeBox
            onClick={handleOnClickDismiss}
            variant={themeVariant}
            variantComponent="remove"
          >
            <ToastsThemeIcon name="X" variant={themeVariant} variantComponent="removeIcon" />
          </ToastsThemeBox>
        </Flex.Item>
      </Flex>
      {toast.autoDismiss && (
        <ToastsThemeBox
          as={animated.div}
          style={{
            ...springStyles,
          }}
          variant={themeVariant}
          variantComponent="progressBar"
        />
      )}
    </Root>
  );
};

Toast.displayName = 'Toast';

Toast.propTypes = {
  /**
   * The toast.
   */
  toast: PropTypes.shape({
    /**
     * Message to display, can be anything render-able.
     */
    message: PropTypes.node,
    /**
     * Automatically dismiss the toast notification.
     */
    autoDismiss: PropTypes.bool,
    /**
     * The style of notification.
     */
    variant: PropTypes.oneOf(['success', 'danger', 'info', 'warning', 'default']),
    /**
     * A custom addon.
     */
    addonStart: PropTypes.node,
    /**
     * Name of icon to show.
     */
    iconName: PropTypes.string,
  }),
  /**
   * Dismiss toast after this many milliseconds.
   */
  autoDismissTimeout: PropTypes.number,
  /**
   * Callback when toast timeout has expired.
   */
  onDismiss: PropTypes.func,
};

Toast.defaultProps = {};

export default Toast;
