import getYear from 'date-fns/getYear';
import PropTypes from 'prop-types';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { Table, TableRow } from '../base';
import getPickYearRange from '../functions/getPickYearRange';
import Year from './Year';

const makeSelector = year => `div[data-year="${year}"]`;

const PickYear = React.forwardRef(
  ({ onPickYear, onFocusYear, date, variant, canSelectYear }, ref) => {
    const tableRef = useRef(null);

    const getActiveYearInt = () => {
      return Number.parseInt(document.activeElement.getAttribute('data-year'));
    };

    const getYearElement = year => {
      return tableRef.current.querySelector(makeSelector(year));
    };

    // on mount focus current year.
    useEffect(() => {
      const yearToFocus = getYearElement(getYear(date));
      if (yearToFocus) {
        yearToFocus.focus();
      }
    }, [date]);

    useImperativeHandle(ref, () => ({
      focusInitial: () => {
        const yearToFocus = getYearElement(getYear(date));
        if (yearToFocus) {
          yearToFocus.focus();
          return true;
        }
        return false;
      },
      focusPrevious: () => {
        const yearToFocus = getYearElement(getActiveYearInt() - 1);
        if (yearToFocus) {
          yearToFocus.focus();
          return true;
        }
        return false;
      },
      focusNext: () => {
        const yearToFocus = getYearElement(getActiveYearInt() + 1);
        if (yearToFocus) {
          yearToFocus.focus();
          return true;
        }
        return false;
      },
    }));

    return (
      <Table isCollapsed={false} ref={tableRef}>
        {getPickYearRange().map(year => {
          const isCanSelectYear = canSelectYear(year);
          return (
            <TableRow key={year}>
              <Year
                date={date}
                isSelected={getYear(date) === year}
                onClickYear={isCanSelectYear ? onPickYear : undefined}
                onFocusYear={onFocusYear}
                tabIndex={0}
                variant={variant}
                year={year}
                isDisabled={!isCanSelectYear}
              >
                {year}
              </Year>
            </TableRow>
          );
        })}
      </Table>
    );
  }
);

PickYear.displayName = 'PickYear';

PickYear.propTypes = {
  /**
   * Callback to determine if year can be selected.
   */
  canSelectYear: PropTypes.func.isRequired,
  /**
   * Selected date.
   */
  date: PropTypes.instanceOf(Date),
  /**
   * Calendar theme variant.
   */
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Called when year is picked.
   */
  onPickYear: PropTypes.func.isRequired,
  /**
   * Called when a year dom element is focused.
   */
  onFocusYear: PropTypes.func.isRequired,
};

PickYear.defaultProps = {
  date: undefined,
  canSelectYear: () => true,
};

export default PickYear;
