import { createTheme } from '../';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import PropTypes from 'prop-types';
import React from 'react';
import BaseFont from './BaseFont';
import BoxSizingReset from './BoxSizing';
import CssReset from './CssReset';

const ThemeProvider = ({ cssReset, boxSizingReset, theme, children }) => {
  return (
    <EmotionThemeProvider theme={theme || createTheme({})}>
      {cssReset && <CssReset />}
      {boxSizingReset && <BoxSizingReset />}
      <BaseFont />
      {children}
    </EmotionThemeProvider>
  );
};

ThemeProvider.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.node,
  cssReset: PropTypes.bool,
  boxSizingReset: PropTypes.bool,
};

ThemeProvider.defaultProps = {
  theme: undefined,
  children: null,
  cssReset: true,
  boxSizingReset: true,
};

export default ThemeProvider;
