import { themeGet } from "@modernary/ui-kit-styled-system";
import isFunction from "lodash/isFunction";

/**
 * Returns a function that when called will find the first "isDark" color by looking up the color
 * object using the "baseColor" and return css style object. For example: { backgroundColor:
 * #e423e4 }
 *
 * @param cssProperty
 * @param baseColor
 * @param propsName the name on the props object holding the base color
 * @param moveBy Alters the color selection by navigating up or down the index of the color object.
 * @returns {function(...[*]=)}
 */
export const themeDarkShadeInBaseColor = ({
  cssProperty,
  baseColor,
  propsName,
  moveBy,
  useMiddleDark
}) => props => {
  const color = props[propsName] || baseColor;
  const colors = themeGet(`colors.${color}`)(props);

  if (!colors) {
    return undefined;
  }

  const colorsArray = Object.values(colors);
  const foundIndex = colorsArray.findIndex(color => color.isDark);

  if (foundIndex === -1) {
    return { [cssProperty]: "lightGrey" };
  }

  if (foundIndex) {
    let actualMoveBy = moveBy;

    if (useMiddleDark) {
      const darkColors = colorsArray.filter(c => c.isDark);
      actualMoveBy = Math.floor(darkColors.length / 2);
      if (moveBy) {
        actualMoveBy += moveBy;
      }
    }

    // move to next shade by the amount of moveBy (+ or -) if valid, otherwise return the found one.
    if (actualMoveBy) {
      const movedBy = colorsArray[foundIndex + actualMoveBy];
      if (movedBy) {
        return { [cssProperty]: movedBy.hex };
      }
      return { [cssProperty]: colorsArray[foundIndex].hex };
    }

    return { [cssProperty]: colorsArray[foundIndex].hex };
  }
  return undefined;
};

/**
 *
 * @param cssProperty
 * @param baseColor
 * @param propsName
 * @param colorVariant
 * @returns {Function}
 */
export const themeGetColor = ({ cssProperty, baseColor, propsName, colorVariant }) => props => {
  const color = props[propsName] || baseColor;
  const variant = isFunction(colorVariant) ? colorVariant(props) : colorVariant;

  const colorObj = themeGet(`colors.${color}.${variant}`)(props);

  if (colorObj) {
    return { [cssProperty]: colorObj.hex };
  }

  return null;
};
