import React from 'react'
import Svg from './Svg'
export const SvgX = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path
      d="M21.811 21.821L2.156 2.266m0 19.555L21.811 2.266"
      stroke="currentcolor"
      strokeWidth={6}
    />
  </Svg>
))
SvgX.isIcon = true
export default SvgX
