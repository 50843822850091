import deepmerge from 'deepmerge';

const BG_HEIGHT_XSMALL = 18;
const BG_HEIGHT_SMALL = 22;
const BG_HEIGHT_MEDIUM = 24;
const BG_HEIGHT_LARGE = 28;
const BG_HEIGHT_xLARGE = 32;

const rootWidth = num => num * 2 - 4;
const handleDim = num => num - 4;
const handleRadius = num => num / 2;

const internalCreateTheme = global => ({
  defaults: {
    root: {
      bg: 'transparent',
      paddingX: 'xsmall',
      borderRadius: global.control.state.normal.borderRadius,
      borderWidth: global.control.state.normal.borderWidth,
      focus: {
        borderRadius: global.control.state.focus.borderRadius,
        borderWidth: global.control.state.focus.borderWidth,
        borderColor: global.control.state.focus.borderColor,
        shadow: global.control.state.focus.shadow,
      },
    },
    background: {
      bg: 'grey.200',
      borderColor: 'grey.300',
      borderWidth: global.control.state.normal.borderWidth,
      checked: {
        bg: global.control.state.selected.bgDark,
        borderColor: global.control.state.selected.borderColor,
        borderWidth: global.control.state.selected.borderWidth,
      },
      disabled: {
        bg: global.control.state.disabled.bg,
        borderColor: global.control.state.disabled.borderColor,
        borderWidth: global.control.state.disabled.borderWidth,
      },
      ['disabled+checked']: {
        bg: global.control.state.selected_disabled.bg,
        borderColor: global.control.state.selected_disabled.borderColor,
        borderWidth: global.control.state.selected_disabled.borderWidth,
      },
    },
    handle: {
      bg: 'white',
      shadow: 'xxxsmall',
      disabled: {
        bg: global.control.state.disabled.borderColor,
        shadow: 'none',
      },
      ['disabled+checked']: {
        bg: global.control.state.disabled.borderColor,
        shadow: 'none',
      },
    },
  },
  default: {},
  xsmall: {
    root: {
      height: global.control.dimensions.xsmall.height,
    },
    background: {
      borderRadius: handleRadius(BG_HEIGHT_XSMALL),
      width: rootWidth(BG_HEIGHT_XSMALL),
      height: BG_HEIGHT_XSMALL,
    },
    handle: {
      borderRadius: handleRadius(BG_HEIGHT_XSMALL),
      width: handleDim(BG_HEIGHT_XSMALL),
      height: handleDim(BG_HEIGHT_XSMALL),
    },
  },
  small: {
    root: {
      height: global.control.dimensions.small.height,
    },
    background: {
      borderRadius: handleRadius(BG_HEIGHT_SMALL),
      width: rootWidth(BG_HEIGHT_SMALL),
      height: BG_HEIGHT_SMALL,
    },
    handle: {
      borderRadius: handleRadius(BG_HEIGHT_SMALL),
      width: handleDim(BG_HEIGHT_SMALL),
      height: handleDim(BG_HEIGHT_SMALL),
    },
  },
  medium: {
    root: {
      height: global.control.dimensions.medium.height,
    },
    background: {
      borderRadius: handleRadius(BG_HEIGHT_MEDIUM),
      width: rootWidth(BG_HEIGHT_MEDIUM),
      height: BG_HEIGHT_MEDIUM,
    },
    handle: {
      borderRadius: handleRadius(BG_HEIGHT_MEDIUM),
      width: handleDim(BG_HEIGHT_MEDIUM),
      height: handleDim(BG_HEIGHT_MEDIUM),
    },
  },
  large: {
    root: {
      height: global.control.dimensions.large.height,
    },
    background: {
      borderRadius: handleRadius(BG_HEIGHT_LARGE),
      width: rootWidth(BG_HEIGHT_LARGE),
      height: BG_HEIGHT_LARGE,
    },
    handle: {
      borderRadius: handleRadius(BG_HEIGHT_LARGE),
      width: handleDim(BG_HEIGHT_LARGE),
      height: handleDim(BG_HEIGHT_LARGE),
    },
  },
  xlarge: {
    root: {
      height: global.control.dimensions.xlarge.height,
    },
    background: {
      borderRadius: handleRadius(BG_HEIGHT_xLARGE),
      width: rootWidth(BG_HEIGHT_xLARGE),
      height: BG_HEIGHT_xLARGE,
    },
    handle: {
      borderRadius: handleRadius(BG_HEIGHT_xLARGE),
      width: handleDim(BG_HEIGHT_xLARGE),
      height: handleDim(BG_HEIGHT_xLARGE),
    },
  },
});

const createSwitch = ({ defaults }) => {
  const selectTheme = internalCreateTheme(defaults);
  return {
    default: deepmerge(selectTheme.defaults, selectTheme.default),
    xsmall: deepmerge(selectTheme.defaults, selectTheme.xsmall),
    small: deepmerge(selectTheme.defaults, selectTheme.small),
    medium: deepmerge(selectTheme.defaults, selectTheme.medium),
    large: deepmerge(selectTheme.defaults, selectTheme.large),
    xlarge: deepmerge(selectTheme.defaults, selectTheme.xlarge),
  };
};

export default createSwitch;
