import PropTypes from 'prop-types';
import React from 'react';
import BaseThemeIcon from '../../base/BaseThemeIcon';

const SpinnerIcon = ({ variant }) => (
  <BaseThemeIcon name="LoadingBubbles" variant={variant} variantComponent="spinnerIcon" />
);

SpinnerIcon.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default SpinnerIcon;
