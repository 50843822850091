import styled from '@emotion/styled';
import isToday from 'date-fns/isToday';
import PropTypes from 'prop-types';
import React from 'react';
import { TableCell } from '../base';
import CalendarThemeTableCell from '../base/CalendarThemeTableCell';
import formatDate from '../functions/formatDate';

const highlightToday = ({ isToday, isSelected }) =>
  isToday && !isSelected
    ? {
        border: '1px solid rgba(187,0,0,0.4)',
      }
    : undefined;

const StyledTableCell = styled(TableCell)`
  ${highlightToday}
`;

const StyledCalendarThemeTableCell = styled(CalendarThemeTableCell)`
  ${highlightToday}
`;

const Day = ({
  children,
  day,
  isDisabled,
  isHighlighted,
  isSelected,
  onClick,
  onFocus,
  variant,
}) => {
  const Root = day.isOtherMonth ? StyledTableCell : StyledCalendarThemeTableCell;
  const tabIndex = !day.isOtherMonth && !isDisabled ? 0 : undefined;
  const handleOnClick = () => onClick && !isDisabled && onClick(day.date);
  const handleOnFocus = () => onFocus && !isDisabled && onFocus(day.date);
  const handleOnKeyDown = e => e.key === 'Enter' && !isDisabled && handleOnClick(day.date);
  const dateFormatted = formatDate(day.date, 'MMMM do yyyy');
  const isDateToday = isToday(day.date);
  const title = isDateToday ? `${dateFormatted} (today)` : dateFormatted;
  return (
    <Root
      aria-label={title}
      data-date={!day.isOtherMonth ? day.date.getDate() : undefined}
      isDisabled={isDisabled}
      isHighlighted={isHighlighted}
      isSelected={isSelected}
      isToday={isDateToday}
      onClick={handleOnClick}
      onFocus={handleOnFocus}
      onKeyDown={handleOnKeyDown}
      role="button"
      tabIndex={tabIndex}
      variant={variant}
      variantComponent="day"
      width="14.285%"
      title={title}
    >
      {day.isOtherMonth ? '' : children}
    </Root>
  );
};

Day.propTypes = {
  children: PropTypes.node.isRequired,
  day: PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    isOtherMonth: PropTypes.bool.isRequired,
  }).isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  isHighlighted: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Day.defaultProps = {
  onClick: undefined,
  isDisabled: false,
  onFocus: undefined,
  isSelected: false,
  isHighlighted: false,
};

export default Day;
