import React from 'react'
import Svg from './Svg'
export const SvgNotification = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M22.943 1.627H1.057C.474 1.627 0 2.101 0 2.684v13.242c0 .584.474 1.057 1.057 1.057h12.345v4.37a1.057 1.057 0 001.862.686l4.306-5.056h3.373c.584 0 1.057-.473 1.057-1.057V2.684c0-.583-.473-1.057-1.057-1.057M21.886 14.87H19.08c-.31 0-.604.136-.804.371l-2.76 3.241v-2.555c0-.584-.474-1.057-1.058-1.057H2.115V3.742h19.77v11.127z" />
      <path d="M5.913 8.47h7.545a1.057 1.057 0 000-2.114H5.913a1.057 1.057 0 100 2.115M17.046 8.47a1.059 1.059 0 00.748-1.805 1.065 1.065 0 00-.748-.31c-.278 0-.55.114-.746.31a1.054 1.054 0 00.745 1.805" />
    </g>
  </Svg>
))
SvgNotification.isIcon = true
export default SvgNotification
