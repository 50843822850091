import deepmerge from 'deepmerge';

const internalCreateTheme = global => ({
  defaults: {
    root: {
      bg: global.control.state.normal.bg,
      borderRadius: 'xxsmall',
      shadow: 'medium',
      width: 240,
    },
    rootRange: {
      bg: global.control.state.normal.bg,
      borderRadius: 'xxsmall',
      shadow: 'medium',
      width: 480,
    },
    header: {
      bg: global.palette.primary.dark,
      borderColor: global.palette.primary.dark,
      borderWidth: 1,
      padding: 'xsmall',
    },
    headerTitle: {
      color: 'white',
      fontFamily: 'sans',
      fontSize: 14,
    },
    headerYear: {
      color: 'white',
      fontFamily: 'sans',
      fontSize: 14,
    },
    headerDate: {
      color: 'white',
      fontFamily: 'sans',
      fontSize: 32,
    },
    headerEmptyDate: {
      color: global.palette.primary.normal,
      fontSize: 18,
      marginTop: 'medium',
    },
    navigator: {
      padding: 'xxsmall',
    },
    navigatorDate: {
      bg: global.control.state.normal.bg,
      borderColor: 'transparent',
      borderRadius: 'xxsmall',
      borderWidth: 1,
      color: global.palette.primary.dark,
      fontSize: 14,
      margin: 'xxxsmall',
      padding: 'xsmall',
      shadow: 'none',
      hover: {
        color: global.control.state.hover.color,
        bg: global.control.state.hover.bg,
        borderColor: global.control.state.hover.borderColor,
        borderWidth: global.control.state.hover.borderWidth,
        shadow: global.control.state.hover.shadow,
      },
      focus: {
        color: global.control.state.focus.color,
        bg: global.control.state.focus.bg,
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
        shadow: global.control.state.focus.shadow,
      },
      disabled: {
        color: global.control.state.disabled.color,
        fontStyle: global.control.state.disabled.fontStyle,
      },
    },
    navigatorButton: {
      height: 32,
      width: 32,
      color: global.palette.primary.dark,
      bg: global.control.state.normal.bg,
      borderWidth: 1,
      shadow: 'none',
      borderColor: 'transparent',
      borderRadius: '50%',
      hover: {
        color: global.control.state.hover.color,
        bg: global.control.state.hover.bg,
        borderColor: global.control.state.hover.borderColor,
        borderWidth: global.control.state.hover.borderWidth,
        shadow: global.control.state.hover.shadow,
      },
      focus: {
        color: global.control.state.focus.color,
        bg: global.control.state.focus.bg,
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
        shadow: global.control.state.focus.shadow,
      },
      disabled: {
        color: global.control.state.disabled.color,
      },
    },
    navigatorButtonIcon: {
      margin: 'xsmall',
      height: 12,
      width: 12,
    },
    daysOfWeek: {
      borderRadius: 'xsmall',
      color: global.palette.primary.dark,
      fontFamily: 'sans',
      fontSize: 12,
      fontWeight: 'bold',
      height: 28,
    },
    day: {
      color: global.control.state.normal.color,
      borderColor: 'transparent',
      borderRadius: global.control.state.normal.borderRadius,
      borderWidth: global.control.state.normal.borderWidth,
      fontWeight: global.control.state.normal.fontWeight,
      fontFamily: 'sans',
      fontSize: 13,
      height: 28,
      width: 28,
      focus: {
        color: global.control.state.focus.color,
        bg: global.control.state.focus.bg,
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
      },
      hover: {
        color: global.control.state.hover.color,
        bg: global.control.state.hover.bg,
        borderColor: global.control.state.hover.borderColor,
        borderWidth: global.control.state.hover.borderWidth,
      },
      highlighted: {
        bg: 'primary.100',
        borderRadius: 0,
      },
      selected: {
        color: 'white',
        bg: global.control.state.selected.bgDark,
        borderColor: global.control.state.selected.borderColor,
        borderWidth: global.control.state.selected.borderWidth,
        shadow: global.control.state.selected.shadow,
      },
      disabled: {
        color: global.control.state.disabled.color,
        shadow: global.control.state.disabled.shadow,
      },
      [`disabled+selected`]: {
        color: global.control.state.selected_disabled.color,
        bg: global.control.state.selected_disabled.bg,
        shadow: global.control.state.selected_disabled.shadow,
      },
    },
    month: {
      color: global.control.state.normal.color,
      borderColor: 'transparent',
      borderRadius: global.control.state.normal.borderRadius,
      borderWidth: global.control.state.normal.borderWidth,
      fontWeight: global.control.state.normal.fontWeight,
      fontFamily: 'sans',
      fontSize: 14,
      height: 32,
      focus: {
        color: global.control.state.focus.color,
        bg: global.control.state.focus.bg,
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
      },
      hover: {
        color: global.control.state.hover.color,
        bg: global.control.state.hover.bg,
        borderColor: global.control.state.hover.borderColor,
        borderWidth: global.control.state.hover.borderWidth,
      },
      selected: {
        color: 'white',
        bg: global.control.state.selected.bgDark,
        borderColor: global.control.state.selected.borderColor,
        borderWidth: global.control.state.selected.borderWidth,
        shadow: global.control.state.selected.shadow,
      },
      disabled: {
        color: global.control.state.disabled.color,
        shadow: global.control.state.disabled.shadow,
      },
      [`disabled+selected`]: {
        color: global.control.state.selected_disabled.color,
        bg: global.control.state.selected_disabled.bg,
        shadow: global.control.state.selected_disabled.shadow,
      },
    },
    year: {
      color: global.control.state.normal.color,
      borderColor: 'transparent',
      borderRadius: global.control.state.normal.borderRadius,
      borderWidth: global.control.state.normal.borderWidth,
      fontWeight: global.control.state.normal.fontWeight,
      fontFamily: 'sans',
      fontSize: 14,
      height: 32,
      focus: {
        color: global.control.state.focus.color,
        bg: global.control.state.focus.bg,
        borderColor: global.control.state.focus.borderColor,
        borderWidth: global.control.state.focus.borderWidth,
      },
      hover: {
        color: global.control.state.hover.color,
        bg: global.control.state.hover.bg,
        borderColor: global.control.state.hover.borderColor,
        borderWidth: global.control.state.hover.borderWidth,
      },
      selected: {
        color: 'white',
        bg: global.control.state.selected.bgDark,
        borderColor: global.control.state.selected.borderColor,
        borderWidth: global.control.state.selected.borderWidth,
        shadow: global.control.state.selected.shadow,
      },
      disabled: {
        color: global.control.state.disabled.color,
        shadow: global.control.state.disabled.shadow,
      },
      [`disabled+selected`]: {
        color: global.control.state.selected_disabled.color,
        bg: global.control.state.selected_disabled.bg,
        shadow: global.control.state.selected_disabled.shadow,
      },
    },
  },
  default: {},
});

const calendar = ({ defaults }) => {
  const selectTheme = internalCreateTheme(defaults);
  return {
    default: deepmerge(selectTheme.defaults, selectTheme.default),
  };
};

export default calendar;
