import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Divider, Flex } from '../../../index';
import DateRange from '../../_internal/model/DateRange';
import { CalendarThemeFlex } from '../_internal/base';
import CalendarWithNavigator from '../_internal/CalendarWithNavigator/CalendarWithNavigator';
import CalendarDateHeader from '../_internal/Header/CalendarDateHeader';
import HeaderEmptyDate from '../_internal/Header/HeaderEmptyDate';

const initRangeValue = value => {
  if (value && isArray(value)) {
    return new DateRange(value[0], value[1]);
  }
  return new DateRange();
};

const initCalendarLeft = internalRange => {
  return internalRange.start || new Date();
};

const initCalendarRight = internalRange => {
  return addMonths(internalRange.end || new Date(), +1);
};

const CalendarRange = React.forwardRef(
  ({ variant, value, onChange, onCancel, isDisabled, onReset }, ref) => {
    const [internalRange, setInternalRange] = useState(initRangeValue(value));
    const [calendarDateLeft, setCalendarDateLeft] = useState(initCalendarLeft(internalRange));
    const [calendarDateRight, setCalendarDateRight] = useState(initCalendarRight(internalRange));

    const [selectModeLeft, setSelectModeLeft] = useState(CalendarWithNavigator.SelectMode.Day);
    const [selectModeRight, setSelectModeRight] = useState(CalendarWithNavigator.SelectMode.Day);
    const [isPickingStartDate, setPickingStartDate] = useState(true);

    useEffect(() => {
      setInternalRange(initRangeValue(value));
    }, [value]);

    const handleOnChangeSelected = date => {
      let dateRange;

      if (isPickingStartDate) {
        if (date > internalRange.end) {
          dateRange = new DateRange(internalRange.end, date);
        } else {
          dateRange = new DateRange(date, internalRange.end);
        }
      } else {
        if (date < internalRange.start) {
          dateRange = new DateRange(date, internalRange.end);
        } else {
          dateRange = new DateRange(internalRange.start, date);
        }
      }

      setInternalRange(dateRange);
      setPickingStartDate(!isPickingStartDate);
    };

    const handleOnClickOkay = e => {
      e.stopPropagation();
      onChange && onChange([internalRange.start, internalRange.end]);
    };

    const canCalendarLeftNavigatePrevious = true;
    const canCalendarRightNavigateNext = true;

    const canCalendarLeftNavigateNext = () => {
      if (selectModeLeft === CalendarWithNavigator.SelectMode.Month) {
        const left = new Date(getYear(calendarDateLeft), getMonth(calendarDateLeft));
        const right = addYears(
          new Date(getYear(calendarDateRight), getMonth(calendarDateRight)),
          -1
        );
        return isBefore(left, right);
      }

      const left = new Date(getYear(calendarDateLeft), getMonth(calendarDateLeft));
      const right = addMonths(
        new Date(getYear(calendarDateRight), getMonth(calendarDateRight)),
        -1
      );
      return isBefore(left, right);
    };

    const canCalendarRightNavigatePrevious = () => {
      if (selectModeRight === CalendarWithNavigator.SelectMode.Month) {
        const left = addYears(new Date(getYear(calendarDateLeft), getMonth(calendarDateLeft)), 1);
        const right = new Date(getYear(calendarDateRight), getMonth(calendarDateRight));
        return isAfter(right, left);
      }

      const left = addMonths(new Date(getYear(calendarDateLeft), getMonth(calendarDateLeft)), 1);
      const right = new Date(getYear(calendarDateRight), getMonth(calendarDateRight));
      return isAfter(right, left);
    };

    const canSelectMonthLeft = month => {
      const left = new Date(getYear(calendarDateLeft), month);
      const right = addMonths(
        new Date(getYear(calendarDateRight), getMonth(calendarDateRight)),
        -1
      );
      return isBefore(left, right);
    };

    const canSelectYearLeft = year => {
      const left = year;
      const right = getYear(calendarDateRight);
      return left <= right;
    };

    const canSelectMonthRight = month => {
      const left = addMonths(new Date(getYear(calendarDateLeft), getMonth(calendarDateLeft)), 1);
      const right = new Date(getYear(calendarDateRight), month);
      return isAfter(right, left);
    };

    const canSelectYearRight = year => {
      const left = getYear(calendarDateLeft);
      const right = year;
      return right >= left;
    };

    const handleOnClickReset = () => {
      const range = initRangeValue(value);
      setInternalRange(range);
      setCalendarDateLeft(initCalendarLeft(range));
      setCalendarDateRight(initCalendarRight(internalRange));
      setSelectModeLeft(CalendarWithNavigator.SelectMode.Day);
      setSelectModeRight(CalendarWithNavigator.SelectMode.Day);
      setPickingStartDate(true);
      onReset && onReset();
    };

    return (
      <CalendarThemeFlex row ref={ref} variant={variant} variantComponent="rootRange">
        <Flex.Item flex={0}>
          <Flex column gutter="medium" alignItems="center">
            <Flex.Item flex={1}>
              <CalendarDateHeader
                date={internalRange.start}
                title="From"
                EmptyComponent={<HeaderEmptyDate variant={variant} message="Start date" />}
                variant={variant}
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <CalendarDateHeader
                date={internalRange.end}
                title="To"
                EmptyComponent={<HeaderEmptyDate variant={variant} message="End date" />}
                variant={variant}
              />
            </Flex.Item>
          </Flex>
          <Divider height={1} color="grey.100" />
        </Flex.Item>
        <Flex.Item flex={1}>
          <Flex column>
            <Flex.Item flex={1} style={{ overflowY: 'auto', maxHeight: 250 }}>
              <CalendarWithNavigator
                calendarDate={calendarDateLeft}
                canNavigateNext={canCalendarLeftNavigateNext()}
                canNavigatePrevious={canCalendarLeftNavigatePrevious}
                isDisabled={isDisabled}
                onChangeCalendarDate={setCalendarDateLeft}
                onChangeSelected={handleOnChangeSelected}
                onChangeSelectMode={setSelectModeLeft}
                canSelectMonth={canSelectMonthLeft}
                canSelectYear={canSelectYearLeft}
                selected={internalRange}
                selectMode={selectModeLeft}
                variant={variant}
              />
            </Flex.Item>
            <Flex.Item flex={0}>
              <Divider width={1} color="grey.100" vertical />
            </Flex.Item>
            <Flex.Item flex={1} style={{ overflowY: 'auto', maxHeight: 250 }}>
              <CalendarWithNavigator
                calendarDate={calendarDateRight}
                canNavigateNext={canCalendarRightNavigateNext}
                canNavigatePrevious={canCalendarRightNavigatePrevious()}
                isDisabled={isDisabled}
                onChangeCalendarDate={setCalendarDateRight}
                onChangeSelected={handleOnChangeSelected}
                onChangeSelectMode={setSelectModeRight}
                canSelectMonth={canSelectMonthRight}
                canSelectYear={canSelectYearRight}
                selectMode={selectModeRight}
                selected={internalRange}
                variant={variant}
              />
            </Flex.Item>
          </Flex>
        </Flex.Item>
        <Flex.Item flex={0}>
          {(onChange || onCancel || onReset) && (
            <>
              <Divider height={1} color="grey.100" />
              <Flex column gutter="xsmall" p="small" alignItems="center">
                {onReset && (
                  <Flex.Item flex={1}>
                    <Button
                      shape="soft"
                      fill="ghost"
                      size="small"
                      baseColor="grey"
                      mr="small"
                      onClick={handleOnClickReset}
                    >
                      RESET
                    </Button>
                  </Flex.Item>
                )}
                {!onReset && <Flex.Item flex={1} />}
                {onCancel && (
                  <Flex.Item flex={0}>
                    <Button
                      shape="soft"
                      fill="ghost"
                      size="small"
                      baseColor="grey"
                      mr="small"
                      onClick={onCancel}
                    >
                      CANCEL
                    </Button>
                  </Flex.Item>
                )}
                {onChange && (
                  <Flex.Item flex={0}>
                    <Button
                      shape="soft"
                      fill="solid"
                      size="small"
                      baseColor="primary"
                      onClick={handleOnClickOkay}
                    >
                      OK
                    </Button>
                  </Flex.Item>
                )}
              </Flex>
            </>
          )}
        </Flex.Item>
      </CalendarThemeFlex>
    );
  }
);

CalendarRange.displayName = 'CalendarRange';

CalendarRange.propTypes = {
  isDisabled: PropTypes.bool,
  onReset: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  /**
   * The input value is an array consisting of two Date elements - start and end date.
   */
  value: PropTypes.arrayOf(Date),
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

CalendarRange.defaultProps = {
  isDisabled: false,
  variant: 'default',
  canReset: true,
};

export default CalendarRange;
