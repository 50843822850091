import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import usePortal from './_internal/usePortal';

/**
 * @example
 * <Portal>
 *   <p>I'm in a portal</p>
 * </Portal>
 */
const Portal = ({ id, children }) => {
  const target = usePortal(id);
  return createPortal(children, target);
};

Portal.propTypes = {
  /**
   * Id of the portal dom element, if not provided a unique random id will be used.
   */
  id: PropTypes.string.isRequired,
  /**
   * Children to exist in the portal.
   */
  children: PropTypes.node,
};

export default Portal;
