import PropTypes from 'prop-types';
import React from 'react';
import NodeModel from '../model/NodeModel';
import Root from './styled/Root';
import TreeNode from './TreeNode';

const TreeNodes = ({
  model,
  name,
  nodeChildren,
  disabled,
  readonly,
  nodeId,
  nodes,
  onCheck,
  onExpand,
  parent,
  renderNode,
  variant,
  variantCheckbox,
  role,
}) => {
  /**
   * Recursively iterate over the nodes render the tree.
   */
  const treeNodes = nodes.map(node => {
    const flatNode = model.getFlatNode(node);

    const children = flatNode.isParent ? (
      <TreeNodes
        nodes={flatNode.children}
        model={model}
        name={name}
        disabled={disabled}
        readonly={readonly}
        onExpand={onExpand}
        onCheck={onCheck}
        nodeId={nodeId}
        nodeChildren={nodeChildren}
        parent={parent}
        renderNode={renderNode}
        variant={variant}
        variantCheckbox={variantCheckbox}
        role="group"
      />
    ) : null;

    const isParentExpanded = parent ? model.getFlatNode(parent).isExpanded : true;

    if (!isParentExpanded) {
      return null;
    }

    const handleOnExpandClose = () => {
      onExpand(node, false);
    };

    const handleOnExpandOpen = () => {
      onExpand(node, true);
    };

    const handleOnExpandToggle = () => {
      onExpand(node);
    };

    const handleOnFocusNode = nextNode => {
      if (!nextNode) {
        return;
      }
      const makeSelector = node => `div[data-node-id="${node.id}"]`;
      const nextDomNode = document.querySelector(makeSelector(nextNode));
      if (nextDomNode) {
        nextDomNode.focus();
      }
    };

    const handleOnFocusPrevious = () => {
      handleOnFocusNode(model.getFocusPreviousFlatNode(node));
    };

    const handleOnFocusNext = () => {
      handleOnFocusNode(model.getFocusNextFlatNode(node));
    };

    const handleOnCheck = () => {
      onCheck(node);
    };

    return (
      <TreeNode
        disabled={disabled}
        readonly={readonly}
        checked={flatNode.isChecked}
        expanded={flatNode.isExpanded}
        indeterminate={flatNode.isSomeDescendantsChecked}
        parent={flatNode.isParent}
        key={flatNode.id}
        leaf={flatNode.isLeaf}
        name={name}
        node={node}
        onCheck={handleOnCheck}
        onExpandClose={handleOnExpandClose}
        onExpandOpen={handleOnExpandOpen}
        onExpandToggle={handleOnExpandToggle}
        onFocusNext={handleOnFocusNext}
        onFocusPrevious={handleOnFocusPrevious}
        renderNode={renderNode}
        value={flatNode.value}
        label={flatNode.label}
        variant={variant}
        variantCheckbox={variantCheckbox}
      >
        {children}
      </TreeNode>
    );
  });

  return (
    <Root variant={variant} variantComponent="root" role={role}>
      {treeNodes}
    </Root>
  );
};

TreeNodes.propTypes = {
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  model: PropTypes.instanceOf(NodeModel),
  nodeChildren: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  name: PropTypes.string,
  nodeId: PropTypes.func.isRequired,
  nodes: PropTypes.array,
  onCheck: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  parent: PropTypes.object,
  renderNode: PropTypes.func.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  variantCheckbox: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

TreeNodes.defaultProps = {};

export default TreeNodes;
