import { get } from 'styled-system';

const getColor = (n, scale) => {
  const color = get(scale, n);

  // If this matches a valid color name, but no shade is specified, return the default shade (500)
  // TODO: At some point, we may want to allow configuration of a custom "default" shade for a given color in the color scheme.
  if (color && color['500'] && color['500'].hex) {
    return color['500'].hex;
  }

  if (color && color.hex) {
    return color.hex;
  }

  if (color) {
    return color;
  }

  return n;
};

export default getColor;
