import getYear from 'date-fns/getYear';

const currentYear = getYear(new Date());
const startRange = currentYear - 100;
const endRange = currentYear + 100;
const years = [...Array(endRange - startRange).keys()].map(x => startRange + x);

const getPickYearRange = () => years;

export default getPickYearRange;
