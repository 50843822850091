import { compose } from 'styled-system';
import borderBottomWidth from './borderBottomWidth';
import borderLeftWidth from './borderLeftWidth';
import borderRightWidth from './borderRightWidth';
import borderTopWidth from './borderTopWidth';
import borderWidth from './borderWidth';

export default compose(
  borderWidth,
  borderTopWidth,
  borderRightWidth,
  borderBottomWidth,
  borderLeftWidth
);

export { borderTopWidth, borderRightWidth, borderBottomWidth, borderLeftWidth };
