import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import TreeNodes from './_internal/components/TreeNodes';
import NodeModel from './_internal/model/NodeModel';

const CheckboxTree = ({
  id,
  name,
  nodes,
  value,
  readonly,
  disabled,
  onChange,
  onModelChange,
  onExpand,
  expanded,
  nodeId,
  nodeLabel,
  nodeValue,
  nodeChildren,
  renderNode,
  variant,
  variantCheckbox,
}) => {
  const modelRef = useRef(
    new NodeModel({ nodes, value, expanded, nodeId, nodeChildren, nodeLabel, nodeValue })
  );

  useEffect(() => {
    modelRef.current = new NodeModel({
      nodes,
      value,
      expanded,
      nodeId,
      nodeChildren,
      nodeLabel,
      nodeValue,
    });

    onModelChange && onModelChange(modelRef.current);

    // Do not add onModelChange to deps array as it will force a re-render
    // because we are not forcing the caller to memoize the handler.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, expanded, value, nodeId, nodeChildren, nodeLabel, nodeValue]);

  const handleOnExpand = useCallback(
    (node, expand) => {
      if (expand !== undefined) {
        modelRef.current.toggleExpanded(node, expand);
      } else {
        modelRef.current.toggleExpanded(node);
      }
      onExpand(modelRef.current.getExpandedValues());
    },
    [onExpand]
  );

  const handleOnCheck = useCallback(
    node => {
      modelRef.current.toggleChecked(node);
      onChange && onChange(modelRef.current.getCheckedValues());
    },
    [onChange]
  );

  return (
    <TreeNodes
      disabled={disabled}
      id={id}
      model={modelRef.current}
      name={name}
      nodeChildren={nodeChildren}
      nodeId={nodeId}
      nodes={nodes}
      onCheck={handleOnCheck}
      onExpand={handleOnExpand}
      readonly={readonly}
      renderNode={renderNode}
      role="tree"
      variant={variant}
      variantCheckbox={variantCheckbox}
    />
  );
};

CheckboxTree.propTypes = {
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  expanded: PropTypes.array,
  id: PropTypes.string,
  name: PropTypes.string,
  nodeChildren: PropTypes.func.isRequired,
  nodeId: PropTypes.func.isRequired,
  nodeLabel: PropTypes.func.isRequired,
  nodeValue: PropTypes.func,
  nodes: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  /**
   * Callback when internal node model has changed.
   */
  onModelChange: PropTypes.func,
  onExpand: PropTypes.func,
  renderNode: PropTypes.func.isRequired,
  value: PropTypes.array,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  variantCheckbox: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

CheckboxTree.defaultProps = {
  expanded: undefined,
  id: undefined,
  onChange: undefined,
  onExpand: undefined,
  value: [],
  variant: 'default',
  nodeValue: undefined,
  variantCheckbox: 'xsmall',
};

export default CheckboxTree;
