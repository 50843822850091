import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { isEnterKey, isSpaceKey } from '../../../_internal/isKey';
import AnimatedIcon from './AnimatedIcon';
import { CheckboxRoot, Input, isRadio, RadioRoot } from './index';

const Choice = ({
  ariaLabel,
  ariaLabeledBy,
  id,
  isChecked,
  isDisabled,
  isIndeterminate,
  isReadOnly,
  name,
  onChange,
  tabIndex,
  type,
  value,
  variant,
}) => {
  const inputRef = useRef(null);
  const isControlDisabled = isReadOnly || isDisabled;
  const isControlEnabled = !isControlDisabled;

  const handleOnClickRoot = e => {
    if (e.target.checked === undefined) {
      e.stopPropagation();
      e.preventDefault();
      inputRef.current.click();
    }
  };

  const handleOnChange = () => {
    if (isRadio(type)) {
      // only call onChange when going on >>> off.
      onChange && !isChecked && onChange(!isChecked, value);
    } else {
      // always call onChange.
      onChange && onChange(!isChecked, value);
    }
  };

  const handleKeyDown = e => {
    if (isEnterKey(e) || isSpaceKey(e)) {
      handleOnClickRoot(e);
    }
  };

  const Root = isRadio(type) ? RadioRoot : CheckboxRoot;

  return (
    <Root
      aria-checked={isChecked ? 'true' : 'false'}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabeledBy}
      isDisabled={isControlDisabled}
      onClick={isControlEnabled ? handleOnClickRoot : undefined}
      onKeyDown={isControlEnabled ? handleKeyDown : undefined}
      role="checkbox"
      tabIndex={tabIndex}
      variant={variant}
      variantComponent="root"
    >
      <AnimatedIcon
        type={type}
        isChecked={isChecked || false}
        isIndeterminate={isIndeterminate || false}
        isDisabled={isControlDisabled || false}
        variant={variant}
      />
      <Input
        id={id}
        isChecked={isChecked}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        name={name}
        onChange={isControlEnabled ? handleOnChange : undefined}
        ref={inputRef}
        type={type}
        value={value}
      />
    </Root>
  );
};

Choice.propTypes = {
  /**
   * Sets aria-label attribute.
   */
  ariaLabel: PropTypes.string,
  /**
   * Sets aria-labelledby attribute.
   */
  ariaLabeledBy: PropTypes.string,
  /**
   * The input id.
   */
  id: PropTypes.string,
  /**
   * Set the checked attribute on the input.
   */
  isChecked: PropTypes.bool,
  /**
   * Disables the choice.
   */
  isDisabled: PropTypes.bool,
  /**
   * Set the checkbox as indeterminate.
   */
  isIndeterminate: PropTypes.bool,
  /**
   * Set the checkbox control as read only.
   */
  isReadOnly: PropTypes.bool,
  /**
   * The input name.
   */
  name: PropTypes.string,
  /**
   * Sets the onChange handler on input.
   */
  onChange: PropTypes.func,
  /**
   * Tab index.
   */
  tabIndex: PropTypes.number,
  /**
   * Sets the type on the input.
   */
  type: PropTypes.oneOf(['checkbox', 'radio']),
  /**
   * Sets the value on input.
   */
  value: PropTypes.any,
  /**
   * Sets the theme variant.
   */
  variant: PropTypes.string,
};

Choice.defaultProps = {
  id: undefined,
  isChecked: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  name: undefined,
  onChange: undefined,
  tabIndex: undefined,
  value: undefined,
  variant: 'medium',
};

export default Choice;
