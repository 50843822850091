import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  stylesForDefaultState,
  stylesForFocusState,
  stylesForHoverState,
} from '../../../_internal/theme/stylesFor';

const RadioRoot = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  border-color: transparent;

  ${stylesForDefaultState('radio')}

  &:hover {
    ${stylesForHoverState('radio')}
  }

  &:focus {
    outline: 0;
    ${stylesForFocusState('radio')}
  }
`;

RadioRoot.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RadioRoot;
