/**
 * Calls the user supplied "renderOption" function to generate the
 * option label falling back by just return the plain option.
 *
 * @param renderOption the "renderOption" prop.
 * @param option the option item data.
 * @param index the index
 * @returns {string} the option label.
 */
const optionLabel = (renderOption, option, index) => {
  if (renderOption) {
    return renderOption({ item: option, node: option, index });
  }
  return option;
};

export default optionLabel;
