import React from 'react'
import Svg from './Svg'
export const SvgClock = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <g>
      <path d="M12 0a12 12 0 1012 12A12.018 12.018 0 0012 0zm0 20.138A8.138 8.138 0 1120.138 12 8.147 8.147 0 0112 20.138z" />
      <path d="M16.834 11.717h-3.615V6.521a1.184 1.184 0 00-2.368 0v7.564h5.983a1.184 1.184 0 100-2.368z" />
    </g>
  </Svg>
))
SvgClock.isIcon = true
export default SvgClock
