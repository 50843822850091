import React from 'react'
import Svg from './Svg'
export const SvgChevronRightDouble = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M9.444 11.996L.003 2.555 2.557 0l11.997 11.996L2.553 24 0 21.444z" />
    <path d="M18.89 11.996L9.449 2.555 12.003 0 24 11.996 11.999 24l-2.553-2.556z" />
  </Svg>
))
SvgChevronRightDouble.isIcon = true
export default SvgChevronRightDouble
