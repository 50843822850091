import React from 'react'
import Svg from './Svg'
export const SvgCameraPlus = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M9.535 2c-.696 0-1.344.308-1.824.87a.551.551 0 00-.072.099L6.246 5.164c-.118.13-.286.268-.46.268v-.428a.856.856 0 00-.856-.858H3.643a.858.858 0 00-.858.858v.428H2.57A2.575 2.575 0 000 8.004v10.281a2.574 2.574 0 002.57 2.572h18.86A2.574 2.574 0 0024 18.285v-7.797a7.56 7.56 0 01-6.846 4.354 7.56 7.56 0 01-.687-.031 5.13 5.13 0 01-4.465 2.619c-3.959 0-6.434-4.286-4.455-7.715a5.066 5.066 0 012.062-1.979 7.56 7.56 0 01-.013-.453A7.56 7.56 0 0111.75 2H9.535zm.406 7.545a3.428 3.428 0 00-1.369 2.74 3.428 3.428 0 003.43 3.43 3.428 3.428 0 002.678-1.29 7.56 7.56 0 01-4.739-4.88z" />
    <path d="M16.886 1.249a6 6.001 0 00-5.894 6 6 6.001 0 006.001 6 6 6.001 0 005.999-6 6 6.001 0 00-5.999-6 6 6.001 0 00-.107 0zm-.637 1.45h1.6v3.62h3.615v1.599h-3.616v3.245H16.25V7.918H13v-1.6h3.25z" />
  </Svg>
))
SvgCameraPlus.isIcon = true
export default SvgCameraPlus
