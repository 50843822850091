/**
 * These are the default ui-kit fonts and are considered "web safe fonts".
 */
export const DEFAULT_FONT_SANS = '"Trebuchet MS", Helvetica, sans-serif';
export const DEFAULT_FONT_SERIF = '"Times New Roman", Times, serif';
export const DEFAULT_FONT_MONOSPACE = '"Courier New", Courier, monospace';

/**
 * Creates the fonts theme prop.
 */
const createFonts = ({ fonts = {} } = {}) => {
  return {
    sans: fonts.sans || DEFAULT_FONT_SANS,
    serif: fonts.serif || DEFAULT_FONT_SERIF,
    monospace: fonts.monospace || DEFAULT_FONT_MONOSPACE,
  };
};

export default createFonts;
