import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box } from '../../../Box';
import { Divider } from '../../../Display';
import { CalendarThemeBox } from '../_internal/base';
import CalendarWithNavigator from '../_internal/CalendarWithNavigator/CalendarWithNavigator';
import CalendarDateHeader from '../_internal/Header/CalendarDateHeader';

const Container = styled(CalendarThemeBox)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Body = styled(Box)`
  overflow-y: auto;
  max-height: 280px;
  flex: 1;
`;

/**
 * Monthly View component to view date on a calendar.
 */
const Calendar = React.forwardRef(({ selected, variant, onChange, isDisabled }, ref) => {
  const [dateInView, setDateInView] = useState(selected || new Date());

  const handleOnChangeSelected = date => {
    onChange && onChange(date);
  };

  return (
    <Container ref={ref} variant={variant} variantComponent="root">
      <CalendarDateHeader date={selected || new Date()} />
      <Divider height={1} color="grey.100" />
      <Body>
        <CalendarWithNavigator
          calendarDate={dateInView}
          isDisabled={isDisabled}
          onChangeCalendarDate={setDateInView}
          onChangeSelected={handleOnChangeSelected}
          selected={selected}
          variant={variant}
        />
      </Body>
    </Container>
  );
});

Calendar.displayName = 'Calendar';

Calendar.propTypes = {
  /**
   * Calendar theme variant.
   */
  variant: PropTypes.string,
  /**
   * Selected date.
   */
  selected: PropTypes.instanceOf(Date),
  /**
   * Called when a selected date changes. The date will be passed in to the callback.
   */
  onChange: PropTypes.func,
  /**
   * Called when keydown event occurs.
   */
  onKeyDown: PropTypes.func,
  /**
   * Disabled.
   */
  isDisabled: PropTypes.bool,
};

Calendar.defaultProps = {
  isDisabled: false,
  onChange: undefined,
  onKeyDown: undefined,
  selected: undefined,
  variant: 'default',
};

export default Calendar;
