import PropTypes from 'prop-types';
import React from 'react';
import Choice from '../_internal/Choice';

const Checkbox = ({
  id,
  name,
  isChecked,
  isIndeterminate,
  isReadOnly,
  onChange,
  isDisabled,
  tabIndex,
  value,
  variant,
}) => {
  return (
    <Choice
      onChange={onChange}
      isChecked={isChecked}
      isDisabled={isDisabled}
      isIndeterminate={isIndeterminate}
      value={value}
      variant={variant}
      id={id}
      isReadOnly={isReadOnly}
      tabIndex={tabIndex}
      name={name}
      type="checkbox"
    />
  );
};

Checkbox.propTypes = {
  /**
   * The input id.
   */
  id: PropTypes.string,
  /**
   * Set the checked attribute on the checkbox input.
   */
  isChecked: PropTypes.bool,
  /**
   * Disables the checkbox control.
   */
  isDisabled: PropTypes.bool,
  /**
   * Set the checkbox control as indeterminate.
   */
  isIndeterminate: PropTypes.bool,
  /**
   * Set the checkbox control as read only.
   */
  isReadOnly: PropTypes.bool,
  /**
   * The input name.
   */
  name: PropTypes.string,
  /**
   * Sets the onChange handler on checkbox input.
   */
  onChange: PropTypes.func,
  /**
   * Sets the value on checkbox input.
   */
  value: PropTypes.any,
  /**
   * Tab index.
   */
  tabIndex: PropTypes.number,
  /**
   * Sets the theme variant.
   */
  variant: PropTypes.string,
};

Checkbox.defaultProps = {
  id: undefined,
  isChecked: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  name: undefined,
  tabIndex: 0,
  onChange: undefined,
  value: undefined,
  variant: 'medium',
};

export default Checkbox;
