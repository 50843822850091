import styled from "@emotion/styled";
import { getThemeColor } from "@modernary/ui-kit-styled-system";
import PropTypes from "prop-types";
import { DEFAULT_BG_COLOR } from "../../Display/Tooltip/_internal/constants";

const arrowColor = props => {
  return getThemeColor(props.color)(props);
};

const Arrow = styled("div")`
  position: absolute;
  width: 10px;
  height: 10px;
  &[data-placement*="bottom"] {
    top: 0;
    left: 0;
    margin-top: -8px;
    margin-left: -8px;
    width: 0px;
    height: 8px;
    &::before {
      margin-left: -5px;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent ${arrowColor} transparent;
    }
  }
  &[data-placement*="top"] {
    bottom: 0;
    left: 0;
    margin-bottom: -8px;
    width: 10px;
    height: 8px;
    &::before {
      margin-left: -3px;
      border-width: 8px 8px 0 8px;
      border-color: ${arrowColor} transparent transparent transparent;
    }
  }
  &[data-placement*="right"] {
    left: 0;
    margin-left: -6px;
    margin-top: -8px;
    height: 8px;
    width: 8px;
    &::before {
      border-width: 8px 8px 8px 0;
      border-color: transparent ${arrowColor} transparent transparent;
    }
  }
  &[data-placement*="left"] {
    right: 0;
    margin-right: -8px;
    margin-top: -8px;
    height: 8px;
    width: 8px;
    &::before {
      border-width: 8px 0 8px 8px;
      border-color: transparent transparent transparent ${arrowColor};
    }
  }
  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

Arrow.displayName = "Arrow";

Arrow.propTypes = {
  color: PropTypes.string
};

Arrow.defaultProps = {
  color: DEFAULT_BG_COLOR
};

export default Arrow;
