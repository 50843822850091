import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Manager, Popper as ReactPopper, Reference } from 'react-popper';
import { animated, interpolate } from 'react-spring/renderprops.cjs';
import { Z_INDEX_POPPER } from '../../_internal/constants';
import randomId from '../../_internal/utils/randomId';
import { Absolute } from '../../Box';
import ScaleTransition from '../../_internal/transitions/ScaleTransition';
import Backdrop from '../Backdrop';
import Portal from '../Portal';

const Popper = ({
  isOpen,
  isUsePortal,
  offsetX,
  offsetY,
  onOpen,
  onRequestClose,
  overlayOpacity,
  placement,
  renderPopper,
  renderReference,
  width,
  zIndex,
}) => {
  const portalId = useRef(randomId());
  const [internalIsOpen, setInternalIsOpen] = useState(isOpen);

  useEffect(() => {
    setInternalIsOpen(isOpen);
  }, [isOpen]);

  useLayoutEffect(() => {
    if (isOpen) {
      onOpen && onOpen();
    }
  }, [internalIsOpen, isOpen, onOpen]);

  const handleOnPortalClick = e => {
    onRequestClose && onRequestClose(e);
  };

  const modifiers = {
    offset: {
      enabled: true,
      offset: `${offsetX},${offsetY}`,
    },
  };

  return (
    <Manager>
      <Reference>{({ ref }) => renderReference({ ref })}</Reference>
      <ScaleTransition isActive={internalIsOpen}>
        {({ scale, opacity }) => (
          <ReactPopper placement={placement} modifiers={modifiers}>
            {({ ref, placement, style: { top, left, position, transform } }) => {
              const popperContents = (
                <Absolute
                  ref={ref}
                  as={animated.div}
                  data-placement={placement}
                  style={{
                    zIndex: zIndex,
                    top: top,
                    left: left,
                    width: width,
                    position: position,
                    opacity: opacity,
                    transform: interpolate([scale], s => `${transform} scale(${s})`),
                  }}
                >
                  {renderPopper({})}
                </Absolute>
              );

              if (isUsePortal) {
                return (
                  <Portal id={portalId.current}>
                    {popperContents}
                    <Backdrop
                      onClick={handleOnPortalClick}
                      opacity={overlayOpacity}
                      zIndex={zIndex - 1}
                    />
                  </Portal>
                );
              }

              return popperContents;
            }}
          </ReactPopper>
        )}
      </ScaleTransition>
    </Manager>
  );
};

Popper.propTypes = {
  /**
   * Render function to render the reference component to which the popper will reference it
   * location to. The render function accepts a 'ref' prop which you need to add to your component.
   */
  renderReference: PropTypes.func.isRequired,
  /**
   * Render function to render the popper component. As ref prop will be passed to your function.
   */
  renderPopper: PropTypes.func.isRequired,
  /**
   * Flag to set popper open or closed.
   */
  isOpen: PropTypes.bool,
  /**
   * Renders the popper in a Portal.  See https://bit.ly/2qdP9tD for more info.
   */
  isUsePortal: PropTypes.bool,
  /**
   * X-axis popper placement offset.
   */
  offsetX: PropTypes.number,
  /**
   * Y-axis popper placement offset.
   */
  offsetY: PropTypes.number,
  /**
   * Called when popper has opened.
   */
  onOpen: PropTypes.func,
  /**
   * Called when popper is requested to be closed.
   */
  onRequestClose: PropTypes.func,
  /**
   * Overlay opacity.
   */
  overlayOpacity: PropTypes.number,
  /**
   * Where to place the popper in relation to the reference component.
   */
  placement: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
    'top-start',
    'right-start',
    'bottom-start',
    'left-start',
    'top-end',
    'right-end',
    'bottom-end',
    'left-end',
  ]),
  /**
   * Sets the popper width.
   */
  width: PropTypes.string,
  /**
   * Z-index
   */
  zIndex: PropTypes.number.isRequired,
};

Popper.defaultProps = {
  isOpen: undefined,
  isUsePortal: undefined,
  offsetX: 0,
  offsetY: 12,
  onOpen: undefined,
  onRequestClose: undefined,
  overlayOpacity: 0,
  placement: 'bottom-start',
  width: undefined,
  zIndex: Z_INDEX_POPPER,
};

export default Popper;
