/**
 * Shadows.
 *
 * TODO Andrew needs to review and confirm values below.
 */
const createShadows = () => ({
  xxxsmall: '0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24)',
  xxsmall: '0 3px 6px rgba(0,0,0,0.16),0 3px 6px rgba(0,0,0,0.23)',
  xsmall: '0 5px 10px rgba(0,0,0,0.19),0 6px 6px rgba(0,0,0,0.23)',
  small: '0 8px 14px rgba(0,0,0,0.25),0 10px 10px rgba(0,0,0,0.22)',
  medium: '0 12px 20px rgba(0,0,0,0.30),0 15px 12px rgba(0,0,0,0.22)',
  large: '0 14px 24px rgba(0,0,0,0.30),0 15px 12px rgba(0,0,0,0.22)',
  xlarge: '0 18px 24px rgba(0,0,0,0.30),0 15px 12px rgba(0,0,0,0.22)',
  xxlarge: '0 24px 32px rgba(0,0,0,0.30),0 15px 12px rgba(0,0,0,0.22)',
  xxxlarge: '0 48px 60px rgba(0,0,0,0.30),0 15px 12px rgba(0,0,0,0.22)',
});

export default createShadows;
