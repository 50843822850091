import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { isEscapeKey } from '../../../_internal/isKey';
import { Popover } from '../../../Util';
import { CalendarRange } from '../../Calendar';
import Calendar from '../../Calendar/Calendar/Calendar';

const DatePicker = ({
  children,
  isCloseOnChange,
  isDateRange,
  onAfterClose,
  onChange,
  backdropOpacity,
  placement,
  value,
  variant,
}) => {
  const [selectedDate, setSelectedDate] = useState(value);
  const [isOpen, setIsOpen] = useState(isOpen);

  const calendarRef = useRef(null);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const handleClosePopper = () => {
    setIsOpen(false);
    onAfterClose && onAfterClose();
  };

  const handleClickDate = date => {
    setSelectedDate(date);
    if (isCloseOnChange) {
      handleClosePopper();
    }
    onChange && onChange(date);
  };

  const handleOnCalendarKeyDown = e => {
    if (isEscapeKey(e)) {
      e.stopPropagation();
      handleClosePopper();
    }
  };

  const handleOnReset = () => {};

  const Content = isDateRange ? (
    <CalendarRange
      onChange={handleClickDate}
      onCancel={handleClosePopper}
      onReset={handleOnReset}
      onKeyDown={handleOnCalendarKeyDown}
      ref={calendarRef}
      value={value}
      variant={variant}
    />
  ) : (
    <Calendar
      onChange={handleClickDate}
      onKeyDown={handleOnCalendarKeyDown}
      ref={calendarRef}
      selected={selectedDate}
      variant={variant}
    />
  );

  return (
    <Popover
      backdropOpacity={backdropOpacity}
      isOpen={isOpen}
      placement={placement}
      onRequestClose={handleClosePopper}
      PopoverComponent={Content}
      shadow="xxsmall"
      radius="xxxsmall"
    >
      {children({
        open: () => setIsOpen(true),
        close: handleClosePopper,
      })}
    </Popover>
  );
};

DatePicker.propTypes = {
  /**
   * Child should be a function which accepts `ref`, `open`, & `close`.
   */
  children: PropTypes.func,
  /**
   * The style variant of the calendar located under the 'calendar' property in the theme.
   */
  variant: PropTypes.string,
  /**
   * The selected date value.
   */
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.arrayOf(Date)]),
  /**
   * Callback when date value changes.
   */
  onChange: PropTypes.func,
  /**
   * Callback when popper has been closed.
   */
  onAfterClose: PropTypes.func,
  /**
   * Close calendar when a date has been chosen.
   */
  isCloseOnChange: PropTypes.bool,
  /**
   * Is the date picker intended for picking date ranges.
   */
  isDateRange: PropTypes.bool.isRequired,
  /**
   * Overlay opacity.
   */
  backdropOpacity: PropTypes.number,
  /**
   * Where to place the popper in relation to the reference component.
   */
  placement: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
    'top-start',
    'right-start',
    'bottom-start',
    'left-start',
    'top-end',
    'right-end',
    'bottom-end',
    'left-end',
  ]),
};

DatePicker.defaultProps = {
  children: () => null,
  isCloseOnChange: true,
  isDateRange: false,
  onChange: undefined,
  value: undefined,
  variant: undefined,
  placement: 'right',
  overlayOpacity: undefined,
};

export default DatePicker;
