import tinycolor from 'tinycolor2';
import asColorObject from './internal/asColorObject';

/**
 * Material design algorithms from here:
 *
 * https://github.com/mbitson/mcg/blob/ce27a563a300db97fa6f87b9dbfcdde0d9e6e9e0/scripts/controllers/ColorGeneratorCtrl.js
 */

const algorithm1 = hex => {
  return {
    50: asColorObject(tinycolor(hex).lighten(52), '50'),
    100: asColorObject(tinycolor(hex).lighten(37), '100'),
    200: asColorObject(tinycolor(hex).lighten(26), '200'),
    300: asColorObject(tinycolor(hex).lighten(12), '300'),
    400: asColorObject(tinycolor(hex).lighten(6), '400'),
    500: asColorObject(tinycolor(hex), '500'),
    600: asColorObject(tinycolor(hex).darken(6), '600'),
    700: asColorObject(tinycolor(hex).darken(12), '700'),
    800: asColorObject(tinycolor(hex).darken(18), '800'),
    900: asColorObject(tinycolor(hex).darken(24), '900'),
    A100: asColorObject(
      tinycolor(hex)
        .lighten(50)
        .saturate(30),
      'A100'
    ),
    A200: asColorObject(
      tinycolor(hex)
        .lighten(30)
        .saturate(30),
      'A200'
    ),
    A400: asColorObject(
      tinycolor(hex)
        .lighten(10)
        .saturate(15),
      'A400'
    ),
    A700: asColorObject(
      tinycolor(hex)
        .lighten(5)
        .saturate(5),
      'A700'
    ),
  };
};

export default algorithm1;
