import { system } from 'styled-system';
import { getPxAbsolute } from '../internal';

const borderLeftWidth = system({
  borderLeftWidth: {
    property: 'borderLeftWidth',
    transform: getPxAbsolute,
  },
});

export default borderLeftWidth;
