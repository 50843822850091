import React from 'react'
import Svg from './Svg'
export const SvgPlusCircle = React.forwardRef(({ size, ...props }, svgRef) => (
  <Svg viewBox="0 0 24 24" height={size} width={size} fill="currentColor" ref={svgRef} {...props}>
    <path d="M4.5 10.5h15v3h-15z" />
    <path d="M10.5 4.5h3v15h-3z" />
    <path d="M24 12c0 3.206-1.426 6.396-3.515 8.485C18.396 22.574 15.206 24 12 24c-3.206 0-6.396-1.426-8.485-3.515C1.426 18.396 0 15.206 0 12c0-3.206 1.426-6.396 3.515-8.485C5.604 1.426 8.794 0 12 0c3.206 0 6.396 1.426 8.485 3.515C22.574 5.604 24 8.794 24 12zm-5.636-6.364C16.653 3.925 14.593 3 12 3s-4.653.925-6.364 2.636C3.925 7.347 3 9.407 3 12s.925 4.653 2.636 6.364C7.347 20.075 9.407 21 12 21s4.653-.925 6.364-2.636C20.075 16.653 21 14.593 21 12s-.925-4.653-2.636-6.364z" />
  </Svg>
))
SvgPlusCircle.isIcon = true
export default SvgPlusCircle
