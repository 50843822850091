import React from 'react';
import { Transition } from 'react-spring/renderprops.cjs';
import PropTypes from 'prop-types';

const springConfig = {
  duration: 150,
};

const ScaleTransition = ({ isActive, children }) => (
  <Transition
    native
    items={isActive}
    config={springConfig}
    from={{ opacity: 0, scale: 0.95 }}
    enter={{ opacity: 1, scale: 1 }}
    leave={{ opacity: 0, scale: 1.05 }}
  >
    {show => show && (styles => children(styles))}
  </Transition>
);

ScaleTransition.displayName = 'ScaleTransition';

ScaleTransition.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

ScaleTransition.defaultProps = {
  isActive: undefined,
};

export default ScaleTransition;
