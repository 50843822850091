import styled from "@emotion/styled";
import { bottom, left, right, top, zIndex } from "@modernary/ui-kit-styled-system";
import Box from "../Box";

const Absolute = styled(Box)`
  position: absolute;
  ${top} 
  ${right}
  ${bottom} 
  ${left} 
  ${zIndex}
`;

Absolute.propTypes = {
  ...top.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
  ...left.propTypes,
  ...zIndex.propTypes
};

Absolute.displayName = "Absolute";

export default Absolute;
