import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Flex } from '../../../../Box';
import RemoveButton from './internal/RemoveButton';
import SelectionText from './internal/SelectionText';

const SelectionFlexItems = ({
  allowClear,
  getRemoveButtonProps,
  inputName,
  isDisabled,
  isReadOnly,
  option,
  optionId,
  renderSelection,
  variant,
}) => {
  const isInputDisabled = isDisabled || isReadOnly;
  const isInputEnabled = !isInputDisabled;

  const removeButtonProps =
    isInputEnabled && getRemoveButtonProps ? getRemoveButtonProps({ item: option }) : {};

  return (
    <Fragment>
      <Flex.Item flex={1}>
        <SelectionText
          variant={variant}
          renderSelection={renderSelection}
          option={option}
          isDisabled={isDisabled}
        />
        <input
          name={inputName}
          defaultValue={optionId(option)}
          disabled={isDisabled}
          readOnly={isReadOnly}
          type="hidden"
        />
      </Flex.Item>
      {isInputEnabled && allowClear && (
        <Flex.Item flex={0}>
          <RemoveButton {...removeButtonProps} variant={variant} />
        </Flex.Item>
      )}
    </Fragment>
  );
};

SelectionFlexItems.propTypes = {
  getRemoveButtonProps: PropTypes.func,
  inputName: PropTypes.string,
  allowClear: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  option: PropTypes.any.isRequired,
  optionId: PropTypes.func.isRequired,
  renderSelection: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

SelectionFlexItems.defaultProps = {
  allowClear: true,
  getRemoveButtonProps: undefined,
  inputName: undefined,
  renderSelection: undefined,
};

export default SelectionFlexItems;
